import { Component, Input, OnInit } from '@angular/core'
import { Auth, onAuthStateChanged, signInAnonymously } from '@angular/fire/auth'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { CookieService } from 'ngx-cookie-service'
import { AuthentificationService } from 'src/app/services/authentification.service'
import { UserService } from 'src/app/services/user.service'
import { environment } from 'src/environments/environment'
import { Contrat, Service, Actualites } from '../../models/contrat'
import { ContratService } from '../../services/contract.service'

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  @Input() contractId = 'Est Ensemble'
  contrat?: Contrat
  mail:string = ""
  password:string = ""
  contractColor?: string
  caractereError:boolean = true
  specialError:boolean = true
  majError:boolean = true
  numberError:boolean = true
  mailError : boolean = false
  passwordError : boolean = false
  conditionError:boolean = false
  cguCheck:boolean = false
  privacyCheck:boolean = false
  pathNok: string = "../../../assets/img/check-nok.svg"
  pathOk: string = "../../../assets/img/check-ok.svg"
  
  constructor(
                private auth: Auth, 
                private authservice: AuthentificationService,
                private contractService: ContratService, 
                private userService: UserService, 
                private router : Router, 
  ) { }

  ngOnInit(): void {
    onAuthStateChanged(this.auth, (user) => {
        this.contractService.contract.asObservable().subscribe((contrat) => {
            console.log('Constrat set')
            this.contrat = contrat
            this.contractColor = this.contrat.design?.couleurPrincipale
        })
        if (user?.isAnonymous == false) {
            this.router.navigate(['dashboard'])
            this.contractService.navButtons.next("dashboard")
        } else {
            console.log('Utilisateur non connecté')
        }
    })
  }

  goToLogin() {
    this.router.navigate(['/signin'])
  }

   validMail(mail:string){
    return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(mail);
  }

  processMail(event:any){
    if(this.validMail(event.target.value)){
      this.mailError = false
      this.mail = event.target.value
    }else{
      this.mailError = true
    }
  }

  changeCguCheck(event:any){
    this.cguCheck = event.target.checked
  }

  changePrivacyCheck(event:any){
    this.privacyCheck = event.target.checked
  }

  processPwd(event:any){
    if(event.target.value.length >= 8){
      this.caractereError = false
    }else{
      this.caractereError = true
    }
    if(event.target.value.match(/.*?[#?!@$%^&*-]/g)){
      this.specialError = false
    }else{
      this.specialError = true
    }
    if(event.target.value.match(/[A-Z]/g)){
      this.majError = false
    }else{
      this.majError = true
    }
    
    if(event.target.value.match(/[0-9]/g)){
      this.numberError = false
    }else{
      this.numberError = true
    }
    if(!this.caractereError && !this.specialError && !this.majError && !this.numberError && !this.conditionError){
      this.passwordError = false
      this.password = event.target.value
    }else{
      this.passwordError = true
    }
  }



  canCreate(){
    if(this.cguCheck && this.privacyCheck &&!this.caractereError && !this.specialError && !this.majError && !this.numberError && !this.conditionError && !this.mailError){
      return false
    }else{
      return true
    }
  }

  onSubmitSignUp() {
    if(!this.mailError && !this.passwordError){
      this.authservice.SignUp(this.mail, this.password, this.contractId);
    }
  }

}
