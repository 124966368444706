import { Component, Input, OnInit } from '@angular/core';
import { Contrat, Report } from 'src/app/models/contrat';
import { Auth, onAuthStateChanged, signInAnonymously } from '@angular/fire/auth'
import { ContratService } from 'src/app/services/contract.service';
import { Router } from '@angular/router';
import { User_Montri } from 'src/app/models/user';
import * as moment from 'moment';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { UserService } from 'src/app/services/user.service';
import { Timestamp } from '@angular/fire/firestore';
moment.locale('fr');

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    section:string= 'infos'
    user?: User_Montri
    contrat?: Contrat
    contractColor?: string
    cguCheck: boolean = true
    privacyCheck: boolean = true
    report?: Report
    reports?:Report[]
    message?: string
    history?:boolean = false
    @Input() contractId = 'Est Ensemble'
    showUnreadBadge = false
    uid?: string

    constructor(
        private contractService: ContratService,
        private userService: UserService,
        private auth: Auth, 
        private router : Router, 
        private authservice: AuthentificationService
    ) {}

    ngOnInit(): void {
        onAuthStateChanged(this.auth, (user) => {
            if (user?.isAnonymous == false) {
                this.uid = user.uid
                this.contractService.contract.asObservable().subscribe((contrat) => {
                    console.log('Constrat set')
                    this.contrat = contrat
                    this.history = contrat?.getService('Demandes et Signalements')?.historique
                    this.contractColor = this.contrat.design?.couleurPrincipale
                })
                this.userService.user.asObservable().subscribe((userDb) => {
                    this.user = userDb
                })
                this.userService.reports.asObservable().subscribe((reports) => {
                    this.reports = reports
                    this.checkBadgeStatus()
                })
            } else {
                this.router.navigate(['/signin'])
                this.contractService.navButtons.next("signin")
            }
        })
    }

    checkBadgeStatus() {
        this.showUnreadBadge = this.reports ? this.reports.findIndex(elem => elem.maj) > -1 : false
    }

    getAdress(obj:any){
        for(let key in obj){
            if(key.toLowerCase().includes('adresse')){
            return obj[key]
            }
        }
    }

    selectReport(report:any){
        this.report = report
        this.section = 'report'
        if (this.report?.maj) {
            this.report.maj = false
            this.contractService.updateReport(this.report)
        }
    }

    changeSection(section:string){
        this.section = section
    }

    changeSurname(event:any){
        if(this.user){
            this.user.prenom = event.target.value
        }
    }


    changeMessage(event:any) {
        this.message = event.target.value
    }

    sendMessage() {
        this.report?.messages?.push({
            message: this.message,
            date: Timestamp.now(),
            expediteur: 0
        })
        if (this.report) {
            this.report.majBO = true
        }
        this.contractService.updateReport(this.report)
        this.message = ''
    }

    getColorState(status:string){
        if (status == 'a_traiter') {
            return '../../../assets/img/state_red.svg'
        } else if (status == 'en_cours') {
            return '../../../assets/img/state_yellow.svg'
        } else if (status == 'traiter') {
            return '../../../assets/img/state_green.svg'
        } else {
            return ''
        }
    }

    getNameState(status?: string){
        if (status == 'a_traiter') {
            return 'En attente'
        } else if (status == 'en_cours') {
            return 'En cours'
        } else if (status == 'traiter') {
            return 'Terminé'
        } else {
            return ''
        }
    }

    getClassState(status?: string) {
        if (status == 'a_traiter') {
            return 'red'
        } else if(status == 'en_cours') {
            return 'yellow'
        } else if (status == 'traiter') {
            return 'green'
        } else {
            return ''
        }
    }

    saveInfoAccount() {
        if (this.user && this.uid) {
            this.userService.updateUser(this.user, this.uid)
        }
    }

    changePwd() {
        if(this.user?.email){
            this.authservice.changePassword(this.user.email)
        }    
    }
}
