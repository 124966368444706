/// <reference types="@types/googlemaps" />
import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { } from 'googlemaps';

@Component({
    selector: 'AutocompleteComponent',
    templateUrl: './google-places.component.html',
    styleUrls: ['./google-places.component.scss']
})

export class AutocompleteComponent implements OnInit, AfterViewInit {
    @Input() adressType: string = ""
    @Input() color: string = "#f4f3f3"
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
	@Output() fini: EventEmitter<any> = new EventEmitter();
	@ViewChild('addresstext') addresstext: any;

    autocompleteInput: string = ""
    queryWait: boolean = false

    constructor() { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.getPlaceAutocomplete()
    }

    private getPlaceAutocomplete() {
        const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
            {
                componentRestrictions: { country: 'FR' },
                types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
            })
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace()
            this.invokeEvent(place)
        })
    }

    invokeEvent(place: Object) {
        this.setAddress.emit(place);
    }

}
