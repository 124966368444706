<ng-container *ngIf="contrat?.contrat">
    <div class="row d-flex justify-content-center marginDashboard">
        <div class="card card-login mt-4 mb-4 align-items-center" >
            <img src="assets/img/logo-montri.png" class="montri_logo mt-4 mb-4">
            <p class="p_welcome">👋 Bienvenue,</p>
            <p class="p_connect mb-4">Veuillez vous connecter</p>
            <div class="left-inner-addon input-container mb-4">
                <img src="../../../assets/img/mail-icon.svg" alt="">
                <input type="text" (keyup)="processMail($event)"
                        (change)="processMail($event)"
                       class="form-control input_login" 
                       placeholder="Votre email" />
            </div>
            <div class="left-inner-addon input-container mb-4">
                <img src="../../../assets/img/password-icon.svg" alt="">
                <input type="password" (keyup)="processPwd($event)"
                        (change)="processPwd($event)"
                       class="form-control input_login" 
                       placeholder="Votre mot de passe" />
            </div>
            <p class="p_forget mb-4" (click)="forgotPwd()">Mot de passe oublié ?</p>
            <div *ngIf="errorLogin" class="errorLogin  mb-4">
                <p class="errorText ml-3">Votre email et mot de passe ne correspondent pas. Veuillez vérifier et réessayer.</p>
            </div>
            <button class="button_connect mb-3" (click)="onSubmitSignIn()">Me connecter</button>
            <div class="row">
                <p class="p_ask mr-5">Pas encore de compte ?</p>
                <p class="p_newaccount" (click)="toRegister()">Créer votre compte</p>
            </div>
        </div>
     </div>
   
</ng-container>





