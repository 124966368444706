
<div class="modal-header d-flex flex-column" style='border: none;align-items: center;padding-top: 2rem;'>
	<img src="../../assets/img/logomontri.svg" class="centered style_icons" style="width: 15%;" />
</div>
<div class="modal-header d-flex flex-column" style='border: none;align-items: center;'>
	<h5 class="avenir_heavy" id="exampleModalLongTitle">Bienvenue à {{ city?.nom }} !</h5>
	<p class="avenir_light form-check-label" style='text-align: center;' >Les services de votre ville ne sont pas encore disponibles, mais vous pouvez utiliser le guide du tri et suivre nos astuces sur le recyclage et l’économie circulaire !</p>
</div>
<div class="modal-footer" style='border: none;justify-content: center;padding-bottom: 2rem;'>
	<button class="btn btn-primary"
		[ngStyle]="{'color': 'white','background-color':contractColor,'border-color': contractColor}"
		data-dismiss="modal" (click)="vote()" >J'ai compris</button>
</div>
