import { LocationStrategy } from '@angular/common'
import { Component, OnInit} from '@angular/core'
import { Router } from '@angular/router'
import { Contrat } from 'src/app/models/contrat'
import { ContratService } from 'src/app/services/contract.service'
import { Auth, onAuthStateChanged, signInAnonymously } from '@angular/fire/auth'

@Component({
    selector: 'app-successfull',
    templateUrl: './successfull.component.html',
    styleUrls: ['./successfull.component.scss']
})

export class SuccessfullComponent implements OnInit {

    constructor(
        private router: Router,
        private contractService: ContratService,
        private location: LocationStrategy,
        private auth : Auth
        

    ) {
        history.pushState(null, "", window.location.href)
        // check if back or forward button is pressed.
        this.location.onPopState(() => {
            this.router.navigateByUrl('/successfull')
        })
    }

    contrat?: Contrat
    textWeb: string = ""
    contractId : string = ""

    ngOnInit(): void {
        window.scrollTo(0, 0)
        onAuthStateChanged(this.auth, (user) => {
            if (user) {
                this.contractService.contract.asObservable().subscribe((contrat) => {
                    if (!contrat.contrat) {
                        console.log("Contrat vide")
                        //this.contractService.loadContract(this.contractId)
                    } else {
                        this.textWeb = this.contrat?.parrainage?.texteWebsuccess ?? "" // Texte à enlever
                    }
                })
            } else {
                console.log('Un nouveua compte anonyme va être crée')
                signInAnonymously(this.auth)
            }
        })
    }

    // On click on the button, we redirect the user to the right page
    download(){
        let os = this.getMobileOperatingSystem()
        if(os === 'iOS'){
            window.location.href = 'https://apps.apple.com/fr/app/montri-trier-solidaire/id1458927235'
        }else if (os === 'Android'){
            window.location.href = 'https://play.google.com/store/apps/details?id=eu.uzer.montri&hl=fr'
        }
    }

    //Get the mobile operating system.
    getMobileOperatingSystem() {
	    var userAgent = navigator.userAgent || navigator.vendor 
        // Android detection
		if (/android/i.test(userAgent)) {
			return "Android"
		}
		// iOS detection 
		if (/iPad|iPhone|iPod/.test(userAgent) ) {
			return "iOS"
		}
		return "unknown"
	}
}
