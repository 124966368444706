
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core'
import { Reporting } from 'src/app//models/user'
import { Alert, Champs, Contrat, Coordonne, Service, Subservice } from 'src/app/models/contrat'
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { v4 as uuidv4 } from 'uuid'
import { } from 'googlemaps'
import { ContratService } from 'src/app/services/contract.service'
import { Auth, onAuthStateChanged, signInAnonymously, User } from '@angular/fire/auth'
import { environment } from 'src/environments/environment'
import { getStorage, ref, getDownloadURL } from '@angular/fire/storage'
import { DestinatairesList } from 'src/app/models/destinataires'
import { NgxSpinnerService } from 'ngx-spinner'
import { HostListener } from "@angular/core"
import { CookieService } from 'ngx-cookie-service'

@Component({
    selector: 'signalements-widget',
    templateUrl: './signalements.component.html',
    styleUrls: ['./signalements.component.scss']
})
export class ReportingComponent implements OnInit {

  mandatoryFieldError : boolean = false
	contractColor?: String;
	degraded_contract_color?: String;
	contrat: Contrat = new Contrat();
	SearchIcon = faSearchLocation;
	progress?: number;
    hidden_spinner: boolean = true
	infoMessage: any;
	isUploading = false;
	file: File = new File([""], "") ;
	selected_reporting: string = ''
	selectedItem?: Subservice ;
	imageUrl: string | ArrayBuffer = 'https://bulma.io/images/placeholders/480x480.png';
	fileName = 'No file selected';
	address?: Object;
	sousService?: Subservice;
	sousServicesList: Subservice[] = [];
	establishmentAddress?: Object;
	formattedAddress?: string;
	formattedEstablishmentAddress?: string;
	phone?: string;
	last_ckecked_element: string = "";
	hover: boolean = false;
	is_hidden: boolean = true;
	reporting : Reporting = new Reporting({})
	reportingDynamique : any
	@Output() setAddress: EventEmitter<any> = new EventEmitter();
	@Output() fini: EventEmitter<any> = new EventEmitter();
	@ViewChildren("address") adress?: QueryList<any>;
	adresse_focus : boolean = false
	message_focus : boolean = false
	contratColor06 =  ""
	contratColor0075  =  ""
	form: FormGroup = new FormGroup({}); 
	googlePlacesChamps : Champs | any
	postalCode? : string 
	addr : string = ""
    formIsHiden: boolean = false
    userUid?: string

  onChange(fileList: FileList | null, champsCoordonnees: Champs) {
    if (fileList) {
      let file = fileList[0]
      if (file.size > 2000000) {
        champsCoordonnees.error = true
        champsCoordonnees.value = "L'image doit avoir une taille inférieure à 2 MB"
      } else if (file) {
        this.fileName = file.name
        this.file = file
        var re = /(?:\.([^.]+))?$/;
        var ext = re.exec(file.name)
        if (ext != null) {
          champsCoordonnees.photoId = `${uuidv4()}.${ext[1] ? ext[1] : ""}`
        }
        
        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = event => {
          this.imageUrl = reader.result ?? ""
        };
        champsCoordonnees.error = false
      }
		}
	}

  constructor(private contractService: ContratService ,private auth: Auth, public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private cookieService : CookieService) { 
        this.getScreenSize()
    }

    screenHeight: number = 0
    screenWidth: number = 0

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?: Event) {
        this.screenHeight = window.innerHeight
        this.screenWidth = window.innerWidth
    }

    @Input() contractId = 'Est Ensemble'
    services: Service[] = []
    ngOnInit(): void {

    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        this.userUid = user.uid
        this.contractService.contract.asObservable().subscribe((contrat) => {
          if (!contrat.contrat) {
            console.log("Contrat vide")
            if (environment.widget) {
                this.contractService.loadContract(this.contractId)
            }  
          } else {
            console.log('Constrat set')
            this.contractService.serviceButtonChangeColor.next("Demandes et Signalements")
            this.contrat = contrat
            this.contractId = this.contrat?.contrat?.idContrat ?? ""
            this.contractColor = this.contrat.design?.couleurPrincipale ?? this.contrat.interface?.designWeb?.couleurPrincipale 
            this.reporting.idContrat = this.contrat?.contrat?.idContrat ?? ""
            const contractColor = this.contractColor as string;
            const contratColor06 = this.hexToRgbA(contractColor, '0.6');
            this.contratColor06 = contratColor06
            const contratColor0075 = this.hexToRgbA(contractColor, '0.075');
            this.contratColor0075 = contratColor0075
            const input1 = document.getElementById('objectInput');
            const storage = getStorage()
            this.services = this.contrat?.services ?? []
            for (let element of this.services) {
              if (element.nom == "Demandes et Signalements") {
                this.sousServicesList = element.sousServices ?? [];
                for (let index in this.sousServicesList) {
                  const img = ref(
                    storage,this.contrat.contrat?.idContrat+'/'+environment.resourcesOrigin+'/'+this.sousServicesList[index].imageWeb
                  );
                  getDownloadURL(img).then((href:any) => {
                    this.sousServicesList[index].imageUrl = href
                  })
                }
                break;
              }
            }
            //this.changeInputColor(0, this.sousServicesList[0], 0)
          } 
        })
      } else {
        // User is signed out
        console.log('Compte anonyme crée')
        signInAnonymously(this.auth)
      }
    })
	}

  registrationForm = this.fb.group({
		motifName: ['', [Validators.required]]
	})

  changeMotif(e:any) {
		this.mandatoryFieldError = false
		this.motifName?.setValue(e.target.value, {
			onlySelf: true
		  })
	}

  get motifName() {
		return this.registrationForm.get('motifName');
  	}

    getlocation(key: any, champsCoordonnees: Champs ) {
      champsCoordonnees.value = ""
      champsCoordonnees.error = false
      this.adresse_focus = true
      champsCoordonnees.coordinate = undefined
      this.googlePlacesChamps = champsCoordonnees
  
      if (navigator.geolocation) {
        this.spinner.show()
        this.hidden_spinner = false
        navigator.geolocation.getCurrentPosition(
          position => {
            const lat = position.coords.latitude; // Works fine
            const lng = position.coords.longitude;
            const geocoder = new google.maps.Geocoder();
            const latlng = new google.maps.LatLng(lat, lng);
            const request = {
              latLng: latlng
            } as google.maps.GeocoderRequest;
            this.spinner.hide();
            this.hidden_spinner = true
            this.addr = ""
            geocoder.geocode(request, (results, status) => {
              if (status == google.maps.GeocoderStatus.OK) {
                if (results[0] != null) {
                  const address = results[0].formatted_address;
                  this.addr = results[0].formatted_address
                  const input = document.getElementById('adress') as HTMLInputElement;
                  input.value = address;
                  this.form?.controls[this.googlePlacesChamps.nom]?.setValue(address)
                  this.googlePlacesChamps.value = address 
                  this.googlePlacesChamps.coordinate = {latitude : results[0].geometry.location.lat(), longitude : results[0].geometry.location.lng()}
                  for (const component of results[0].address_components) {
                    if (component.types.indexOf("postal_code") > -1) {
                      this.postalCode = component.short_name
                    }
                  }
                } else {
                  alert('No address available');
                }
              }
            });
          },
          error => {
            console.log('Error code: ' + error.code + '<br /> Error message: ' + error.message);
            this.spinner.hide();
            this.hidden_spinner = true
          }
        );
      }
    }
    
    private reset()  {
      this.is_hidden = true
      this.reporting = new Reporting({})
      //this.adress.nativeElement.value = "";
      const rgb = document.getElementById(this.last_ckecked_element) as HTMLInputElement
      rgb.style.backgroundColor = "rgba(60, 60, 60, 0.6)"
    }

    cleanField(champsCoordonnees:any) {
      this.form?.controls[champsCoordonnees.nom]?.reset()
      champsCoordonnees.value = ""
      champsCoordonnees.error = false
      this.adresse_focus = true
    }

    submit(){
      this.reporting.motif = this.motifName?.value
    }

    getIcon(name:string){
      switch (name) {
        case 'Nom et prénom':
          return "fa fa-user"
        case 'Email':
            return "fa fa-envelope"
        case 'Téléphone':
          return "fa fa-phone fa-lg mt-1"
        case 'SIRET':
          return "fa fa-plus-square"
        default:
          return ""
      }
    }

    changeInputColor(i: number, item: Subservice, from: number) {
      this.imageUrl = 'https://bulma.io/images/placeholders/480x480.png'
      if (item == this.selectedItem) {
          return
      }
      this.selectedItem = item 
      
        this.sousServicesList.forEach((value, index) => {
            if (value.active) {
                const color = document.getElementById('input' + index) as HTMLElement
                if (value.nom == item.nom) {
                    color.style.opacity = "1"
                } else {
                    color.style.opacity = "0.3"
                }
            }
        })

      this.reporting.type = item.nom ?? ""
      if (from == 0) {
        if (item.url && item.url != "") {
          this.is_hidden = true;
          window.open(item.url.toString(), "_blank");
        } else {
          this.is_hidden = false;
        }
        this.selected_reporting = item.nom ?? "";
        const color = document.getElementById('input' + i) as HTMLElement
        color.style.backgroundColor = this.hexToRgbA(this.contractColor, 0.2)
        color.style.opacity = "1"
        if (this.last_ckecked_element != "") {
          const rgb = document.getElementById(this.last_ckecked_element) as HTMLElement
          rgb.style.backgroundColor = "#F2F2F2"
          rgb.style.opacity = "0.3"
        } else {
            const color = document.getElementById('input' + i) as HTMLElement
            color.style.backgroundColor = this.hexToRgbA(this.contractColor, 0.2)
            color.style.opacity = "1"
        }
        this.last_ckecked_element = 'input' + i
      }
      else {
        if (item.url && item.url != "") {
          this.is_hidden = true;
          window.open(item.url.toString(), "_blank");
        } else {
          this.is_hidden = false;
        }
        this.selected_reporting = item.nom ?? "";
        this.last_ckecked_element = 'input' + i
        const color = document.getElementById('input' + i) as HTMLElement
        color.style.backgroundColor = this.hexToRgbA(this.contractColor, 0.2)
        color.style.opacity = "1"
      }
      let temp = this.selectedItem.coordonnees?.champs ?? []
      let temp2 = this.selectedItem.formulaires?.champs ?? []
      this.form = this.toFormGroup(temp.concat(temp2))

      if (this.screenWidth < 576) {
        setTimeout(() => {
            document.getElementById('destination')?.scrollIntoView({behavior:"smooth"})
        }, 100) 
      }
      
    }

    mouse_over(i: number) {
      // const color = document.getElementById('input' + i) as HTMLElement
      // color.style.backgroundColor = this.hexToRgbA(this.contractColor, 0.1)
  
    }
    mouse_leave(i: number) {
      // if (this.last_ckecked_element != 'input' + i) {
      //   const color = document.getElementById('input' + i) as HTMLElement
      //   color.style.backgroundColor = "#AAAAAA"
      // }
    }

    toFormGroup(champs: Champs[] ) {
      const group: any = {};
      champs.forEach(champ => {
        var validators = []
        if (champ.format == "email") {
          validators.push(Validators.email)
        }
        if(champ.format == "number"){
          validators.push(Validators.pattern("^(?:(?:\\+|00)33|0)\\s*[1-9](?:[\\s.-]*\\d{2}){4}$"))
        }
        let temp = champ.nom ?? ""
        group[temp] = new FormControl('', validators);
  
      });
      return new FormGroup(group);
      }

      sendInfos() {

        let villesListe = this.contrat.villesListe ?? []
        if (this.postalCode && villesListe.indexOf(this.postalCode) > -1) {		
          var error : boolean = false;
          let temp = this.selectedItem?.coordonnees?.champs ?? []
          for (let index in temp ) {
            if (this.form?.controls[temp[index].nom].hasError('required')) {
              if (temp[index].format == "photo") {
                temp[index].value = "La soumission d'une image est obligatoire"
              } else {
                temp[index].value = "Champs requis"
              }
              temp[index].error = true
              error = true
              continue
            }
            if (this.form?.controls[temp[index].nom].hasError('email')) {
              temp[index].value = "Adresse email invalide"
              temp[index].error = true
              error = true
              continue
            }
            if (this.form?.controls[temp[index].nom]?.errors) {
              temp[index].value = "Numéro invalide"
              temp[index].error = true
              error = true
              continue
            }
            if (temp[index].format == "googlemaps") {
              if (this.googlePlacesChamps.coordinate) {
                temp[index] = this.googlePlacesChamps
              } else {
                temp[index].value = "Champs requis"
                temp[index].error = true
                error = true
              }
            } else {
              temp[index].value = this.form?.controls[temp[index].nom].value
            }
          }
    
          temp = this.selectedItem?.formulaires?.champs ?? []
          for (let index in temp) {
            if (this.form?.controls[temp[index].nom].hasError('required')) {
              if (temp[index].format == "photo") {
                temp[index].value = "La soumission d'une image est obligatoire"
              } else {
                temp[index].value = "Champs requis"
              }
              temp[index].error = true
              error = true
              continue
            }
            if(this.form?.controls[temp[index].nom].hasError('email')){
              temp[index].value = "Adresse email invalide"
              temp[index].error = true
              error = true
              continue
            }
            if (this.form?.controls[temp[index].nom]?.errors) {
              temp[index].value = "Numéro invalide"
              temp[index].error = true
              error = true
              continue
            }
            if (temp[index].format == "googlemaps") {
              if (this.googlePlacesChamps.coordinate) {
                temp[index] = this.googlePlacesChamps
              } else {
                temp[index].value = "Champs requis"
                temp[index].error = true
                error = true
              }
            } else {
              temp[index].value = this.form?.controls[temp[index].nom].value
            }
          }
    
          if (!error) {
            this.formIsHiden = true
            this.contractService.destinataires_obs.subscribe((destinataires: any) => {
              if(this.selectedItem){
                let destinataire = this.findDestinataire(this.selectedItem, destinataires) ?? ""
                this.contractService.sendReporting(this.contractId, this.selectedItem, this.file, destinataire, this.contrat, this.userUid)
                this.formIsHiden = true
                this.reset()
                window.scrollTo(0, 0)
              }
            })
          }
        }
        else{
            window.scrollTo(0, 0)
            var alert: Alert = {
                type: "error",
                message: "Désolé, l'adresse renseignée n'est pas couverte par nos services. Veuillez vérifier votre adresse et réessayer.",
            }
            this.contractService.alert.next(alert)
        }
      }

      ngAfterViewInit() {
        this.adress?.changes.subscribe((result) => {
          setTimeout(() => {
            const autocomplete = new google.maps.places.Autocomplete(
              result.first.nativeElement,
              {
                componentRestrictions: { country: "fr" }
              }
            );
            google.maps.event.addListener(autocomplete, "place_changed", () => {
              const place = autocomplete.getPlace() 
              if(place.geometry){
                this.googlePlacesChamps.value = place.formatted_address
                this.googlePlacesChamps.coordinate = {latitude : place.geometry?.location.lat(), longitude : place.geometry?.location.lng()}
                const geocoder = new google.maps.Geocoder();
                const latlng = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
                const request = {
                  latLng: latlng
                } as google.maps.GeocoderRequest;
                this.postalCode = undefined
                this.addr = ""
                geocoder.geocode(request, (results, status) => {
                  if (status == google.maps.GeocoderStatus.OK) {
                    if (results[0] != null) {
                      this.addr = results[0].formatted_address
                      for (const component of results[0].address_components) {
                        if (component.types.indexOf("postal_code") > -1) {
                          this.postalCode = component.short_name
                        }
                      }
                    }
                  }
                });
                this.invokeEvent(place);
                };
            });
          }, 200);
        })
      }

      onGooglePlacesFocus(champsFormulaire: any ) {
        champsFormulaire.coordinate = undefined
        this.googlePlacesChamps = champsFormulaire
      }

      invokeEvent(place: Object) {
        this.setAddress.emit(place);
      }

      findDestinataire(sousService: Subservice, collectionDestinataires: DestinatairesList) {
        if (collectionDestinataires.destinatairesFormulaires) { let destinataires = collectionDestinataires.destinatairesFormulaires
          for (const item of destinataires) {
            if(item.nom) { let nom = item.nom
              if (this.addr.indexOf(nom)  > -1) {
                if (item.sousServices) { let typesDemande = item.sousServices
                  for (const type of typesDemande) {
                    if (type.code == sousService.code) {
                      if (type.motifs) { let motifs = type.motifs
                        for (const motif of motifs) {
                          if (motif.motifs) { let nomMotif = motif.motifs
                            if (motif.emails) { let emails = motif.emails
                              if (sousService.formulaires) { 
                                let formulaires = sousService.formulaires 
                                for (const champ of formulaires.champs ?? []){
                                  if (champ.format == "liste" && champ.value == nomMotif) {
                                    return emails
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      if (type.emails) { let emails = type.emails
                        return emails
                      }
                    }
                  }
                }
              }
            }
          }
        }
        return sousService.email
        }

  hexToRgbA(hex: any, opacity: any) {
		let c: any;
		if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			c = hex.substring(1).split('');
			if (c.length == 3) {
				c = [c[0], c[0], c[1], c[1], c[2], c[2]];
			}
			c = '0x' + c.join('');
			return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
		}
		throw new Error('Bad Hex');
	}

  capitalize(s: string | any[]) {
		return s[0].toUpperCase() + s.slice(1);
	}

}
