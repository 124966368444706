import { environment } from './../../../environments/environment'
import { getStorage, Storage, ref, getDownloadURL, StorageReference } from '@angular/fire/storage'
import { Contrat } from './../../../app/models/contrat'
import { ContratService } from './../../../app/services/contract.service'
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router'
import { } from 'googlemaps'
import { CollecteWaste, FluxCalendrier, Jours } from './../../models/collecte-waste'
import { NgxSpinnerService } from "ngx-spinner"
import { HostListener } from "@angular/core"
import { Auth, onAuthStateChanged, signInAnonymously } from '@angular/fire/auth'
import { combineLatest } from 'rxjs'


@Component({
    selector: 'calendrier-widget',
    templateUrl: './calendrier.component.html',
    styleUrls: ['./calendrier.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CalendrierComponent implements OnInit {

    @Input() contractId = 'Est Ensemble'
    contractColor?: string
    contrat: Contrat = new Contrat()
    collecte?: CollecteWaste
    address?: Object
    collecteResult: FluxCalendrier[] = []
    is_hidden: boolean = false
	hidden_spinner : boolean = true

    constructor(
        private router: Router,
        private contractService: ContratService,
		private spinner: NgxSpinnerService,
        private storage: Storage,
        private auth: Auth,
    ) {
        this.getScreenSize()
     }

    screenHeight: number = 0
    screenWidth: number = 0

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?: Event) {
        this.screenHeight = window.innerHeight
        this.screenWidth = window.innerWidth
    }

    ngOnInit(): void {
        onAuthStateChanged(this.auth, (user) => {
            if (user) {
                combineLatest([this.contractService.contract.asObservable(), this.contractService.calendrier_obs]).subscribe((res) => {
                    const contrat = res[0]
                    const calendrier = res[1]
                    if (!contrat.contrat) {
                        console.log("Contrat vide")
                        if (environment.widget) {
                            this.contractService.loadContract(this.contractId)
                        }  
                    } else {
                        console.log('Constrat set')
                        this.contractService.serviceButtonChangeColor.next("Calendrier de collecte")
                        this.contrat = contrat
                        this.contractColor = contrat.design?.couleurPrincipale ?? ""
                        if (!calendrier.flux && environment.widget && this.contrat.contrat?.idContrat) {
                            this.contractService.loadCalendrier(this.contrat.contrat?.idContrat)
                        } else {
                            this.collecte = calendrier
                        }
                    }
                })
            } else {
                console.log('Compte anonyme crée')
                signInAnonymously(this.auth)
            }
        })
    }

    getFluxIcon(item: FluxCalendrier) {
        let imageRef = ref(this.storage, `${this.contrat.contrat?.idContrat}/${environment.resourcesOrigin}/${item.image}`)
        getDownloadURL(imageRef).then((href) => {
            item.icon = href
        })
    }
  
    getOrderedItems() {
		return this.collecteResult.sort((n1:any,n2:any) => n1.ordre - n2.ordre)
	}

    getAddress(place: google.maps.GeocoderResult | null, position: GeolocationPosition | null) {
        this.collecteResult = []
        let design = this.contrat.design?.flux ?? []
        for (let element of this.collecte?.flux ?? []) {
            var found = false
            element.agendaIcon = `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Fapport.png?alt=media`
            element.backgroudColor = this.hexToRgbA(design.filter(item => item.nom == element.nom)[0].couleur, '0.3')
            this.getFluxIcon(element)

            for (let zone of element.zones ?? []) {
                var paths_temp = []
                for (let paths of zone.polygones ?? []) {
                    var path_temp = []
                    let decodedPath = google.maps.geometry.encoding.decodePath(paths)
                    for (let path of decodedPath) {
                    path_temp.push({
                        lat : path.lat(),
                        lng : path.lng()
                        })
                    }
                    paths_temp.push(path_temp)
                }
                var polygon = new google.maps.Polygon({ paths: paths_temp})

                var lat = 0
                var lng = 0
                if (place != null) {
                    lat = place.geometry.location.lat()
                    lng = place.geometry.location.lng()
                } else if (position != null) {
                    lat = position.coords.latitude
                    lng = position.coords.longitude
                }

                var verif = google.maps.geometry.poly.containsLocation(new google.maps.LatLng(lat, lng), polygon)
                var dateverif = this.analyseIntervalDate(zone.infos?.periode ?? "")
                if (verif && dateverif) {
                    let jours = new Jours(zone.infos?.jours)
                    element.agenda = zone.infos?.daysPerMonth && zone.infos?.daysPerMonth.length > 0 ? jours?.datesToString().slice(3) : jours?.datesToString()
                    found = true
                    element.agendaIcon = `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Fagenda.png?alt=media`
                    element.found = found
                    element.dateVerif = dateverif
                    element.zone = zone
                    this.collecteResult.push(element)
                    break
                }
                
            }
            
            if (!found) {
                element.found = found
                element.agenda = this.collecte?.collecteKo
                this.collecteResult.push(element)
            }
        }
    }

    showMapButton(item: FluxCalendrier) {
        return !item.found || ((item.zone?.infos?.fluxCarte?.length ?? 0) > 0)
    }

    getShortLibelle(libelle: string | undefined) {
        if (libelle) {
            if (libelle.toLowerCase().includes("point") || libelle.toLowerCase().includes("trouver")) {
                return "Carte"
            }
        }
        return ""
    }

    getIconBottom(agenda: string | undefined) {
        if (agenda) {
            const lowercasedTime = agenda.toLowerCase()
            if(lowercasedTime.includes("lundi") || 
            lowercasedTime.includes("mardi") || 
            lowercasedTime.includes("mercredi") || 
            lowercasedTime.includes("jeudi") || 
            lowercasedTime.includes("vendredi") || 
            lowercasedTime.includes("samedi") || 
            lowercasedTime.includes("dimanche") ||
            lowercasedTime.includes("tous les jours")) {
                return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Ficon-collecte-pap.svg?alt=media`
            }
        }
        return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Ficon-collecte-pav.svg?alt=media`
    }

    analyseIntervalDate(period: string) {

        const dateStart: string = period.slice(3,13)
        const dateEnd: string = period.slice(17,28)

        const dateStartTransform = dateStart.split("/")
        const dateEndTransform = dateEnd.split("/")

        const dateStartConvert = new Date(dateStartTransform[2]+"-"+dateStartTransform[1]+"-"+dateStartTransform[0])
        const dateEndConvert = new Date(dateEndTransform[2]+"-"+dateEndTransform[1]+"-"+dateEndTransform[0])

        const today  = new Date()
        if (today >= dateStartConvert && today <= dateEndConvert || period =='') {
            return true
        } else { 
            return false
        }
    }

    goToFluxInMap(item: FluxCalendrier) {
        if (!item.found && item.flux) {
            if (this.contrat.contrat?.idContrat == "CACP") {
                switch (item.flux) {
                    case "Ordures ménagères (Habitats collectifs)":
                    case "Ordures ménagères (Habitats pavillonnaires)":
                        this.contractService.defaultFlux = "Ordures ménagères"
                        break
                    case "Emballages recyclables (Habitats collectifs)":
                    case "Emballages recyclables (Habitats pavillonnaires)":
                        this.contractService.defaultFlux = "Emballages recyclables"
                        break
                    case "Encombrants (Habitats collectifs)":
                    case "Encombrants (Habitats pavillonnaires)":
                    case "Déchets végétaux (Habitats pavillonnaires)":
                        this.contractService.defaultFlux = "Déchetteries"
                        break
                    case "Emballages en verre":
                        this.contractService.defaultFlux = "Emballage en verre"
                        break
                }
            } else {
                this.contractService.defaultFlux = item.flux
            }
        } else if (item.zone?.infos?.fluxCarte && (item.zone.infos.fluxCarte.length) > 0) {
            this.contractService.defaultFlux = item.zone.infos.fluxCarte
        }
        this.contractService.serviceButtonChangeColor.next("Carte des points de collecte")
        this.router.navigateByUrl("/services/map")
    }

    getCollectGeolocation() {
        this.hidden_spinner = false
        this.spinner.show()
        this.collecteResult = []
        this.is_hidden = false
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const lat = position.coords.latitude
                    const lng = position.coords.longitude
                    const geocoder = new google.maps.Geocoder()
                    const latlng = new google.maps.LatLng(lat, lng)
                    const request = {
                        latLng: latlng
                    } as google.maps.GeocoderRequest

                    geocoder.geocode(request, (results, status) => {
                        if (status == google.maps.GeocoderStatus.OK) {
                            if (results[0] != null) {
                                const address1 = results[0].formatted_address
                                const input = document.getElementById('input_adresse') as HTMLInputElement
                                input.value = address1;
                                this.getAddress(null, position)
                                this.spinner.hide()
                                this.hidden_spinner = true
                            } else {
                                alert('No address available')
                                this.spinner.hide()
                            }
                        }
                    })
                },
                error => {
                    console.log('Error code: ' + error.code + '<br /> Error message: ' + error.message)
                    this.spinner.hide()
                }
            )
        }
    }

	HexCode(color: any){
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
        const hex = `#${((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)}`;
        return hex;
    }

    hexToRgbA(hex: any, opacity: any) {
        let c : any
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('')
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]]
            }
            c = '0x' + c.join('')
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')'
        }
        throw new Error('Bad Hex')
    }

    getAgendaText(item: FluxCalendrier) {
        if (item.found == false) {
            return this.collecte?.collecteKo
        } else if (item?.zone?.infos?.periodeType == "Personnalisée" && item.zone.infos.frequence == "mensuelle") {
            return `${[item.zone.infos.daysPerMonth?.slice(0 ,-8),item.agenda,item.zone.infos.daysPerMonth?.slice(-8)].join('')}${ item.zone.infos.pairImpair ? ` ${item.zone.infos.pairImpair}` : "" } ${item.zone.infos.periode?.toLowerCase()} ${ item.zone.infos.horaires ? `à partir de ${item.zone.infos.horaires?.slice(0, -3)}` : ""}`
        } else if (item?.zone?.infos?.periodeType == "Personnalisée"  && item.zone.infos.frequence== "Bi-mensuelle") {
            return `${[item.zone.infos.daysPerMonth?.slice(0 ,-8),item.agenda,item.zone.infos.daysPerMonth?.slice(-8)].join('')}${ item.zone.infos.pairImpair ? ` ${item.zone.infos.pairImpair}` : "" } ${item.zone.infos.periode?.toLowerCase()} ${ item.zone.infos.horaires ? `à partir de ${item.zone.infos.horaires?.slice(0, -3)}` : ""}`
        } else if (item?.zone?.infos?.periodeType == "Personnalisée" && item.zone.infos.frequence == "Hebdomadaire") {
            return `Tous les ${item.agenda} ${item.zone.infos.periode?.toLowerCase()}${ item.zone.infos.pairImpair ? ` ${item.zone.infos.pairImpair}` : "" } ${ item.zone.infos.horaires ? `à partir de ${item.zone.infos.horaires?.slice(0, -3)}` : ""}`
        } else if (item?.zone?.infos?.periodeType == "Toute l'année" && item.zone.infos.periode == "" && item.zone.infos.frequence == "mensuelle") {
            return `${[item?.zone?.infos?.daysPerMonth?.slice(0 ,-8),item.agenda,item.zone.infos.daysPerMonth?.slice(-8)].join('')}${ item.zone.infos.pairImpair ? ` ${item.zone.infos.pairImpair}` : "" } ${ item.zone.infos.horaires ? `à partir de ${item.zone.infos.horaires?.slice(0, -3)}` : ""}`
        } else if (item?.zone?.infos?.periodeType == "Toute l'année" && item.zone.infos.periode == "" && item.zone.infos.frequence== "Bi-mensuelle") {
            return `${[item?.zone?.infos?.daysPerMonth?.slice(0 ,-8),item.agenda,item.zone.infos.daysPerMonth?.slice(-8)].join('')}${ item.zone.infos.pairImpair ? ` ${item.zone.infos.pairImpair}` : "" } ${ item.zone.infos.horaires ? `à partir de ${item.zone.infos.horaires?.slice(0, -3)}` : ""}`
        } else if (item?.zone?.infos?.periodeType == "Toute l'année" && item.zone.infos.periode == "" && item.zone.infos.frequence == "Hebdomadaire") {
            return `${item.agenda}${ item.zone.infos.pairImpair ? ` ${item.zone.infos.pairImpair}` : "" } ${ item.zone.infos.horaires ? `à partir de ${item.zone.infos.horaires?.slice(0, -3)}` : ""}`
        }
        return ""
    }

    getActionText(item: FluxCalendrier) {
        if (item.found == false) {
            return '<img class="action pb-1 pr-1" src="https://firebasestorage.googleapis.com/v0/b/montri-dev.appspot.com/o/widget%2Fassets%2Fimages%2Faction.png?alt=media"/>'+item.texte?.replace(
                /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g,
                '<a href="$1" target="_blank">$1</a>')
        }
        return '<img class="action pb-1 pr-1" src="https://firebasestorage.googleapis.com/v0/b/montri-dev.appspot.com/o/widget%2Fassets%2Fimages%2Faction.png?alt=media"/>'+item.zone?.infos?.description?.replace(
            /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g,
            '<a href="$1" target="_blank">$1</a>')
    }

    goToUrl(url: string | undefined) {
        window.open(url, '_blank')?.focus()
    }
}
