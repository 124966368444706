<ng-container *ngIf="contrat?.contrat">
    <div class="container marginDashboard" style="max-width: 1000px !important;">
    <p class="p_bienvenue mb-2">Bienvenue <b *ngIf="user?.prenom">{{user?.prenom}}</b> ! 👋</p>
    <div class="row">
        <div class="col">   
            <div class="card card_sections mb-4" (click)="changeSection('infos')" [ngClass]="section == 'infos' ? 'section_selected' : 'section_unselected'">
                <div class="row" style="height: 100%;">
                    <div class="col-2">
                        <img src="../../../assets/img/topCarddemandesData.svg" style="border-radius: 12px 0px 0px 0px;"  alt="">
                    </div>
                    <div class="col-8">
                        <p class="title_section mt-3 mb-1"><b>Mes données</b></p>
                        <p class="description_section">Retrouvez ici vos informations personnelles</p>
                    </div>
                    <div class="col-2">
                        <img src="../../../assets/img/CardArrow.svg" style="position: absolute;top: 40%;" alt="">
                    </div>
                </div>
                
            </div>
            <ng-container *ngIf="history">
                <div class="card card_sections" (click)="changeSection('historique')" [ngClass]="section == 'historique' || section == 'report' ? 'section_selected' : 'section_unselected'">
                    <div class="row" style="height: 100%;">
                        <div class="col-2">
                            <img src="../../../assets/img/topCarddemandes.svg" style="border-radius: 12px 0px 0px 0px;" alt="">
                        </div>
                        <div class="col-8">
                            <p class="title_section mt-3 mb-1"><b>Mon historique</b></p>
                            <p class="description_section">Retrouvez ici vos demandes et suivez leur évolution</p>
                        </div>
                        <div class="col-2">
                            <img src="../../../assets/img/CardArrow.svg" style="position: absolute;top: 40%;" alt="">
                        </div>
                    </div>
                    <span *ngIf="showUnreadBadge" class="notify-badge"></span>
                </div>
            </ng-container>
            
        </div>
        <div class="col">
            <div class="card card_view">

                <ng-container *ngIf="section == 'historique'">
                    <div class="ml-5 mr-4 mt-4 mb-4">
                        <p class="title_view mb-4">Historique de réclamations :</p>
                        <ng-container *ngFor="let report of reports | slice:0:10; let i=index">
                            <div class="row" style="cursor: pointer;" (click)="selectReport(report)">
                                <div class="col-2 d-flex align-items-center justify-content-center">
                                    <img [src]="getColorState(report.status ?? '')" alt="">
                                </div>
                                <div class="col-7" style="height: 2.5rem;">
                                    <p class="signal_type mt-3 mb-1 vertical-align">{{report.type}}</p>
                                    <p class="signal_date">{{report?.date?.toDate() | date: 'dd/MM/yyyy'}}</p>
                                </div>
                                <div class="col-1 d-flex align-items-center justify-content-center">
                                    <img *ngIf="report?.maj" src="../../../assets/img/pastille-maj.svg" alt="">
                                </div>
                                <div class="col-1 mr-2 d-flex align-items-center justify-content-center">
                                    <img src="../../../assets/img/arrowSignal.svg" alt="">
                                </div>
                            </div>
                            <hr>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container  *ngIf="section == 'report'">
                    <div class="ml-5 mr-4 mt-4 mb-4" >
                        <p class="title_view mb-4">Historique de réclamations :</p>
                        <div class="state_report" [ngClass]="getClassState(report?.status)" >
                            <p class="">{{getNameState(report?.status)}}</p>
                        </div>
                        <p class="text900">{{report?.type}}</p>
                        <p class="report_id">{{report?.id}}</p>
                        <p class="report_adresse mb-4">{{getAdress(report?.formulaires)}}</p>
                        <p class="text900 mb-4">Suivez votre demande</p>
                        <p class="report_date ml-2 mb-2">{{report?.date?.toDate() | date: 'dd/MM/yyyy'}}</p>
                        <div class="row ml-2 mb-2 d-flex align-items-center">
                            <img class="mr-1" src="../../../assets/img/report_send.svg" alt="">
                            <p class="suivi_report">Demande envoyée</p>
                        </div>
                        <div class="ml-4 mb-2 ">
                            <img src="../../../assets/img/report_separator.svg" alt="">
                        </div>
                        <ng-container  *ngIf="report?.status == 'a_traiter'">
                            <div class="row ml-2 d-flex align-items-center">
                                <img class="mr-1" src="../../../assets/img/report_wait.svg" alt="">
                                <p class="report_wait">En attente</p>
                            </div>
                        </ng-container>

                        <ng-container  *ngIf="report?.status == 'en_cours'">
                            <p *ngIf="report?.dateEnCours" class="report_date ml-2 mb-2">{{report?.dateEnCours?.toDate() | date: 'dd/MM/yyyy'}}</p>
                            <div class="row ml-2 d-flex align-items-center">
                                <img class="mr-1" src="../../../assets/img/report_progress.svg" alt="">
                                <p class="suivi_report">Nos équipes traitent votre demande</p>
                            </div>
                            <div class="ml-4 mb-2">
                                <img src="../../../assets/img/report_separator.svg" alt="">
                            </div>
                            <div class="row ml-2 d-flex align-items-center">
                                <img class="mr-1" src="../../../assets/img/report_wait.svg" alt="">
                                <p class="report_wait">En attente</p>
                            </div>
                        </ng-container>

                        <ng-container  *ngIf="report?.status == 'traiter'">
                            <p *ngIf="report?.dateEnCours" class="report_date ml-2 mb-2">{{report?.dateEnCours?.toDate() | date: 'dd/MM/yyyy'}}</p>
                            <div class="row ml-2 d-flex align-items-center">
                                <img class="mr-1" src="../../../assets/img/report_progress.svg" alt="">
                                <p class="suivi_report">Nos équipes traitent votre demande</p>
                            </div>
                            <div class="ml-4 mb-2">
                                <img src="../../../assets/img/report_separator.svg" alt="">
                            </div>
                            <div class="row ml-2 d-flex align-items-center">
                                <img class="mr-1" src="../../../assets/img/report_done.svg" alt="">
                                <p class="report_done">Votre demande a bien été traitée</p>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="report?.messages">
                            <p class="text900 mt-4 mb-5">Historique des échanges</p>
                            <ng-container *ngFor="let msg of report?.messages ?? []">
                                <div>   
                                    <p class="mb-1" [ngClass]="msg.expediteur == 1 ? 'report_date_chat' : 'report_date_chat2'">{{msg.date?.toDate() | date: 'dd/MM/yyyy à HH:mm'}}</p>
                                    <div class="card mb-2" [ngClass]="msg.expediteur == 1 ? 'chat-agent' : 'chat-user'">
                                        <p class="chat-text ml-1" [ngClass]="msg.expediteur == 1 ? 'chat-text2' : 'chat-text'">{{msg.message}}</p>
                                        <img [ngClass]="msg.expediteur == 1 ? 'tale_left' : 'tale_right'" [src]="msg.expediteur == 1 ? '../../../assets/images/left_msg.svg' : '../../../assets/images/right_msg.svg' " alt="">
                                    </div>
                                 
                                    <div [ngClass]="msg.expediteur == 1 ? 'logo-agent' : 'logo-user'"  [ngStyle]="{'background-color': msg.expediteur == 1 ? contractColor : 'rgba(64, 128, 237, 1)'}" alt=""></div>
                                </div>
                            </ng-container>
                            <div class="right-inner-addon input-container mt-5">
                                <img class="send_img" src="../../../assets/images/send_msg.svg" (click)="sendMessage()" alt="">
                                <input type="text"  style="border-radius: 25px;"
                                        (keyup)="changeMessage($event)"
                                        (keyup.enter)="sendMessage()"
                                        [value]="message ?? ''"
                                        (change)="changeMessage($event)"
                                       class="form-control input_login" 
                                       placeholder="Envoyer un message" />
                            </div>
                        </ng-container >
                    </div>


                </ng-container>

                <ng-container  *ngIf="section == 'infos'">
                    <div class="ml-5 mr-4 mt-4 mb-4">
                        <p class="title_view mb-4">Données personnelles :</p>
                        <label for="prenom">Prénom : </label>
                        <div id="prenom" class="left-inner-addon input-container">
                            <img src="../../../assets/img/user_icon.svg" alt="">
                            <input type="text" (keyup)="changeSurname($event)" [value]="user?.prenom ?? ''"
                                class="form-control input_infos" 
                                placeholder="Prénom" />
                        </div>
                        <label for="Email">Email : </label>
                        <div class="left-inner-addon input-container">
                            <img src="../../../assets/img/mail_icon.svg" alt="">
                            <input type="text" [value]="user?.email"
                                class="form-control input_infos"
                                placeholder="Votre email" />
                        </div>
                        <p class="modify_pwd mb-4">Modifier mon mot de passe</p>
                        <div class="row mb-4">
                            <div class="col">
                                <label class="form-switch">
                                    <input type="checkbox" disabled [checked]="cguCheck">
                                    <i></i>
                                    J’accepte les Conditions Générales d’Utilisation
                                </label>
                            </div>
                            <p></p>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <label class="form-switch" >
                                    <input type="checkbox" disabled  [checked]="privacyCheck">
                                    <i></i>
                                    J’accepte la Politique de Confidentialité
                                </label>
                            </div>
                        </div>
                        <button class="button_register mb-3" (click)="saveInfoAccount()">Enregistrer</button>
                    </div>
                       
                </ng-container> 
            </div>
        </div>
    </div>
    </div>
</ng-container>
