import { Timestamp } from '@angular/fire/firestore'


export class User_Montri {
	cgu? : boolean
	codePostal? : string
	contratId? :  string
    prenom? : string;
	telephone? : string;
	email? : string;
	parrainage? : Parrainage;
	contrats ?: {[key: string]:infosContrat}

	constructor({cgu = false,codePostal = "", parrainage = { parrain: '', contratId :'', valide: false},telephone ="",contratId="",prenom ="",email="",contrats = {}}){
		this.cgu =  cgu
		this.codePostal = codePostal
		this.contratId =  contratId
		this.parrainage = parrainage
		this.prenom =  prenom
		this.email = email
		this.telephone = telephone
		this.contrats = contrats
	}
}

export class Parrainage{
	parrain? : String;
	contratId? : String;
	valide? : boolean
}

export  class infosContrat{
	alerts : Alerts;
	badges : String[];
	stats  : Stats;
	adresse : Adresse;

	constructor({alerts = {collecte : false, generale : false, encombranys : false,dechetteries : false} ,badges = [],stats = {kmVoiture : 0, points : 0, scans : 0},adresse = {adresse : "", coordonne : "", numero : "",rue :"",ville : ""}}){
		this.alerts = alerts
		this.badges = badges
		this.stats = stats
		this.adresse = adresse
	}
}

export class Alerts{
	perturbations? : boolean;
	generales? : boolean;
	encombrants? : boolean;
	dechetteries? : boolean;

	constructor({perturbations = false, generales = false, encombranys = false,dechetteries = false}){
		this.perturbations = false
		this.generales = false
		this.encombrants = false
		this.dechetteries = false
	}
}

export class Stats{
	kmVoiture? : number;
	points? : number;
	scans? : number;

	constructor({kmVoiture = 0, points = 0, scans = 0}){
		this.kmVoiture = kmVoiture;
		this.points = points;
		this.scans = scans;
	}
}

export class Adresse{

	adresse? : String;
	coordonne? : String ;
	numero? : String ;
	rue? : String;
	ville? : String;

	constructor({adresse = "", coordonne = "", numero = "",rue="",ville=""}){
		this.adresse = adresse;
		this.coordonne = coordonne;
		this.numero =numero;
		this.rue = rue;
		this.ville = ville;
	}
}



export class Reporting {
	type : string
	motif : string 
	adresse : string
	message : string
	prenom : string
	nom : string
	email : string
	telephone : string
	idContrat : string
	status : string
	date : Timestamp
	image : string
	coordonne : {}

	constructor({type = "", motif = "", adresse = "", message = "", 
		prenom = "", nom = "", email = "", telephone = "", idContrat = "",
		status = "a_traiter", date = Timestamp.now(), image = "", coordonne = ""}){
		this.type = type
		this.motif = motif
		this.adresse = adresse
		this.message = message
		this.prenom = prenom
		this.nom = nom
		this.email = email
		this.telephone = telephone
		this.idContrat = idContrat
		this.status = status
		this.date = date
		this.image = image
		this.coordonne = coordonne
	}
}