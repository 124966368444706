<div class="container my-5">
    <div class="row justify-content-center">
        <img class="col-6 col-md-4" src="../../../../../assets/images/icone-invit-page.svg" alt="">
    </div>
    <div class="row justify-content-center">
        <h5 class="col-6 col-md-4 my-5" style="font-family: avenir_heavy;font-size: 120%;text-align: center;">Vous avez déjà un compte</h5>
    </div>
    <div class="row justify-content-center">
        <button type="button" class="btn btn-warning mb-5 col-6 col-md-3 py-3" (click)='toParrain()'  style="font-family: avenir;color: white;">Parrainer des amis</button>
    </div>
</div>
