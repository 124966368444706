import { Injectable, NgZone } from '@angular/core';
// import { User } from '../services/user';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { User_Montri } from '../models/user';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthentificationService {
    userData: any;

    constructor(
      public afs: AngularFirestore, // Inject Firestore service
      public afAuth: AngularFireAuth, // Inject Firebase auth service
      public router: Router,
      public ngZone: NgZone, // NgZone service to remove outside scope warning
      private userService: UserService,

    ) {
        
      this.afAuth.authState.subscribe((user) => {
        if (user) {
          this.userData = user;
          localStorage.setItem('user', JSON.stringify(this.userData));
          JSON.parse(localStorage.getItem('user')!);
          
        } else {
          localStorage.setItem('user', 'null');
          JSON.parse(localStorage.getItem('user')!);
        }
      });
       
    }

    
    SignIn(email: string, password: string, contratId: string) {
      return this.afAuth
        .signInWithEmailAndPassword(email, password)
        .then((result) => {
          // this.ngZone.run(() => {
          //   this.router.navigate(['dashboard']);
          // });
          this.SetUserData(result.user, contratId);
        })
        .catch((error) => {
          // window.alert("Vérifiez votre email et mot de passe.");
          return 'error';
        });
    }

    // Sign up with email/password
    SignUp(email: string, password: string, contratId: string) {
      return this.afAuth
        .createUserWithEmailAndPassword(email, password)
        .then((result) => {
          /* Call the SendVerificaitonMail() function when new user sign 
          up and returns promise */
          // this.SendVerificationMail();
          // this.ngZone.run(() => {
          //   this.router.navigate(['dashboard']);
          // });
          this.SetUserData(result.user, contratId);
        })
        .catch((error) => {
          window.alert(error.message);
        });
    }

    // Send email verfificaiton when new user sign up
    SendVerificationMail() {
      return this.afAuth.currentUser
        .then((u: any) => u.sendEmailVerification())
        .then(() => {
          this.router.navigate(['verify-email-address']);
        });
    }

    // Reset Forggot password
    ForgotPassword(passwordResetEmail: string) {
      return this.afAuth
        .sendPasswordResetEmail(passwordResetEmail)
        .then(() => {
          window.alert("L'email de réinitialisation du mot de passe a été envoyé, vérifiez votre boîte de réception.");
        })
        .catch((error) => {
          
        });
    }

    changePassword(passwordResetEmail: string) {
      return this.afAuth
        .sendPasswordResetEmail(passwordResetEmail)
        .then(() => {
          window.alert("L'email de changement de mot de passe a été envoyé, vérifiez votre boîte de réception.");
        })
        .catch((error) => {
          
        });
    }
    // Returns true when user is looged in and email is verified
    get isLoggedIn(): boolean {
      const user = JSON.parse(localStorage.getItem('user')!);
      return user !== null && user.emailVerified !== false ? true : false;
    }
    // Sign in with Google
    GoogleAuth(contratId: string) {
      return this.AuthLogin(new auth.GoogleAuthProvider(), contratId).then((res: any) => {
        if (res) {
          this.router.navigate(['dashboard']);
        }
      });
    }
    // Auth logic to run auth providers
    AuthLogin(provider: any, contratId?: string) {
      return this.afAuth
        .signInWithPopup(provider)
        .then((result) => {
          this.ngZone.run(() => {
            this.router.navigate(['dashboard']);
          });
          this.SetUserData(result.user, contratId);
        })
        .catch((error) => {
          
        });
    }

    /* Setting up user data when sign in with username/password, 
    sign up with username/password and sign in with social auth  
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
    SetUserData(user: any, contratId?: string) {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      const userData: User_Montri = {
        contratId: contratId,
        email: user.email
      };
      return userRef.set(userData, {
        merge: true,
      });
    }

    // Sign out
    SignOut() {
      return this.afAuth.signOut().then(() => {
        localStorage.removeItem('user');
        this.router.navigate(['sign-in']);
      });
    }
}
