// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    widget: false,
    contratOrigin : "preContrats",
    resourcesOrigin : "preResources",
    firebase: {
        apiKey: "AIzaSyBtQQkpUDo4ijzeuMabarQx15gRDxO7bUM",
        authDomain: "montri-dev.firebaseapp.com",
        databaseURL: "https://montri-dev.firebaseio.com",
        projectId: "montri-dev",
        storageBucket: "montri-dev.appspot.com",
        messagingSenderId: "891669451314",
        appId: "1:891669451314:web:5ddbaae66f8badf547e22b",
        measurementId: "G-8TLYNPXPZN",	
    }
}

// export const environment = {
//     production: true,
//     widget: false,
//     contratOrigin : "preContrats",
//     resourcesOrigin : "preResources",
//     firebase: {
//         apiKey: "AIzaSyAKmj4B-saJO-RfLbylXYyMqRy13IixKXg",
//         authDomain: "montri-preprod.firebaseapp.com",
//         databaseURL: "https://montri-preprod.firebaseio.com",
//         projectId: "montri-preprod",
//         storageBucket: "montri-preprod.appspot.com",
//         messagingSenderId: "525429733807",
//         appId: "1:525429733807:web:a8280650b9558e2e54575a",
//         measurementId: "G-Q01LRJ04YT"
//     }
// }

// export const environment = {
//     production: true,
//     widget: false,
// 	contratOrigin: "preContrats",
// 	resourcesOrigin: "preResources",
// 	firebase: {
// 		apiKey: "AIzaSyBZYdy_PEvcryYFbcWDboZhcnQRgA8ypts",
// 		authDomain: "montri-prod.firebaseapp.com",
// 		databaseURL: "https://montri-prod.firebaseio.com",
// 		projectId: "montri-prod",
// 		storageBucket: "montri-prod.appspot.com",
// 		messagingSenderId: "770091497466",
// 		appId: "1:770091497466:web:f274d5e0dd55303c4fd643",
// 		measurementId: "G-8TLYNPXPZN"
// 	}
// };

// export const environment = {
//     production: true,
//     widget: false,
//     contratOrigin : "preContrats",
//     resourcesOrigin : "preResources",
//     firebase: {
//         apiKey: "AIzaSyAKmj4B-saJO-RfLbylXYyMqRy13IixKXg",
//         authDomain: "montri-preprod.firebaseapp.com",
//         databaseURL: "https://montri-preprod.firebaseio.com",
//         projectId: "montri-preprod",
//         storageBucket: "montri-preprod.appspot.com",
//         messagingSenderId: "525429733807",
//         appId: "1:525429733807:web:a8280650b9558e2e54575a",
//         measurementId: "G-Q01LRJ04YT"
//     }
// }

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
