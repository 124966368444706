import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { CookieService } from 'ngx-cookie-service'
import { NgxSpinnerService } from 'ngx-spinner'
import { ContratService } from "./services/contract.service"
import { Auth, onAuthStateChanged, signInAnonymously } from '@angular/fire/auth'
import { Alert, Contrat } from "./models/contrat"
import { getDownloadURL, ref, StorageReference, Storage } from "@angular/fire/storage"
import { environment } from "src/environments/environment"
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ContactComponent } from "./components/contact/contact.component"
import { ToastrService } from 'ngx-toastr'
import { getAnalytics, logEvent } from "firebase/analytics"
import { UserService } from "./services/user.service"

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})

export class AppComponent implements OnInit {
	search: boolean = true
	overlay_hidden : boolean = true
	hidden_footer = true

    lienFacebook?: string = "http://www.facebook.com/UzerInc"
    lienTwitter?: string = "https://twitter.com/uzerofficial"
    lienYoutube?: string
    lienInstagram?: string

    contractColor?: string = "#F47000"
    hover_lik1?: boolean = true
    hover_lik2?: boolean = false
    hover_lik3?: boolean = false
    hover_lik4?: boolean = false
    current_window?: string = "home"
    contract?: Contrat 
    logoUrl?: string = "./../../assets/img/logo_montri.png"
    headerFooterHidden = true
    isChangingCommune = false
    hasContract = false
    alert?: Alert
    userData:any

    constructor(
        private auth: Auth,
        private router: Router,
        private cookieService : CookieService,
        private spinner: NgxSpinnerService,
        private contractService: ContratService,
        private userService: UserService,
        private storage: Storage,
        private modalService: NgbModal,
    ) {}

    ngOnInit() {

        onAuthStateChanged(this.auth, (user) => {
            if (user) {
                this.userData = user
                this.contractService.contract.asObservable().subscribe( async (contrat) => {
                    if (!contrat.contrat) {
                        console.log("Contrat vide")
                        this.hasContract = false
                        const analytics = getAnalytics()
                        if (window.location.href.indexOf('metropole-rouen-normandie') != -1) {
                            this.spinner.show()
                            this.contractService.postalCode = "76000"
                            logEvent(analytics, 'mt_traficweb', { codePostal: this.contractService.postalCode, contratId: "ROUEN" })
                            this.contractService.loadContract("ROUEN")
                            return
                        } else if (window.location.href.indexOf('est-ensemble') != -1) {
                            this.spinner.show()
                            this.contractService.postalCode = "93000"
                            logEvent(analytics, 'mt_traficweb', { codePostal: this.contractService.postalCode, contratId: "Est Ensemble" })
                            this.contractService.loadContract("Est Ensemble")
                            return
                        } else if (window.location.href.indexOf('sundgau') != -1) {
                            this.spinner.show()
                            this.contractService.postalCode = "68130"
                            logEvent(analytics, 'mt_traficweb', { codePostal: this.contractService.postalCode, contratId: "SUNDGAU" })
                            this.contractService.loadContract("SUNDGAU")
                            return
                        } else if (window.location.href.indexOf('cdciledere') != -1) {
                            this.spinner.show()
                            this.contractService.postalCode = "17111"
                            logEvent(analytics, 'mt_traficweb', { codePostal: this.contractService.postalCode, contratId: "ILE DE RE" })
                            this.contractService.loadContract("ILE DE RE")
                            return
                        } else if (window.location.href.indexOf('valdevienne') != -1) {
                            this.spinner.show()
                            this.contractService.postalCode = "87700"
                            logEvent(analytics, 'mt_traficweb', { codePostal: this.contractService.postalCode, contratId: "CCVDV" })
                            this.contractService.loadContract("CCVDV")
                            return
                        } else if (window.location.href.indexOf('agglo-royan') != -1) {
                            this.spinner.show()
                            this.contractService.postalCode = "17200"
                            logEvent(analytics, 'mt_traficweb', { codePostal: this.contractService.postalCode, contratId: "ROYAN" })
                            this.contractService.loadContract("ROYAN")
                            return
                        } else if (window.location.href.indexOf('convergence-garonne') != -1) {
                                this.spinner.show()
                                this.contractService.postalCode = "31370"
                                logEvent(analytics, 'mt_traficweb', { codePostal: this.contractService.postalCode, contratId: "CCCG" })
                                this.contractService.loadContract("CCCG")
                                return
                        } else if (this.cookieService.check('postalCode')) {
                            this.spinner.show()
                            const postalCode = this.cookieService.get('postalCode')
                            this.contractService.postalCode = postalCode
                            if (postalCode.includes("Montri-")) {
                                this.contractService.loadContract(postalCode)
                            } else {
                                this.contractService.loadContractByPostalCode(postalCode)
                            }
                            return
                        } else if (window.location.pathname == "/" || this.isChangingCommune) {
                            this.isChangingCommune = false
                            this.router.navigateByUrl('/welcome')
                        }
                        this.headerFooterHidden = false
                    } else {
                        console.log('Constrat set')
                        if (contrat.contrat.idContrat) { // Load user info if user is logged in
                            this.userService.loadUser(contrat.contrat.idContrat ,user.uid)
                        }
                        this.hasContract = true
                        this.contract = contrat
                        let imageRef: StorageReference
                        if(this.contract.Web?.logoSite){
                            imageRef = ref(this.storage, this.contract?.contrat?.idContrat + '/' + environment.resourcesOrigin + '/' + this.contract.Web?.logoSite)
                            getDownloadURL(imageRef).then((href) => {
                                this.logoUrl = href
                            })
                        }else{
                            imageRef = ref(this.storage, this.contract?.contrat?.idContrat + '/' + environment.resourcesOrigin + '/logoapp.png')
                            getDownloadURL(imageRef).then((href) => {
                                this.logoUrl = href
                            })
                        }
                        
                        this.lienFacebook = contrat.reseauxSociaux?.lienFacebook
                        this.lienTwitter = contrat.reseauxSociaux?.lienTwitter
                        this.lienYoutube = contrat.reseauxSociaux?.lienYoutube
                        this.lienInstagram = contrat.reseauxSociaux?.lienInstagram
                        this.contractColor = contrat.design?.couleurPrincipale
                        if (window.location.pathname == "/") {
                            this.router.navigateByUrl('/home')
                        }
                        this.headerFooterHidden = false
                        window.scrollTo(0, 0)
                        this.spinner.hide()
                        if (contrat.contrat?.idContrat) {
                            await this.contractService.loadActualites(contrat.contrat?.idContrat)
                            await this.contractService.loadCarte(contrat.contrat?.idContrat)
                            await this.contractService.loadCalendrier(contrat.contrat?.idContrat)
                        }
                    }
                })
                this.contractService.navButtons.asObservable().subscribe((buttonName) => {
                    this.current_window = buttonName
                    this.hover_lik1 = false
                    this.hover_lik2 = false
                    this.hover_lik3 = false
                    this.hover_lik4 = false
                })
            } else {
                console.log('Compte anonyme crée')
                signInAnonymously(this.auth)
            }
        })

        this.contractService.alert.asObservable().subscribe((alert) => {
            if (alert.type == "success") {
                this.contractService.openSuccessToast(alert?.message, "")
            } else if (alert.type == "error") {
                this.contractService.openErrorToast(alert?.message, "")
            }
        })
    }	

    logout(){
        this.auth.signOut()
    }

    changeCommune() {
        this.cookieService.delete("postalCode")
        this.lienFacebook = "http://www.facebook.com/UzerInc"
        this.lienTwitter = "https://twitter.com/uzerofficial"
        this.lienYoutube = undefined
        this.lienInstagram = undefined
        this.logoUrl = "./../../assets/img/logo_montri.png"
        this.contractColor = "#F47000"
        this.hasContract = false

        this.router.navigateByUrl('/welcome')

        window.scrollTo(0, 0)
    }

    showContactModal() {
        window.scroll(0,0)
        let options: NgbModalOptions = {}
        options.centered = true
        this.modalService.dismissAll()
        this.modalService.open(ContactComponent, options)
    }
}