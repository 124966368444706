<div class="display_all">

	<div class="container w-100 h-100">
		<div class="row">
			<div class="col-lg-4"></div>
			<div class="col-lg-4 align-content-center p-5 ml-3 mr-3">
				<div class="d-flex flex-row justify-content-center">
					<h5 class="avenir_heavy">Créer votre compte</h5>
				</div>

				<form class="mt-4" (submit)="onSubmit(name_signup.value, email_signup.value, password_signup.value)">

					<div class="input-group mb-3">
						<div class="input-group-prepend">
							<span class="input-group-text" style="border-radius: 15px 0px 0px 15px;">
								<i class="fa fa-user fa-xs" style="font-size: 100%;" aria-hidden="true"></i>
							</span>
						</div>
						<input autocomplete="off" style="border-radius: 0px 15px 15px 0px;" type="text"
							class="form-control avenir_light" id="name_signup" aria-describedby="Nom" placeholder="Nom"
							 #name_signup>
					</div>
					<div class="input-group mb-3">
						<div class="input-group-prepend">
							<span class="input-group-text" style="border-radius: 15px 0px 0px 15px;">
								<i class="fa fa-envelope fa-xs" style="font-size: 75%;" aria-hidden="true"></i>
							</span>
						</div>
						<input autocomplete="off" style="border-radius: 0px 15px 15px 0px;" type="email"
							class="form-control avenir_light" id="email_signup" aria-describedby="emailHelp"
							placeholder="Email"  #email_signup>
					</div>

					<div class="input-group mb-3">
						<div class="input-group-prepend">
							<span class="input-group-text" style="border-radius: 15px 0px 0px 15px;">
								<i class="fa fa-lock fa-xs" aria-hidden="true"></i>
							</span>
						</div>
						<input type="password" style="border-radius: 0px 15px 15px 0px;"
							class="form-control avenir_light" id="password_signup" placeholder="Mot de passe"
							aria-describedby="passwordHelpInline" #password_signup>

					</div>
					<small id="passwordHelpInline" class="form-text text-muted" style="font-size: 10px;">
						Votre mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre
						et un caractère spécial.
					</small>



					<div class="row" style="margin-top: 25px !important;">

						<div class="col-2 d-flex flex-column justify-content-center">
							<label class="switch">
								<input type="checkbox" (click)="acceptecgu($event)" id="cgu">
								<span class="slider round" id="cgu1"></span>
							</label>
						</div>

						<div class="col-10 d-flex flex-column pt-2">
							<a href="https://www.uzer.eu/cgu-montri" target="_blank" style="color: black;">
								<p class="avenir_light" style="font-size: 12px;margin-left: 7px;"> Conditions Génerales
									d'Utilisation</p>
							</a>
						</div>
					</div>

					<div class="row mt-1">
						<div class="col-2 d-flex flex-column justify-content-center">
							<label class="switch">
								<input type="checkbox" (click)="acceptechart($event)" id="chart">
								<span class="slider round" id="chart1"></span>
							</label>
						</div>
						<div class="col-10 d-flex flex-column pt-2">
							<a href="https://www.uzer.eu/charte-de-confidentialite-montri" class="bg-green"
								target="_blank" style="color: black;">
								<p class="avenir_light" style="font-size: 12px;margin-left: 7px;"> Charte de
									Confidentialité</p>
							</a>
						</div>
					</div>



					<button autocomplete="off" type="submit" style="border-radius: 10px;margin-top: 20px;"
						class="btn btn-dark w-100 avenir_light" id="Login_btn_signup">M'inscrire</button>

				</form>

			</div>
			<div class="col-md-4"></div>
		</div>
	</div>
</div>
