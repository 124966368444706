<div class="container-fluid main ">
	<div class="row" style="z-index: 3; position: relative;" >
		<div class="col-12 d-flex flex-column justify-content-center align-items-center" id="background">
			<div class="container">
				<div class="row">
					<div class="col-12 d-flex flex-column align-items-center justify-content-center pt-4">
						<h1 class="avenir_heavy responsive_font1">Bienvenue sur Montri</h1>
						<p class="mt-4 avenir_light responsive_font">Renseignez votre code postal pour retrouver tous
							les <br /> services de votre collectivité </p>
					</div>
				</div>
				<div class="row">
					<div class="col-md-3">
					</div>
					<div class="bs-autocomplete-container form-group col-md-6  m-0 p-3  dropdown">
						<input type="text" id="inputcity" [ngStyle]="{'color': contractColor}"
							style="font-family:Arial, FontAwesome" aria-label="Default" autocomplete="off"
							aria-describedby="inputGroup-sizing-default"
							class="form-control input_city bs-autocomplete-input card_design"
							onfocus="this.placeholder = ''"
							onblur=" this.placeholder = '&#61442;   Renseignez votre code postal'" id="postalcode"
							[ngModel]="filterCity" (ngModelChange)="filterEntriesCity($event)" data-toggle="dropdown"
							(focus)="inputFieldFocusedCity()" placeholder="&#61442;    Renseignez votre code postal" />

						<ul class="dropdown-menu card_design_menu w-100" role="menu" [hidden]="search">
							<li><a href="javascript:void(0)" class="dropdown-item pl-2 bg-gray avenir_light"
									[ngStyle]="{'color': contractColor}" *ngFor="let entry of filteredEntitiesCity"
									(click)="selectEntryCity(entry)">
									{{entry.codePostal}} {{entry.nom}}</a></li>
						</ul>
					</div>
					<div class="col-md-3"></div>
				</div>
			</div>
		</div>
	</div>

	<div class="row" style="z-index: 1;position: static;" style="background-color:  #F47000;">
		<div class="container">
			<div class="row">
				<div class="col-lg d-flex flex-column justify-content-around align-items-center pt-4 pb-3 wrap">
					<img src="../../assets/img/Actualités et alertes.svg" class="centered style_icons" style="width: 6rem;" />
					<p class="avenir_light" style="color: white;text-align: center;">Actualités et alertes personnalisées</p>
				</div>
				<div class="col-lg d-flex flex-column justify-content-around align-items-center pt-4 pb-3 wrap">
					<img src="../../assets/img/Guide du tri_w.svg" class="centered style_icons" style="width: 6rem;" />
					<p class="avenir_light" style="color: white;text-align: center;">Consignes de tri géolocalisées</p>
				</div>
				<div class="col-lg d-flex flex-column justify-content-around align-items-center pt-4 pb-3 wrap">
					<img src="../../assets/img/Carte.svg" class="centered style_icons" style="width: 6rem;" />
					<p class="avenir_light" style="color: white;text-align: center;">Carte des points de collecte autour de vous</p>
				</div>
				<div class="col-lg d-flex flex-column justify-content-around align-items-center pt-4 pb-3 wrap">
					<img src="../../assets/img/Calendrier.svg" class="centered style_icons" style="width: 6rem;" />
					<p class="avenir_light" style="color: white;text-align: center;">Calendrier de collecte à votre adresse</p>
				</div>
				<div class="col-lg d-flex flex-column justify-content-around align-items-center pt-4 pb-3 wrap">
					<img src="../../assets/img/Demandes et signalements_w.svg" class="centered style_icons" style="width: 6rem;" />
					<p class="avenir_light" style="color: white;text-align: center;">Formulaires de demandes et signalements</p>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-6 d-flex flex-row justify-content-center align-items-center pt-4 pb-4">
			<div class="row">
				<div class="col-md-2">
				</div>
				<div class="col-md-8 d-flex flex-column align-items-start justify-content-start">
					<h2 class="avenir_heavy">Vos services en quelques clics</h2>
					<p class="mt-2" style="font-size: large;white-space: pre-line">Retrouvez dans
						l'application tous vos services liés à la gestion de vos déchets (carte des points de
						collecte, demandes, signalement, actualités, calendrier de collecte etc.)</p>
				</div>
				<div class="col-md-2">
				</div>
			</div>
		</div>
		<div class="col-md-6 d-flex flex-row align-items-center justify-content-center pt-4 pb-4">
			<img  src="assets\img\service_simple.png" class="site_screen_app" alt="img">
		</div>
	</div>
	<div class="row flex-md-row-reverse">
		<div class="col-md-6 d-flex flex-row justify-content-center align-items-center  pt-4 pb-4">
			<div class="row">
				<div class="col-md-2">
				</div>
				<div class="col-md-8 d-flex flex-column align-items-start justify-content-start">
					<h2 class="avenir_heavy">Triez sans erreur</h2>
					<p class="mt-2" style="font-size: large;white-space: pre-line">L'application
						Montri passe à la loupe vos produits, vous indique comment les recycler
						et leur impact sur votre santé. <br /> Scannez les code-barres de vos produits pour
						faire le bons choix !</p>
				</div>
				<div class="col-md-2">
				</div>
			</div>
		</div>
		<div class="col-md-6 d-flex flex-row align-items-center justify-content-center pt-4 pb-4">
			<img  src="assets\img\triez_sans_erreur.png" class="site_screen_app" alt="img">
		</div>
	</div>

	<div class="container">
		<div class="row">
			<div class="col-2"></div>
			<div class="col-8">
				<div class="row d-flex flex-row justify-content-center pt-5">
					<h4 [ngStyle]="{'color': contractColor, 'text-align': 'center' }" class="avenir_heavy">Télécharger
						gratuitement
						l'application</h4>
				</div>
				<div class="row d-flex flex-row justify-content-center pt-1">
					<h6 class="avenir_light" style="font-weight: bold;">4.5 étoiles</h6>
				</div>
				<div class="row d-flex flex-row justify-content-center pt-0">
					<ngx-stars [readonly]="true" [color]="'#FFD700'" [size]="2" [initialStars]="4.5"></ngx-stars>
				</div>
			</div>
			<div class="col-2"></div>
		</div>

	</div>
	<div class="container">
		<div class="row  pt-4 pb-5">
			<div class="col-2"></div>
			<div class="col-8 stores">
				<a href="https://apps.apple.com/fr/app/montri-trier-solidaire/id1458927235" target="_blank"><img
						src="../../assets/img/Badge-Appstore.png"
						style="width: 200px;height: 60px;border: 1px solid white; border-radius: 10px;"
						class="stores_padding_right btn_apple"></a>
				<a href="https://play.google.com/store/apps/details?id=eu.uzer.montri&hl=fr" target="_blank"><img
						src="../../assets/img/Badge-GooglePlay.png"
						style="width: 200px;height: 60px;border-radius: 10px;"
						class="stores_padding_left btn_google"></a>
			</div>
			<div class="col-2"></div>
		</div>
	</div>
</div>
