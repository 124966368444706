import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuideComponent } from './components/guide/guide.component';
import { NewsComponent } from './components/actus/actus.component';
import { ReportingComponent } from './components/signalements/signalements.component';
import { CalendrierComponent } from './components/calendrier/calendrier.component';
import { CalendrierSansPolygonesComponent } from './components/calendrier-sans-polygones/calendrier-sans-polygones.component';
import { MapComponent } from './components/map/carte.component';
import { MontriComponent } from './components/montri/montri.component';
import { HomeComponent } from './components/home/home.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ExistComponent } from './components/parrainage/exist/exist.component';
import { ToSignupComponent } from './components/parrainage/to-signup/to-signup.component';
import { SignupComponent } from './components/parrainage/signup/signup.component';
import { SuccessfullComponent } from './components/parrainage/successfull/successfull.component';

const routes: Routes = [
    { path: 'services/news', component: NewsComponent },
    { path: 'services/guide', component: GuideComponent },
    { path: 'services/reporting', component: ReportingComponent },
    { path: 'services/calendar', component: CalendrierComponent },
    { path: 'services/map', component: MapComponent },
    { path: 'services/calendarWithoutPolygones', component: CalendrierSansPolygonesComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class WidgetRoutingModule { }