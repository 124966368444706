import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation } from "@angular/core"
import { CollecteWaste, ScheduleInfo, Street, Ville } from "./../../models/collecte-waste"
import { Contrat } from "./../../models/contrat"
import { ContratService } from "./../../services/contract.service"
import { environment } from "src/environments/environment"
import { Storage, ref, getDownloadURL, StorageReference } from "@angular/fire/storage"
import { HostListener } from "@angular/core"
import { Router } from "@angular/router"
import { Auth, onAuthStateChanged, signInAnonymously } from '@angular/fire/auth'
import { CookieService } from "ngx-cookie-service"
import { combineLatest } from "rxjs"

@Component({
    selector: 'calendriersans-widget',
    templateUrl: './calendrier-sans-polygones.component.html',
    styleUrls: ['./calendrier-sans-polygones.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CalendrierSansPolygonesComponent implements OnInit {
    @Input() cities: Array<any> = []
    @Input() streets: Array<any> = []
    @Input() numeros: Array<any> = []
    @Input() entryPropertyCity?: string
    @Input() entryPropertyStreet?: string
    @Input() entryPropertyNum?: string
    @Input() inputPlaceholder: string = "Search..."
    @Output() onEntrySelected = new EventEmitter()
    showResults: boolean = false
    filteredEntitiesCity?: string[]
    filteredEntitiesStreet: any
    filteredEntitiesNum: any
    filterCity?: string
    filterStreet?: string
    filterNum?: string
    contractColor?: string
    contrat: Contrat = new Contrat()
    collecte?: CollecteWaste
    hide_street: string = "none"
    hide_num: string = "none"
    hidden_spinner: boolean = true
    collecteResult?: ScheduleInfo[] = []
    flux_verre: string = ""
    flux_omr: string = ""
    flux_recyclables: string = ""
    flux_encombrants: string = ""
    flux_bac_dechets_verts: string = ""
    flux_organique: string = ""
    description: string = ""
    @Input() contractId :string = ""
    hideCitiesInput = true
    calendarUrl?:string

    constructor(
        private contractService: ContratService,
        private storage: Storage,
        private router : Router,
        private auth: Auth,
        private cookieService : CookieService
    ) { 
        this.getScreenSize()
    }

    screenHeight: number = 0
    screenWidth: number = 0

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?: Event) {
        this.screenHeight = window.innerHeight
        this.screenWidth = window.innerWidth
    }

    getShortLibelle(libelle: string | undefined) {
        if (libelle) {
            if (libelle.includes("apport")) {
                return "Carte"
            }
        }
        return ""
    }

    ngOnInit() {
        onAuthStateChanged(this.auth, (user) => {
            if (user) {
                combineLatest([this.contractService.contract.asObservable(), this.contractService.calendrier_obs]).subscribe((res) => {
                    const contrat = res[0]
                    const calendrier = res[1]

                    if (!contrat.contrat) {
                        console.log("Contrat vide")
                        if (environment.widget) {
                            this.contractService.loadContract(this.contractId)
                        }  
                    } else {
                        console.log('Constrat set')
                        this.contractService.serviceButtonChangeColor.next("Calendrier de collecte")
                        if (!calendrier.villes) {
                            if (environment.widget && contrat.contrat?.idContrat){
                                this.contractService.loadCalendrier(contrat.contrat?.idContrat)
                            }
                        } else {
                            this.collecte = calendrier
                            this.contractColor = contrat.design?.couleurPrincipale
                            this.contrat = contrat
                            this.cities = []
                            for (let city of this.collecte.villes ?? []) {
                                this.cities.push(city.nom)
                            }
                            this.hideCitiesInput = false
                            const contratColor06 = this.hexToRgbA(this.contractColor, "0.6")
                            const contratColor0075 = this.hexToRgbA(this.contractColor, "0.075")
                            const input2 = document.getElementById("inputcity") as HTMLInputElement
                            const input3 = document.getElementById("inputstreet") as HTMLInputElement
                            const input4 = document.getElementById("inputnum") as HTMLInputElement
                            var self = this

                            input2.addEventListener("focus", function () {
                                this.style.borderColor = self.contractColor ?? ""
                                this.style.boxShadow = "inset 0 1px 1px " + contratColor0075 + ", 0 0 8px " + contratColor06
                                self.hide_street = "none"
                                self.hide_num = "none"
                                self.collecteResult = []
                            })

                            input2.addEventListener("blur", function () {
                                this.style.borderColor = "gray"
                                this.style.boxShadow = "none"
                            })
    
                            input3.addEventListener("focus", function () {
                                this.style.borderColor = self.contractColor ?? ""
                                this.style.boxShadow = "inset 0 1px 1px " + contratColor0075 + ", 0 0 8px " + contratColor06
                                self.hide_num = "none"
                                self.collecteResult = []
                            })
    
                            input3.addEventListener("blur", function () {
                                this.style.borderColor = "gray"
                                this.style.boxShadow = "none"
                            })
    
                            input4.addEventListener("focus", function () {
                                this.style.borderColor = self.contractColor ?? ""
                                this.style.boxShadow = "inset 0 1px 1px " + contratColor0075 + ", 0 0 8px " + contratColor06
                                self.collecteResult = []
                            })
                
                            input4.addEventListener("blur", function () {
                                this.style.borderColor = "gray"
                                this.style.boxShadow = "none"
                            })
                        }
                    }
                })
            } else {
                console.log('Compte anonyme crée')
                signInAnonymously(this.auth)
            }
        })
    }
  
    goToFluxInMap(flux: string | undefined) {
        if (flux) {
            this.contractService.defaultFlux = flux
            this.contractService.serviceButtonChangeColor.next("Carte des points de collecte")
            this.router.navigateByUrl("/services/map")
        }
    }
  
    filterEntriesCity(filter: string) {
        if (typeof this.entryPropertyCity != "undefined") {
            this.filteredEntitiesCity = this.cities.filter((item) =>
                item[this.entryPropertyCity ?? ""].toLowerCase().indexOf(filter.toLowerCase()) == 0
            ).slice(0,10)
        } else {
            this.filteredEntitiesCity = this.cities.filter((item) => 
                item.toLowerCase().indexOf(filter.toLowerCase()) == 0
            ).slice(0,10)
        }
    }
  
    inputFieldFocusedCity() {
        this.calendarUrl = undefined
        this.filterCity = ""
        this.filterStreet = ""
        this.filterNum = ""
        this.filteredEntitiesCity = this.cities
        setTimeout(() => {
            let input = document.getElementById("inputcity") as HTMLElement
            input.addEventListener("keydown", function (e) {
                (document.querySelectorAll("menucity")[0] as any).focus()
            })
        }, 200)
    }
  
    filterEntriesStreet(filter: string) {
        if (typeof this.entryPropertyStreet != "undefined") {
            this.filteredEntitiesStreet = this.streets.filter(
            (item) =>
                item[this.entryPropertyStreet ?? ""]
                .toLowerCase()
                .indexOf(filter.toLowerCase()) == 0
            ).slice(0,10)
        } else {
            this.filteredEntitiesStreet = this.streets.filter(
            (item) => item.toLowerCase().indexOf(filter.toLowerCase()) == 0
            ).slice(0,10)
        }
    }
  
    inputFieldFocusedStreet() {
        this.calendarUrl = undefined
        this.filterStreet = ""
        this.filterNum = ""
        this.filteredEntitiesStreet = this.streets
        let input = document.getElementById("inputstreet") as HTMLElement
            input.addEventListener("keydown", function (e) {
            (document.querySelectorAll("menustreet")[0] as any).focus()
            })
    }
  
    filterEntriesNum(filter: string) {
        if (typeof this.entryPropertyStreet != "undefined") {
            this.filteredEntitiesNum = this.numeros.filter((item) =>
                item[this.entryPropertyNum ?? ""]
                .toLowerCase()
                .indexOf(filter.toLowerCase()) == 0
            ).slice(0,10)
        } else {
            this.filteredEntitiesNum = this.numeros.filter((item) => 
                item.toLowerCase().indexOf(filter.toLowerCase()) == 0
            ).slice(0,10)
        }
    }
  
    inputFieldFocusedNum() {
        this.calendarUrl = undefined
        this.filterNum = ""
        let input = document.getElementById("inputnum") as HTMLElement
        input.addEventListener("keydown", function (e) {
            (document.querySelectorAll("menunum")[0] as any).focus()
        })
    }
  
    selectEntryCity(entry: any) {
        if (typeof this.entryPropertyCity != "undefined") {
            this.filterCity = entry[this.entryPropertyCity]
        } else {
            this.filterCity = entry
        }
        this.streets = []
        for (let city of this.collecte?.villes ?? []) {
            if (city.nom == entry) {
                if ("infos" in city && city.infos?.length != 0) {
                    this.calendarUrl = city.calendarUrl
                    this.showCalendar(city.infos ?? [])
                } else {
                    for (let street of city.rues ?? []) {
                        this.streets.push(street.nom)
                    }
                    this.hide_street = "block"
                }
            }
        }
    }
  
    selectEntryStreet(entry: any) {
        if (typeof this.entryPropertyStreet != "undefined") {
            this.filterStreet = entry[this.entryPropertyStreet]
        } else {
            this.filterStreet = entry
        }
        this.numeros = []
        for (let city of this.collecte?.villes ?? []) {
            if (city.nom == this.filterCity) {
                for (let street of city.rues ?? []) {
                    if (street.nom == entry) {
                        if ("infos" in street && street.infos?.length != 0) {
                            this.calendarUrl = street.calendarUrl
                            this.showCalendar(street.infos ?? [])
                        } else {
                            for (let numero of street.numeros ?? []) {
                                this.numeros.push(numero.nom)
                            }
                            this.hide_num = "block"
                        }
                    }
                }
            }
        }
    }
  
    selectEntryNum(entry : any) {
        if (typeof this.entryPropertyNum != "undefined") {
            this.filterNum = entry[this.entryPropertyNum]
        } else {
            this.filterNum = entry
        }
  
        for (let city of this.collecte?.villes ?? []) {
            if (city.nom == this.filterCity) {
                for (let street of city.rues ?? []) {
                    if (street.nom == this.filterStreet) {
                        for (let numero of street.numeros ?? []) {
                            if (numero.nom == entry) {
                                this.calendarUrl = numero.calendarUrl
                                this.showCalendar(numero.infos ?? [])
                            }
                        }
                    }
                }
            }
        }
    }

    HexCode(color:any){
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
        const hex = `#${((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)}`
        return hex
    }
  
    hexToRgbA(hex:string | undefined , opacity: string) {
        if (hex) {
            let c : any
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split("")
                if (c.length == 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]]
                }
                c = "0x" + c.join("")
                return ("rgba(" +
                        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
                        "," +
                        opacity +
                        ")"
                )
            }
        }
        throw new Error("Bad Hex" + hex)
    }

    getFluxIcon(item: ScheduleInfo) {
        let imageRef:StorageReference
        switch(item.fluxCollecte) {
            case "Ordures ménagères":
                imageRef = ref(this.storage, this.contrat.contrat?.idContrat + "/" + environment.resourcesOrigin + "/calendar_omr.png")
                getDownloadURL(imageRef).then((href) => {
                    item.icon = href
                })
                break
            case "Emballages recyclables":
                imageRef = ref(this.storage, this.contrat.contrat?.idContrat + "/" + environment.resourcesOrigin + "/calendar_recyclables.png")
                getDownloadURL(imageRef).then((href) => {
                    item.icon = href
                })
                break
            case "Emballages en verre":
                imageRef = ref(this.storage, this.contrat.contrat?.idContrat + "/" + environment.resourcesOrigin + "/calendar_verre.png")
                getDownloadURL(imageRef).then((href) => {
                    item.icon = href
                })
                break
            case "Encombrants":
                imageRef = ref(this.storage, this.contrat.contrat?.idContrat + "/" + environment.resourcesOrigin + "/calendar_encombrants.png")
                getDownloadURL(imageRef).then((href) => {
                    item.icon = href
                })
                break
            case "Déchets végétaux":
                imageRef = ref(this.storage, this.contrat.contrat?.idContrat + "/" + environment.resourcesOrigin + "/calendar_vegetaux.png")
                getDownloadURL(imageRef).then((href) => {
                    item.icon = href
                })
                break
            case "Déchets organiques":
                imageRef = ref(this.storage, this.contrat.contrat?.idContrat + "/" + environment.resourcesOrigin + "/calendar_organique.png")
                getDownloadURL(imageRef).then((href) => {
                    item.icon = href
                })
                break
            case "Papier":
                imageRef = ref(this.storage, this.contrat.contrat?.idContrat + "/" + environment.resourcesOrigin + "/calendar_papier.png")
                getDownloadURL(imageRef).then((href) => {
                    item.icon = href
                })
                break
            default:
                return ""
        }
        return ""
    }

    getIconBottom(agenda: string | undefined) {
        if (agenda) {
            const lowercasedTime = agenda.toLowerCase()
            if(lowercasedTime.includes("lundi") || 
            lowercasedTime.includes("mardi") || 
            lowercasedTime.includes("mercredi") || 
            lowercasedTime.includes("jeudi") || 
            lowercasedTime.includes("vendredi") || 
            lowercasedTime.includes("samedi") || 
            lowercasedTime.includes("dimanche") ||
            lowercasedTime.includes("tous les jours")) {
                return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Ficon-collecte-pap.svg?alt=media`
            }
        }
        return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Ficon-collecte-pav.svg?alt=media`
	}

    getAgendaIcon(agenda: string | undefined) {
        if (agenda) {
            const lowercasedTime = agenda.toLowerCase()
            if(lowercasedTime.includes("lundi") || 
            lowercasedTime.includes("mardi") || 
            lowercasedTime.includes("mercredi") || 
            lowercasedTime.includes("jeudi") || 
            lowercasedTime.includes("vendredi") || 
            lowercasedTime.includes("samedi") || 
            lowercasedTime.includes("dimanche") ||
            lowercasedTime.includes("tous les jours")) {
                return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Fagenda.png?alt=media`
            }
        }
        return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Fapport.png?alt=media`
	}
  
	showCalendar(infos: ScheduleInfo[]) {
		this.collecteResult = []

        for (let element of infos) {
            let length = element.libelleBouton?.length ?? 0
            this.getFluxIcon(element)
            element.backgroudColor = this.hexToRgbA(this.contrat?.design?.flux?.filter(item => item.nom == element.fluxCollecte)[0].couleur, "0.3")
            element.libelleBouton = (length > 0) ? element.libelleBouton?.toString() : "Trouver un point d'apport"
            const split = element.commentaire?.split(" - ")
            if (split) {
                element.agenda = element.commentaire?.split(" - ")[0]
                let slashSplit = element.agenda?.split("//")
                if (slashSplit && slashSplit.length > 1) {
                    element.agenda = ""
                    for (let slash of slashSplit) {
                        let dotsSplit = slash.split(" : ")
                        if (dotsSplit && dotsSplit.length >= 2) {
                            element.agenda += `<img class="mt-calendar-icon pb-1 pr-1" src="${this.getAgendaIcon(dotsSplit[1])}"/><span class="item_top">${dotsSplit[0]} : </span>${dotsSplit[1]}<br>`
                        }
                    }
                    element.agenda = element.agenda.slice(0, -4)
                } else {
                    element.agenda = `<img class="mt-calendar-icon pb-1 pr-1" src="${this.getAgendaIcon(element.agenda)}"/>${element.agenda}`
                }
                if (split.length > 1) {
                    element.warning = element.commentaire?.split(" - ")[1]
                }
            }
            this.collecteResult.push(element)
        }

	}

    openUrl(url: string) {
        window.open(url)
    }
}
