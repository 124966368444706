export class CollecteWaste {
    villes?: Ville[]
	flux?: FluxCalendrier[]
    collecteKo?: string
	constructor({villes = [] , flux = [] }) {
        this.villes = villes
        this.flux = flux
    }
}

export class FluxCalendrier {
    nom?:   string;
    image?: string;
    texte?: string;
    libelleBouton?: string;
    flux?: string;
    zones? : Zone[];
    url?: string;
    carte?: string[];
    ordre?: number;
    couleur?: string
    icon? : string
    backgroudColor? : string
    agenda? : string
    warning? : string
    found? : boolean
    dateVerif? : boolean
    zone? : Zone
    agendaIcon?: string
}

export class Zone {
    consigne? : string;
    jour? : string;
    polygones? : string[]
    infos? :  infosPolygons
}

export class Ville {
    infos?: Info[];
	nom?:   string;
	rues? : Street[];
    calendarUrl? : string
}

export class Street{
	nom?: String
    numeros?: numberStreet[]
    infos?: ScheduleInfo[]
    calendarUrl? : string
}

export class numberStreet{
	nom?: String
    infos?: ScheduleInfo[]
    calendarUrl? : string
}

export class Day {
    lundi? : boolean
    mardi? : boolean
    mercredi? : boolean
    jeudi? : boolean
    vendredi? : boolean
    samedi? : boolean
    dimanche? : boolean
}

export class ScheduleInfo {
    a_publier? : boolean
    btn_pav? : boolean
    commentaire? : string
    texte? : string
    daysPerMonth? : string
    description? : string
    fluxCarte? : string
    fluxCollecte? : string
    frequence? : string
    horaires? : string
    jours? : Day
    libelleBouton? : string
    nomPolygone? : string
    periode? : string
    periodeType? : string
    defaultLibelleBouton? : string
    defaultDescription? : string
    icon? : string
    backgroudColor? : string
    url? : string
    agenda? : string
    warning? : string
}

export class Info {
    jour?:           string;
    flux?:           string;
    carte?:         string[];
    texte?:         string;
    libelleBouton?: string;
    consigne?:      string;
    ordre?:         BigInteger;
}

export class infosPolygons{
    nomPolygone? : string;
    a_publier? : boolean; // ! => pas de collecte à cette adresse
    fluxCollecte? : string;
    periodeType? : string; // personnalise ou toute l'année
    fluxCarte? : string;
    periode? : string; // du 12 avril ...
    frequence? : string; // 
    jours? : Jours ;
    daysPerMonth? : string; // tous les 1er...
    horaires? :  string; //03:03
    commentaire? : string; // Ceci est un commentaire
    btn_pav? : boolean; // ! = Bouton libelle n'apparait pas 
    libelleBouton? : string;
    description? : string;
    url? : string
    pairImpair? : string
}

export class Jours{
    
    lundi? : boolean; 
    mardi? : boolean;
    mercredi? : boolean;
    jeudi? : boolean;
    vendredi? : boolean;
    samedi? : boolean;
    dimanche? : boolean

    constructor(jours?: Jours){
        this.lundi = jours?.lundi; 
        this.mardi = jours?.mardi; 
        this.mercredi = jours?.mercredi;
        this.jeudi = jours?.jeudi;
        this.vendredi = jours?.vendredi;
        this.samedi = jours?.samedi;
        this.dimanche = jours?.dimanche;
    }

    datesToString() : string {

        var text: string = ""
        
        if (this.lundi == true) {
            text += "lundi, "
        }
        if (this.mardi == true) {
            text += "mardi, "
        }
        if (this.mercredi == true) {
            text += "mercredi, "
        }
        if (this.jeudi == true) {
            text += "jeudi, "
        }
        if (this.vendredi == true) {
            text += "vendredi, "
        }
        if (this.samedi == true) {
            text += "samedi, "
        }
        if (this.dimanche == true) {
            text += "dimanche, "
        }
        if (text == "") {
            return ""
        }
        return `Les ${text.slice(0, -2)}`
	}
}
