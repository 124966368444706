import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { ActivatedRoute, Router } from "@angular/router"
import { Component, OnInit } from "@angular/core"
import { Contrat } from 'src/app/models/contrat'
import { ContratService } from 'src/app/services/contract.service'
import { ContactComponent } from '../../contact/contact.component'
import { Auth, EmailAuthProvider, linkWithCredential, onAuthStateChanged, signInAnonymously } from '@angular/fire/auth'
import { doc, Firestore, setDoc } from '@angular/fire/firestore'
import { infosContrat, User_Montri } from 'src/app/models/user'

@Component({
    selector: "app-signup",
    templateUrl: "./signup.component.html",
    styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
    contractColor?: String
    contract?: Contrat
    contratId: string = ""
    focus: Boolean = false
    cgu : boolean = false
    chart : boolean  =  false
    id:string = ""

    constructor(
        private route: ActivatedRoute,
        private modalService: NgbModal, 
        private auth: Auth,
        private router: Router,
        private db : Firestore,
        private contractService: ContratService
    ) { }


    doInit() {
        this.route.params.subscribe( params => {
            if(params['id'] && params['contrat']) {
                this.id = params['id'];
                this.contratId = params['contrat']
                
                this.contractService.contract.asObservable().subscribe((contrat) => {
                    if (!contrat.contrat) {
                        console.log("Contrat vide")
                        //this.contractService.loadContract(this.contratId)
                        } else {
                            this.contractColor = contrat.design?.couleurPrincipale;
                            const contractColor = this.contractColor as string;

                            const contratColor06 = this.hexToRgbA(contractColor, "0.6")
                            const contratColor0075 = this.hexToRgbA(contractColor, "0.075")
                            const input1 = document.getElementById("email_signup")
                            input1?.addEventListener("focus", function () {
                                this.style.borderColor = contractColor
                                this.style.boxShadow = "inset 0 1px 1px " + contratColor0075 + ", 0 0 8px " + contratColor06
                                });
                
                            input1?.addEventListener("blur", function () {
                                this.style.borderColor = "gray"
                                this.style.boxShadow = "none"
                            });
                
                            const input2 = document.getElementById("password_signup")
                            input2?.addEventListener("focus", function () {
                                this.style.borderColor = contractColor
                                this.style.boxShadow =
                                    "inset 0 1px 1px " + contratColor0075 + ", 0 0 8px " + contratColor06
                            });
                
                            input2?.addEventListener("blur", function () {
                                this.style.borderColor = "gray"
                                this.style.boxShadow = "none"
                            })
                
                            const input3 = document.getElementById("name_signup");
                            input3?.addEventListener("focus", function () {
                                this.style.borderColor = contractColor
                                this.style.boxShadow = "inset 0 1px 1px " + contratColor0075 + ", 0 0 8px " + contratColor06
                            })
                
                            input3?.addEventListener("blur", function () {
                                this.style.borderColor = "gray"
                                this.style.boxShadow = "none"
                            })
                
                            const btn_login = document.getElementById("Login_btn_signup") as HTMLElement
                            btn_login.style.backgroundColor = contractColor
                            btn_login.style.borderColor = contractColor
                            btn_login.style.boxShadow = "inset 0 1px 1px " + contractColor
                
                            let element = document.getElementById("inscrivez-vous_signup") as HTMLElement
                            element.style.backgroundColor = "white"
                            element.style.color = contractColor
                            element.style.borderColor = contractColor
                            element.style.boxShadow ="inset 0 1px 1px white"
                        }
                })

            }
        })
    }

    ngOnInit() {
        window.scrollTo(0, 0)
        onAuthStateChanged(this.auth, (user) => {
            if (user) {
                console.log('Utilisateur déjà connecté')
                this.doInit()
            }else{
                console.log('Un nouveua compte anonyme va être crée')
                signInAnonymously(this.auth)
            }
        })
    }
	
    hexToRgbA(hex: any, opacity: any) {
        let c: any
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split("");
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = "0x" + c.join("");
            return (
                "rgba(" +
                [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
                "," +
                opacity +
                ")"
            );
        }
        throw new Error("Bad Hex");
    }

    onSubmit(name:string, email:string, password:string) {
        var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$/;
        if(this.cgu == false){
            this.contractService.openErrorToast("Merci d'accepter les conditions génerales d'utilisation", "Erreur")
        }
        else {
            if(this.chart == false){
                this.contractService.openErrorToast("Merci d'accepter la charte de confidentialité", "Erreur")
            }else{
                if(password.match(decimal)){
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
                        this.signUpParrainage(email, password, name,this.id,this.contratId);
                    }else{
                        this.contractService.openErrorToast("Merci d'entrer une adresse email valide", "Erreur")
                    }
                }else{
                    this.contractService.openErrorToast("Votre mot de passe doit comporter un minimum de 8 caractères, au moins une lettre minuscule, une lettre majuscule, un chiffre numérique et un caractère spécial", "Erreur")
                }
            }
        }
    }

    signUpParrainage(email:string, password:string, name:string, id:any, contratId:string) {
            var self = this;
            let credential = EmailAuthProvider.credential(email,password);
            if(this.auth.currentUser){
                linkWithCredential(this.auth.currentUser, credential).then(	
                    async (userCredential) => {
                    const user = userCredential.user
                    var user_montri = new User_Montri({})
                    var contract_Infos = new infosContrat({})
                    user_montri.email = email
                    user_montri.prenom = name
                    user_montri.contratId = contratId
                    if(user_montri.contrats){
                        user_montri.contrats[contratId] = {...contract_Infos}
                    }
                    if(user_montri.parrainage) {
                        user_montri.parrainage.contratId = contratId
                        user_montri.parrainage.parrain = id
                    }
                    await setDoc(doc(this.db, "users", user.uid), {...user_montri})
                        .then(() => {
                            this.router.navigateByUrl('successfull')
                        })
                        .catch((error:any) => {
                            self.contractService.openErrorToast("Nous avons rencontré un problème, veuillez réessayer", "Erreur")
                        })
                },
                function (error:any) {
                    if(error.code === "auth/provider-already-linked"){
                        self.router.navigateByUrl('exist')
                        return
                    }else{
                        self.contractService.openErrorToast("Nous avons rencontré un problème, veuillez réessayer", "Erreur")
                    }
                }
                )
            }			
    }

    showContactModal() {
        window.scrollTo(0, 0);
        let options: NgbModalOptions = {}
        options.centered = true
        this.modalService.dismissAll()
        const modalRef = this.modalService.open(ContactComponent, options)

    }

    acceptecgu(event : any) {
        const isChecked = (event.target as HTMLInputElement).checked;
        if (isChecked) {
            this.cgu = true;
            let cgu = document.getElementById("cgu") as HTMLElement
            cgu.style.backgroundColor = this.contractColor as string
            let cgu1 = document.getElementById("cgu1") as HTMLElement
            cgu1.style.backgroundColor = this.contractColor as string
        }
        else{
            this.cgu = false;
            let cgu = document.getElementById("cgu") as HTMLElement
            cgu.style.backgroundColor = '#ccc'
            let cgu1 = document.getElementById("cgu1") as HTMLElement
            cgu1.style.backgroundColor = '#ccc'
        }
    }

    acceptechart(event: any) {
        const isChecked = (event.target as HTMLInputElement).checked;
        if (isChecked) {
            this.chart  = true
            let chart = document.getElementById("chart") as HTMLElement
            let chart1 = document.getElementById("chart1") as HTMLElement
            chart.style.backgroundColor = this.contractColor as string
            chart1.style.backgroundColor = this.contractColor as string

        }
        else{
            this.chart  = false;
            let chart = document.getElementById("chart") as HTMLElement
            let chart1 = document.getElementById("chart1") as HTMLElement
            chart.style.backgroundColor = '#ccc'
            chart1.style.backgroundColor = '#ccc'
        }
    }
}
