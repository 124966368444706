<div class="container ml-5 mt-3">
    <div class="row position-relative">
        <div class="col">
            <ngx-spinner [hidden]="hidden_spinner" bdColor = "rgba(255, 255, 255, 0.8)" size = "medium" color = "#A6A6A6" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: #A6A6A6" >Localisation en cours...</p></ngx-spinner>
            <div id="map" style="border-radius: 25px !important;width: 100%;"></div>
        </div>
        <div class="col mt-3 ml-3 position-absolute" style="width: 25rem;">
            <div class="row">
                <div class="col-9 col-md-11 pr-0">
                    <AutocompleteComponent (setAddress)="getAddress($event)" style="width: 100%;" adressType="geocode" color="#FFFFFF"
                    class="placeholder_style"></AutocompleteComponent>
                </div>
                <div class="col-3 col-md-1">
                    <button type="button" class="btn btn-primary localize-btn" (click)="gerUserPosition()"
                        id="search">
                        <i class="fas fa-map-marker-alt" style="color: #333333"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="col ml-3 position-absolute" style="width: 13rem; margin-top: 3.5rem;"> 
            <div class="row">
                <div class="col">
                    <div id="{{'button'+ i}}" class="card mt-3 card_services flex-row flex-wrap" *ngFor="let item of wasteFlux; index as i"
                        (click)="selectFlux(i)">
                        <div class="card-header border-0 bg-transparent pl-3 pr-2 pt-0 pb-1 my-auto">
                            <img id="{{'image'+ i}}" [src]="item.url_menu" style="width:1rem;height: 1rem;filter:brightness(0%)">
                        </div>
                        <div class="card-block my-auto pr-2 pt-1">
                            <p id="{{'label'+ i}}" class="card-text avenir_heavy" style="font-size: 11px;">{{ item.displayName ?? item.nom }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
