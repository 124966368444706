import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { FormBuilder, FormGroup } from "@angular/forms"
import { CookieService } from 'ngx-cookie-service'
import { NgxSpinnerService } from 'ngx-spinner'
import { ContratService } from "src/app/services/contract.service"
import { City } from "src/app/models/contrat"
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { VoteComponent } from "../vote/vote.component"
import { Subscription } from "rxjs"
import { getAnalytics, logEvent } from "firebase/analytics"

declare const Cards: any;

@Component({
    selector: "app-welcome",
    templateUrl: "./welcome.component.html",
    styleUrls: ["./welcome.component.scss"]
})
export class WelcomeComponent implements OnInit {
    filteredEntitiesCity: City[] = []
    cities: City[] = []
    search: boolean = true
    overlay_hidden : boolean = true
    hidden_footer = true
    contractColor = "#FF8C00"
    filterCity?: string
    entry?: City

    subscription?: Subscription

    constructor(
        private contratService: ContratService,
        private router: Router,
        private cookieService : CookieService,
        private modalService: NgbModal
    ) {}

    ngOnDestroy() {
        this.subscription?.unsubscribe()
    }

    ngOnInit() {
        window.scrollTo(0, 0)
        this.contratService.navButtons.next("")
        this.contratService.cities.asObservable().subscribe((cities) => { 
            this.search = (cities.length == 0)
            this.filteredEntitiesCity = cities
        })
        this.subscription = this.contratService.contract.asObservable().subscribe((contrat) => {
            if (contrat.contrat) {
                if (this.entry?.codePostal) {
                    try {
                        const axeptio = JSON.parse(this.cookieService.get("axeptio_cookies"))
                        if (axeptio.Montri) {
                            if (contrat.contrat?.nomContrat?.includes("Montri-")) {
                                this.cookieService.set("postalCode", contrat.contrat?.nomContrat, 390)
                            } else {
                                this.cookieService.set("postalCode", this.entry.codePostal, 390)
                            }
                        }
                    } catch { }
                    this.contratService.postalCode = this.entry.codePostal
                    const analytics = getAnalytics()
                    logEvent(analytics, 'mt_traficweb', { codePostal: this.entry.codePostal, contratId: contrat.contrat.idContrat })
                    this.router.navigateByUrl("/home")
                }
            } else if (this.entry) {
                this.showModal(this.entry)
            }
        })
    }

    filterEntriesCity(filter: any) {
        if (filter.length == 5) {
            this.contratService.loadCities(filter)
        } else {
            this.search = true
            this.filteredEntitiesCity = []
        }
    }

    goToServices() {
        const link = ["/services"]
        this.router.navigate(link)
    }

    goToUrl(url: string): void {
        document.location.href = url
    }

    hexToRgbA(hex: any, opacity: any) {
        let c: any
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split("")
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]]
            }
            c = "0x" + c.join("")
            return (
                "rgba(" +
                [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
                "," +
                opacity +
                ")"
            )
        }
        throw new Error("Bad Hex")
    }

    inputFieldFocusedCity() {
        this.filteredEntitiesCity = []
        this.search = true
        var element = document
            .getElementById("inputcity")
        if(element != null){
            this.search = false
            element.addEventListener("keydown", function (e) {
                (document.querySelectorAll("menucity")[0] as any).focus()
            })
        }
    }

    selectEntryCity(entry: City) {
        if (entry.active == false) {
            this.showModal(entry)
            return
        }
        if (entry.codePostal) {
            this.entry = entry
            this.contratService.loadContractByPostalCode(entry.codePostal)
        }
    }

    showModal(city? : City) {
        window.scrollTo(0, 0)
        let options: NgbModalOptions = {}
        options.centered = true
        this.modalService.dismissAll()
        const modalRef = this.modalService.open(VoteComponent, options)
        modalRef.componentInstance.city = city
        modalRef.componentInstance.contractColor = this.contractColor
        modalRef.result.then(() => {
            if (city?.extension) {
                this.contratService.loadContract(city?.extension)
            }
        })
    }	
}
