import { Component, Input, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { Contrat, Service } from 'src/app/models/contrat'
import { ContratService } from 'src/app/services/contract.service'
import { CookieService } from "ngx-cookie-service"
import { Storage, ref, getDownloadURL, StorageReference } from "@angular/fire/storage"
import { environment } from "src/environments/environment"

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"]
})

export class HomeComponent implements OnInit {

    contractColor?: String
    contrat?: Contrat
    services: Service[] = []
    hidden_page = false
    logoUrl?: string
    imageCover?: string

    @Input() contractId = 'Est Ensemble'

    constructor(
        private contractService: ContratService,
        private router: Router,
        private storage: Storage,
        private cookieService : CookieService
    ) { }

    ngOnInit() {
        window.scrollTo(0, 0)
        this.contractService.navButtons.next("home")
        this.contractService.contract.asObservable().subscribe((contrat) => {
            if (!contrat.contrat) {
                console.log("Contrat vide")
            } else {
                console.log('Constrat set')
                this.contrat = contrat
                this.contractColor = this.contrat.design?.couleurPrincipale

                if (this.contrat.contrat?.nomContrat?.includes("Montri-")) {
                    this.services = (this.contrat?.services ?? []).filter(serv => serv.web)
                } else {
                    this.services = (this.contrat?.services ?? []).filter(serv => {
                        let active = true
                        if (serv.villes) {
                            active = serv.villes.filter(ville => ((ville.zipCode == this.contractService.postalCode) && ville.active)).length != 0
                        }
                        return serv.active && serv.web && active
                    })
                    if(this.services.length == 0) {
                        this.services = (this.contrat?.services ?? []).filter(serv => {
                            let active = true
                            if (serv.villes) {
                                active = serv.villes.filter(ville => ((ville.zipCode == this.contractService.postalCode) && ville.active)).length != 0
                            }
                            return serv.active && serv.activeWeb 
                        })
                    }
                    console.log(this.services)
                }

                let imageRef: StorageReference
                if(this.contrat.Web?.logoSite){
                    imageRef = ref(this.storage, this.contrat?.contrat?.idContrat + '/' + environment.resourcesOrigin + '/' + this.contrat.Web?.logoSite)
                    getDownloadURL(imageRef).then((href) => {
                        this.logoUrl = href
                    })
                }else{
                    imageRef = ref(this.storage, this.contrat?.contrat?.idContrat + '/' + environment.resourcesOrigin + '/logoapp.png' )
                    getDownloadURL(imageRef).then((href) => {
                        this.logoUrl = href
                    })
                }
                
                if(this.contrat.Web?.imageCover){
                    imageRef = ref(this.storage, this.contrat?.contrat?.idContrat + '/' + environment.resourcesOrigin + '/' + this.contrat.Web?.imageCover)
                    getDownloadURL(imageRef).then((href) => {
                        this.imageCover = href
                    })
                }else{
                    imageRef = ref(this.storage, this.contrat?.contrat?.idContrat + '/' + environment.resourcesOrigin + '/' + this.contrat.interface?.designWeb?.imageCover)
                    getDownloadURL(imageRef).then((href) => {
                        this.imageCover = href
                    })
                }

                if(this.contrat?.Web?.Home_tabs){
                    for (const elem of this.contrat?.Web?.Home_tabs ?? []) {
                        imageRef = ref(this.storage, this.contrat?.contrat?.idContrat + '/' + environment.resourcesOrigin + '/' + elem.image)
                        getDownloadURL(imageRef).then((href) => {
                            elem.url_image = href
                        })
                    }
                }else{
                    for (const elem of this.contrat?.interface?.designWeb?.Home_tabs ?? []) {
                        imageRef = ref(this.storage, this.contrat?.contrat?.idContrat + '/' + environment.resourcesOrigin + '/' + elem.image)
                        getDownloadURL(imageRef).then((href) => {
                            elem.image = href
                        })
                    }
                }
                
            }
        })
    }

	goToServices(service: Service) {
        if(service.active != undefined){
            if (!service.active) {
                return
            }
        }
        if(service.activeWeb != undefined){
            if (!service.activeWeb) {
                return
            }
        }
        this.hidden_page = true
        this.contractService.navButtons.next("services")
        this.contractService.serviceButtonAction.next(service.nom)
        this.router.navigateByUrl('/services')
	}

    getServiceIcon(service: Service) {
        switch(service.nom) {
            case "Actualités et événements":
                return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Ficon-menu-actus.png?alt=media`
            case "Guide du tri":
                return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Ficon-menu-guide.png?alt=media`
            case "Calendrier de collecte":
                return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Ficon-menu-calendrier.png?alt=media`
            case "Carte des points de collecte":
                return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Ficon-menu-carte.png?alt=media`
            case "Demandes et Signalements":
                return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Ficon-menu-signalements.png?alt=media`
            default:
                return ''
        }
    }
}
