<div class="card-deck ml-5 mr-5">
    <div class="card border-0 mt-3 mx-auto mx-sm-3" *ngFor="let actu of actus" style="min-width: 15rem;max-width: 15rem;">
        <img class="card-img-top" style="min-height:10rem;max-height:10rem;cursor:pointer" src="{{ actu.image }}" (click)="onClickImage(actu.url)" >
        <div class="card-body pb-0 pl-0 pr-0">
            <h6 class="card-title text mb-0" style="-webkit-line-clamp:2;line-clamp:2">{{ actu.titre }}</h6>
            <p class="card-text mb-2"><small class="text-muted" style="color: #AAAAAA!important;font-size:70%;">{{ formatDate(actu.date) }}</small></p>
            <p class="card-text text" style="font-size:80%;" >{{ actu.description }}</p>
        </div>
        <div class="card-footer pt-2 pb-4 pl-0 pr-0 border-0 bg-transparent">
            <a href="{{ actu.url }}" target="_blank" class="card-link" [ngStyle]="{'color':contractColor}">> Lire la suite</a>
        </div>
    </div>
</div>
