import { Component, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Auth, onAuthStateChanged, signInAnonymously } from '@angular/fire/auth'
import { LocationStrategy } from '@angular/common'
import { Router } from '@angular/router'

@Component({
    selector: 'app-exist',
    templateUrl: './exist.component.html',
    styleUrls: ['./exist.component.scss']
})

export class ExistComponent implements OnInit {

    constructor(
        private router: Router,
        private location: LocationStrategy,
        private auth : Auth
    ) {
        history.pushState(null, "", window.location.href)
        // check if back or forward button is pressed.
        this.location.onPopState(() => {
            this.router.navigateByUrl('/successfull')
        })
    }

    ngOnInit(): void {
        window.scrollTo(0, 0)
        onAuthStateChanged(this.auth, (user) => {
            if (user) {
                console.log('Compte user créé')
            } else {
                console.log('Un nouveau compte anonyme va être créé')
                signInAnonymously(this.auth)
            }
        })
    }

    toParrain(){
        let param
        if(environment.firebase.projectId === 'montri-dev'){
            param = 'montridev'
        }else if(environment.firebase.projectId === 'montri-preprod'){
            param = 'montripreprod'
        }else if(environment.contratOrigin === 'preContrats' && environment.firebase.projectId === 'montri-prod'){
            param = 'montridemo'
        }else if(environment.contratOrigin === 'contrats' && environment.firebase.projectId === 'montri-prod'){
            param = 'montri'
        }
        window.location.href = `https://${param}.page.link/parrainage`
    }
}
