import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuideComponent } from './components/guide/guide.component';
import { NewsComponent } from './components/actus/actus.component';
import { ReportingComponent } from './components/signalements/signalements.component';
import { CalendrierComponent } from './components/calendrier/calendrier.component';
import { CalendrierSansPolygonesComponent } from './components/calendrier-sans-polygones/calendrier-sans-polygones.component';
import { MapComponent } from './components/map/carte.component';
import { MontriComponent } from './components/montri/montri.component';
import { HomeComponent } from './components/home/home.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ExistComponent } from './components/parrainage/exist/exist.component';
import { ToSignupComponent } from './components/parrainage/to-signup/to-signup.component';
import { SignupComponent } from './components/parrainage/signup/signup.component';
import { SuccessfullComponent } from './components/parrainage/successfull/successfull.component';
import { SigninComponent } from './components/signin/signin.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';

const routes: Routes = [
    { path: 'welcome', component: WelcomeComponent },
    { path: 'home', component: HomeComponent},
    { path: 'signin', component: SigninComponent },
    { path: 'signup', component: SignUpComponent },
    {path:'dashboard', component: DashboardComponent},
    { path: 'services', component: MontriComponent, children : [
        { path: 'news', component: NewsComponent },
        { path: 'guide', component: GuideComponent },
        { path: 'reporting', component: ReportingComponent },
        { path: 'calendar', component: CalendrierComponent },
        { path: 'map', component: MapComponent },
        { path: 'calendarWithoutPolygones', component: CalendrierSansPolygonesComponent }
    ]},
    { path: 'parrainage/:contrat/:id', component: ToSignupComponent },
    { path: 'signup/:contrat/:id', component: SignupComponent },
    { path: 'successfull', component: SuccessfullComponent },
    { path: 'exist', component: ExistComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }