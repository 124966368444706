import { environment } from '../../environments/environment'
import { Contrat, Actualites, Subservice, City, Vote, Alert, Report, Messages } from "../models/contrat"
import { Injectable } from "@angular/core"
import { addDoc, collection, doc, Firestore, getDoc, query, Timestamp, where } from "@angular/fire/firestore"
import { BehaviorSubject } from "rxjs"
import { DestinatairesList } from '../models/destinataires'
import * as moment from "moment"
import { CollecteWaste } from '../models/collecte-waste'
import { CarteFlux, Time } from '../models/waste-flux-model'
import { getStorage, uploadBytes } from '@firebase/storage'
import { getDownloadURL, ref } from '@angular/fire/storage'
import { getDocs } from '@firebase/firestore'
import { ToastrService } from 'ngx-toastr'
import { CustomToast } from '../components/toast/toast.component'
import { User_Montri } from '../models/user'
import { setDoc, onSnapshot } from 'firebase/firestore'
import { UserService } from './user.service'
import { Message } from '@angular/compiler/src/i18n/i18n_ast'

@Injectable({
	providedIn: "root"
})

export class ContratService {
    contract: BehaviorSubject<Contrat> = new BehaviorSubject(new Contrat())
    actus: BehaviorSubject<Actualites> = new BehaviorSubject({})
    cities : BehaviorSubject<City[]> = new BehaviorSubject<City[]>([])
    destinataires : BehaviorSubject<DestinatairesList> = new BehaviorSubject<DestinatairesList>({})
    destinataires_obs = this.destinataires.asObservable()
    calendrier : BehaviorSubject<CollecteWaste> = new BehaviorSubject<CollecteWaste>({})
    calendrier_obs = this.calendrier.asObservable()
    carte: BehaviorSubject<CarteFlux> = new BehaviorSubject(new CarteFlux())
    skipInit = 0
    defaultFlux = ""
    serviceButtonAction: BehaviorSubject<string> = new BehaviorSubject("")
    serviceButtonChangeColor: BehaviorSubject<string> = new BehaviorSubject("")
    navButtons: BehaviorSubject<string> = new BehaviorSubject("")
    alert: BehaviorSubject<Alert> = new BehaviorSubject<Alert>({})
    postalCode?: string

    constructor(private db: Firestore, private toastr: ToastrService, private userService: UserService) { }

    async loadContract(contractId: string) {
        const docRef = doc(this.db, environment.contratOrigin, contractId)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            let contract = new Contrat().deserialize(docSnap.data())
            if (contract.contrat?.webApp == true || contract.contrat?.nomContrat?.includes("Montri-")) {
                this.contract.next(contract)
            }
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!")
        }
    }

    async loadContractByPostalCode(postalCode: string) {
        const docRef = collection(this.db, environment.contratOrigin)
        const querySnapshot = await getDocs(query(docRef, where("villesListe", "array-contains", postalCode)))
        if (!querySnapshot.empty) {
            let contract = new Contrat().deserialize(querySnapshot.docs[0].data())
            if (contract.contrat?.webApp == true) {
                this.contract.next(contract)
            } else {
                this.contract.next(new Contrat())
            }
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!")
            this.contract.next(new Contrat())
        }
    }

    async loadActualites(contractId: string) {
        const docRefActu = doc(this.db, "actualites", contractId);
        const docSnapActu = await getDoc(docRefActu);
        if (docSnapActu.exists()) {
            let actus = docSnapActu.data() as Actualites
            this.actus.next(docSnapActu.data())
        } else {
            console.log("No such document!")
        }
    }

    async loadCalendrier(contractId: string) {
        const docRefCalendrier = doc(this.db,environment.contratOrigin + '/' + contractId + '/data/calendrier');
        const docSnapCal = await getDoc(docRefCalendrier);
        if (docSnapCal.exists()) {
            let calendrier = docSnapCal.data() 
            this.calendrier.next(docSnapCal.data() ?? {})
        } else {
            console.log("No such document!")
        }
    }

    async loadCarte(contractId: string) {
        const docRefCarte = doc(this.db,environment.contratOrigin + '/' + contractId + '/data/carte');
        const docSnapCal = await getDoc(docRefCarte)
        if (docSnapCal.exists()) {
            let carte = docSnapCal.data() 
            this.carte.next(docSnapCal.data() ?? {})
        } else {
            console.log("No such document!")
        }
    }

    async loadCities(postalCode: string) {
        var cities: City[] = []
        const docsRefCities = collection(this.db, 'villes')
        const docsSnapCities = await getDocs(query(docsRefCities, where("codePostal", "==", postalCode)))
        docsSnapCities.forEach((doc) => {
            cities.push(doc.data())
        })
        this.cities.next(cities)
    }

    async updateReport(report?: Report) {
        console.log(report)
        if(report?.gid){
            const ref = doc(this.db, 'formulaires', report.gid)
            await setDoc(ref, report, { merge: true })
        }
    }

    sendReporting(idContrat : string, selectedItem: Subservice, file: File, destinataire: string, contract: Contrat, uid?: string){
        var photoId = ""

        let formulaires: {[key: string]: any} = {}
        let coordonnees: {[key: string]: any} = {}

        const confirmation = contract.getService("Demandes et Signalements")?.confirmation
        let messages: Messages[] = (selectedItem.messages && confirmation) ? [new Messages(confirmation, 1, Timestamp.now())] : []

        var data = {
            "type": selectedItem.nom,
            "idContrat": idContrat,
            "status": "a_traiter",
            "date": Timestamp.now(),
            "id": `${selectedItem.code ? selectedItem.code : ""}${moment().format('DDMMYYYYHHmmss')}`,
            "destinataire": destinataire,//selectedItem.email
            "formulaires": formulaires,
            "coordonnees": coordonnees,
            "coordonne" : {},
            "uid": uid,
            "messages": messages,
            "majBO": true
        }

        data["formulaires"] = {}
        for (let champ of selectedItem.formulaires?.champs ?? []) {
            if (champ.photoId) {
                data["formulaires"][champ.nom] = champ.photoId
                photoId = champ.photoId
            } else {
                if (champ.value) {
                    data["formulaires"][champ.nom] = champ.value
                }
                if (champ.nom.toLowerCase().indexOf("message") > -1) {
                    if (selectedItem.messages) {
                        data["messages"] = [new Messages(champ.value, 0, Timestamp.now())].concat(messages)
                    }
                }
            }
            if (champ.coordinate) {
                data["coordonne"] = champ.coordinate
            }
        }
        data["messages"] = data["messages"].map((obj)=> {return Object.assign({}, obj)}) // Allow to add document to Firebase

        data["coordonnees"] = {}
        for (let champ of selectedItem.coordonnees?.champs ?? []) {
            if (champ.photoId) {
                data["coordonnees"][champ.nom] = champ.photoId
                photoId = champ.photoId
            } else {
                data["coordonnees"][champ.nom] = champ.value
            }
            if (champ.coordinate) {
                data["coordonne"] = champ.coordinate
            }
        }

        if (photoId != "") {
            const storage = getStorage()
            let photoRef = ref(storage, `${idContrat}/formulaires/${photoId}`);
            uploadBytes(photoRef, file).then((snapshot) => {
                console.log('Uploaded a blob or file!');
                getDownloadURL(photoRef).then((url)=>{
                    this.addFormulaire(data)
                })
                });
        } else {
            this.addFormulaire(data)
        }
    }

    async addFormulaire(formulaire: any) {
        const docRef = await addDoc(collection(this.db, 'formulaires'), {...formulaire})
        console.log("Document written with ID: ", docRef.id);
    }

    sendVote(vote : Vote) {
        const ref = collection(this.db, 'vote')
        addDoc(ref, {...vote})
	}

    openSuccessToast(message?: string, title?: string) {
        this.toastr.show(message, title, {toastComponent: CustomToast, positionClass: 'toast-bottom-right', toastClass: 'toast', closeButton: false, payload: "success"})
    }

    openErrorToast(message?: string, title?: string) {
        this.toastr.show(message, title, {toastComponent: CustomToast, positionClass: 'toast-bottom-right', toastClass: 'toast', closeButton: false, payload: "failed"})
    }
}
