import { environment } from "../../../environments/environment"
import { faSearchLocation } from "@fortawesome/free-solid-svg-icons"
import { ContratService } from "../../services/contract.service"
import { Contrat } from "../../models/contrat"
import { hexToCSSFilter } from "hex-to-css-filter"
import MarkerClusterer from "@googlemaps/markerclustererplus"
import { Auth, onAuthStateChanged, signInAnonymously } from '@angular/fire/auth'

import {
	Time,
	TimeTable,
	Pav,
	Flux,
    CarteFlux
} from "../../models/waste-flux-model"
import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core"
import { Storage, ref, getDownloadURL } from "@angular/fire/storage"
import { NgxSpinnerService } from "ngx-spinner"
import { CookieService } from "ngx-cookie-service"
import { combineLatest, forkJoin } from "rxjs"


@Component({
	selector: "carte-widget",
	templateUrl: "./carte.component.html",
	styleUrls: ["./carte.component.scss"],
})

export class MapComponent implements OnInit {
    map?: google.maps.Map
    infoswindow_adresse = ""
    infoswindow_points = ""
    infos_window_disponibility: boolean = false
    infos_window_url_googlemaps = ""
    infos_window_contact?: string = ""
    infos_window_pav_name?: String = ""
    infos_window_times: string[] = []
    contractColor?: string = ""
    contrat?: Contrat
    carte?: CarteFlux
    wasteFlux: Flux[] = []
    wasteFluxActive: any
    SearchIcon = faSearchLocation
    markers: google.maps.Marker[] = []
    address?: Object
    ids_selected: number[] = []
    allPavs: Pav[] = []
    overlay_hidden: boolean = true
    user_adress: string = ""
    geoloc_lat: number = 0
    geoloc_lng: number = 0
    ordre: number = 0
    pav: Pav = new Pav()
    hideThis: boolean = false
    hidden_spinner : boolean = true
    cssFilter: string = ""
    infoWindow?: google.maps.InfoWindow
    markerZoom?: google.maps.Marker | null
    userMarker?: google.maps.Marker | null
    markerZoomFlux = -1
    fluxClustering: MarkerClusterer[] = []
    options = {
        mapTypeId: "roadmap",
        maxZoom: 50,
        minZoom: 6,
        center: { lat: 46.711, lng: 1.7191 },
        zoom: 6,
        streetViewControl : false,
        mapTypeControl: false,
        fullscreenControl: false
    }
    isSelected: boolean[] = []
    styles = [
        [
            MarkerClusterer.withDefaultStyle({
            width: 35,
            height: 35,
            url: "./../../../../../assets/imagesClusters/people35.png",
            textColor: "#ff00ff",
            textSize: 10,
            }),
            MarkerClusterer.withDefaultStyle({
            width: 45,
            height: 45,
            url: "./../../../../../assets/imagesClusters/people45.png",
            textColor: "#ff0000",
            textSize: 11,
            }),
            MarkerClusterer.withDefaultStyle({
            width: 55,
            height: 55,
            url: "./../../../../../assets/imagesClusters/people55.png",
            textColor: "#ffffff",
            textSize: 12,
            }),
        ],
        [
            MarkerClusterer.withDefaultStyle({
            url: "./../../../../../assets/imagesClusters/conv30.png",
            width: 30,
            height: 27,
            anchorText: [-3, 0],
            anchorIcon: [27, 28],
            textColor: "#ff00ff",
            textSize: 10,
            }),
            MarkerClusterer.withDefaultStyle({
            url: "./../../../../../assets/imagesClusters/conv40.png",
            width: 40,
            height: 36,
            anchorText: [-4, 0],
            anchorIcon: [36, 37],
            textColor: "#ff0000",
            textSize: 11,
            }),
            MarkerClusterer.withDefaultStyle({
            url: "./../../../../../assets/imagesClusters/conv50.png",
            width: 50,
            height: 45,
            anchorText: [-5, 0],
            anchorIcon: [45, 46],
            textColor: "#0000ff",
            textSize: 12,
            }),
        ],
        [
            MarkerClusterer.withDefaultStyle({
            url: "./../../../../../assets/imagesClusters/heart30.png",
            width: 30,
            height: 26,
            anchorIcon: [26, 15],
            textColor: "#ff00ff",
            textSize: 10,
            }),
            MarkerClusterer.withDefaultStyle({
            url: "imagesClusters/heart40.png",
            width: 40,
            height: 35,
            anchorIcon: [35, 20],
            textColor: "#ff0000",
            textSize: 11,
            }),
            MarkerClusterer.withDefaultStyle({
            url: "./../../../../../assets/iimagesClusters/heart50.png",
            width: 50,
            height: 44,
            anchorIcon: [44, 25],
            textSize: 12,
            }),
        ],
        [
            {
            width: 30,
            height: 30,
            className: "custom-clustericon-1",
            },
            {
            width: 40,
            height: 40,
            className: "custom-clustericon-2",
            },
            {
            width: 50,
            height: 50,
            className: "custom-clustericon-3",
            },
        ],
    ]
    wasteFlux2?: Flux[]
    nbDownloadedImages: number = 0
    defaultFlux: number = 0

    @Input() contractId = 'ROUEN'

    constructor(
        private storage: Storage,
        private auth: Auth,
        private contractService: ContratService,
        private ref: ChangeDetectorRef,
        private spinner: NgxSpinnerService,
        private cookieService : CookieService) {
    }

    ngOnInit(): void {

        if (this.contractService.skipInit == 1) {
            this.contractService.skipInit++
        } else if (this.contractService.skipInit == 2) {
            this.contractService.skipInit = 0
            return
        }

        this.ref.detach()

        window.scrollTo(0, 0)

        onAuthStateChanged(this.auth, (user) => {
            if (user) {
                combineLatest([this.contractService.contract.asObservable(), this.contractService.carte.asObservable()]).subscribe((res) => {
                    this.contrat = res[0]
                    this.carte = res[1]
                    if (!this.contrat?.contrat) {
                        console.log("Contrat vide")
                        if (environment.widget) {
                            this.contractService.loadContract(this.contractId)
                        }  
                    } else {
                        console.log('Constrat set')
                        this.contractService.serviceButtonChangeColor.next("Carte des points de collecte")
                        if (this.carte) {
                            this.initWidget(this.contrat, this.carte)
                        }
                    }
                })
            } else {
                console.log('Compte anonyme crée')
                signInAnonymously(this.auth)
            }
        })
    }

    initWidget(contract: Contrat, carte: CarteFlux) {
        var  marqueur = [
            '<?xml version="1.0" encoding="UTF-8" standalone="no"?>',
            '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">',
            '<svg width="90px" height="90px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">',
            '<rect id="Plan-de-travail1" serif:id="Plan de travail1" x="0" y="0" width="50" height="50" style="fill:none;"/>',
            '<g id="Plan-de-travail11" serif:id="Plan de travail1">',
                '<g transform="matrix(0.971084,0,0,0.977779,25,23.0018)">',
                '<path d="M0,-23.024C-10.011,-23.024 -18.156,-14.879 -18.156,-4.868C-18.156,7.557 -1.907,25.798 -1.216,26.57C-0.567,27.292 0.567,27.291 1.217,26.57C1.907,25.798 18.156,7.557 18.156,-4.868C18.156,-14.879 10.013,-23.024 0,-23.024Z" style="fill:{{ color }};fill-rule:nonzero;"/>',
                '</g>',
            '</g>',
            '<image href="{{ image }}" x="17" y="13" height="15" width="15" />',
            '</svg>',
            ].join('\n')

        var cluster = [
            '<?xml version="1.0" encoding="UTF-8" standalone="no"?>',
            '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">',
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">',
            '<g id="cluster" data-name="Cluster">',
            '<g id="Calque_1-2" data-name="Calque 1"> <circle cx="25" cy="25" r="25" style="fill:{{ color }}"/> </g>',
            '</g>',
            '</svg>',
            ].join('\n')

        this.contrat = contract
        this.contractColor = contract.design?.couleurPrincipale
        this.cssFilter = hexToCSSFilter(this.contractColor).filter.slice(0, -1)
        if (!carte.flux) {
            if (environment.widget && contract.contrat?.idContrat) {
                this.contractService.loadCarte(contract.contrat?.idContrat)
            }
        } else {
            this.wasteFlux = carte.flux?.filter(x => x.active) ?? []
            this.wasteFlux = this.wasteFlux.sort((n1,n2) => n1.ordre - n2.ordre)
            this.nbDownloadedImages = 0
            for (let value of this.wasteFlux) {
                const fluxImage = ref(this.storage, this.contrat?.contrat?.idContrat + '/' + environment.resourcesOrigin + '/' + value.image)
                let self = this
                getDownloadURL(fluxImage).then((href) => {
                    var xhr = new XMLHttpRequest()
                    xhr.responseType = 'blob'
                    xhr.onload = (event) => {
                        var blob = xhr.response
                        var reader = new FileReader()
                        reader.readAsDataURL(blob)
                        reader.onloadend = function() {
                            var base64data = reader.result ?? ""
                            var filename_img = xhr.responseURL.split(RegExp(`%2..*%2F(.*?)\?alt`))[1].split(".")[0]+".png"
                            // Trouver dans la liste self.wasteFlux quel flux a cette image
                            for (let [index, value] of self.wasteFlux.entries()) {						
                                if (value.image == filename_img) {
                                    value.url_menu = xhr.responseURL
                                    value.svg_cluster = cluster.replace('{{ color }}', value.couleur)	
                                    value.svg_marqueur = marqueur.replace('{{ color }}', value.couleur).replace('{{ image }}', base64data.toString())
                                    if (value.defaut == true) {
                                        self.defaultFlux = index
                                    }
                                    self.nbDownloadedImages++
                                    if (self.nbDownloadedImages == self.wasteFlux.length) {
                                        setTimeout(() => {self.ref.detectChanges()}, 0)
                                        setTimeout(() => {
                                            self.initMapAndClusters()
                                            if (self.contractService.defaultFlux != "") {
                                                self.defaultFlux = self.wasteFlux.findIndex(elem => elem.nom == self.contractService.defaultFlux)
                                                self.contractService.defaultFlux = ""
                                            }
                                            self.selectFlux(self.defaultFlux)
                                        }, 100)
                                    }
                                    break
                                }
                            }
                        }
                    }
                    xhr.open('GET', href)
                    xhr.send()
                })
            }
        }
    }

    removeMarkerFromMap(flux: number) {
		this.markerZoom?.setMap(null)
		this.markers = this.markers.filter(elem => elem != this.markerZoom)
        if (this.markerZoom) {
            this.fluxClustering[flux].removeMarker(this.markerZoom)
        }
	}

    refreshMarker() {
		if (this.markerZoom != null) {
			let icon = this.markerZoom.getIcon() as google.maps.ReadonlyIcon
            this.markerZoom.setIcon({ 
                url: icon.url,
                scaledSize: new google.maps.Size(45,45),
                anchor: new google.maps.Point(22.5, 45)
            })
			this.markerZoom = null
		}
	}

    initMapAndClusters() {

        const elem = document.getElementById('map')
        if (elem) {
          this.map = new google.maps.Map(elem, this.options)
        }

        this.map?.addListener('click', () => {
        	this.infoWindow?.close()
        	this.refreshMarker()
        })

        this.map?.addListener('zoom_changed', () => {
        	this.infoWindow?.close()
        	this.refreshMarker()
        })

        for (let [key, element] of this.wasteFlux.entries()) {
            if (this.map) {
                this.fluxClustering.push(new MarkerClusterer(this.map, this.markers, {
                    gridSize: 100,
                    styles: [MarkerClusterer.withDefaultStyle({
                        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(this.wasteFlux[key].svg_cluster),
                        width: 55,
                        height: 55,
                        textColor: "white",
                        textSize: 10
                    }),
                        MarkerClusterer.withDefaultStyle({
                        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(this.wasteFlux[key].svg_cluster),
                        width: 55,
                        height: 55,
                        textColor: "white",
                        textSize: 11
                    }),
                        MarkerClusterer.withDefaultStyle({
                        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(this.wasteFlux[key].svg_cluster),
                        width: 55,
                        height: 55,
                        textColor: "white",
                        textSize: 12
                    })],
                    minimumClusterSize: 5
                }))
            }
        }
    }

    selectFlux(id_name: number) {
        this.isSelected[id_name] = !this.isSelected[id_name]
        let flux = this.wasteFlux[+id_name]
        if (this.isSelected[id_name]) {
            this.ids_selected.push(id_name)
            var bounds = new google.maps.LatLngBounds()
            this.allPavs = flux.pavs
            for (let item of flux.pavs) {
                let markerObject = {
                    position: {
                        lat: item.lat,
                        lng: item.lng,
                    },
                    map: this.map,
                    title: flux.nom,
                    options: {
                        icon: {
                            url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(flux.svg_marqueur),
                            scaledSize: {
                                width: 45,
                                height: 45
                            }
                        }
                    }
                }
                let marker = new google.maps.Marker(markerObject)
                marker.addListener("click", () => {
                    this.openInfo(marker, id_name, item)
                });
                this.markers.push(marker)
                this.fluxClustering[id_name].addMarker(marker)
                bounds.extend({lat: item.lat, lng: item.lng})
            }
            this.map?.fitBounds(bounds)
            let button = document.getElementById("button" + id_name)
            let image = document.getElementById("image" + id_name)
            let label = document.getElementById("label" + id_name)
            if (button && image && label) {
                button.style.backgroundColor = flux.couleur
                image.style.filter = ""
                label.style.color = "white"
            }
        } else {
            let button = document.getElementById("button" + id_name)
            let image = document.getElementById("image" + id_name)
            let label = document.getElementById("label" + id_name)
            if (button && image && label) {
                button.style.backgroundColor = "white"
                image.style.filter = "brightness(0%)"
                label.style.color = "black"
            }
            const markersToRemove = this.markers.filter(
                (item) => item.getTitle() == flux.nom
            )
            this.fluxClustering[id_name].removeMarkers(markersToRemove)
            this.markers = this.markers.filter(
                (item) => item.getTitle() != flux.nom
            )
        }
	}

  

    openInfo(marker : google.maps.Marker, flux: number, pav: Pav) {
        this.infoWindow?.close()
        this.infos_window_url_googlemaps = ""
        this.allPavs.forEach(element => {
            const markerLat = marker.getPosition()?.lat()
            const markerLng = marker.getPosition()?.lng()
            if(element.lat == markerLat && element.lng == markerLng) {
                pav = element
            }
        });


        if( this.markerZoom != null && this.markerZoom != marker){
            const icon = this.markerZoom.getIcon() as google.maps.ReadonlyIcon
            this.markerZoom.setIcon({ 
                url: icon.url,
                scaledSize: new google.maps.Size(45,45),
                anchor: new google.maps.Point(22.5, 45)
            })
        }

        const icon = marker.getIcon() as google.maps.ReadonlyIcon
        marker.setIcon({ 
            url: icon.url,
            scaledSize : new google.maps.Size(90,90),
            anchor: new google.maps.Point(45, 90)
        })
        this.markerZoom = marker
        this.markerZoomFlux = flux

        this.pav = pav
        this.infos_window_disponibility = this.checkStatus(pav)
        this.infos_window_pav_name = pav.nom
        var lat_pav = pav.lat
        var lng_pav = pav.lng
        this.infoswindow_adresse =
            pav.adresseVoie +
            " " +
            pav.cp +
            " " +
            pav.ville

        var self = this
        navigator.geolocation.getCurrentPosition(function (position) {
            var lat = position.coords.latitude
            var lng = position.coords.longitude
            self.infos_window_url_googlemaps =
                "https://www.google.com/maps/dir/" +
                lat +
                "," +
                lng +
                "/" +
                pav.lat +
                "," +
                pav.lng +
                "/data=!4m2!4m1!3e2"
        })
        if (pav.mail != "") {
            this.infos_window_contact = pav.mail;
        }
        this.infos_window_times = [];
        if(!this.infos_window_url_googlemaps){
            //set marker position in url google map
            this.infos_window_url_googlemaps = "https://www.google.com/maps/dir/" + lat_pav + "," + lng_pav + "/" + lat_pav + "," + lng_pav + "/data=!4m2!4m1!3e2"
        }

        if (pav.ouvert24, pav.ouvert24 == true) {
            this.infos_window_times.push("Ouvert 24h/24h")
        } else {
            if (pav.lundi && pav.lundi.length > 0) {
                this.infos_window_times.push(`Lundi : ${pav.lundi}`)
            }
            if (pav.mardi && pav.mardi.length > 0) {
                this.infos_window_times.push(`Mardi : ${pav.mardi}`)
            }
            if (pav.mercredi && pav.mercredi.length > 0) {
                this.infos_window_times.push(`Mercredi : ${pav.mercredi}`)
            }
            if (pav.jeudi && pav.jeudi.length > 0) {
                this.infos_window_times.push(`Jeudi : ${pav.jeudi}`)
            }
            if (pav.vendredi && pav.vendredi.length > 0) {
                this.infos_window_times.push(`Vendredi : ${pav.vendredi}`)
            }
            if (pav.samedi && pav.samedi.length > 0) {
                this.infos_window_times.push(`Samedi : ${pav.samedi}`)
            }
                if (pav.dimanche && pav.dimanche.length > 0) {
                this.infos_window_times.push(`Dimanche : ${pav.dimanche}`)
            }
        }

        this.infoWindow = new google.maps.InfoWindow()

        google.maps.event.addListener(this.infoWindow, 'domready', function() {
            // whatever you want to do once the DOM is ready
            let htmlElem = document.getElementById("pano")
            if (htmlElem) {
                new google.maps.StreetViewPanorama(
                    htmlElem,
                    {
                        position: { lat: lat_pav, lng: lng_pav },
                        pov: { heading: 165, pitch: 0 },
                        addressControlOptions: {
                            position: google.maps.ControlPosition.BOTTOM_CENTER,
                        },
                        linksControl: false,
                        panControl: false,
                        enableCloseButton: false,
                        zoomControl: false,
                        fullscreenControl: false,
                        addressControl: false,
                    }
                )
            }
        })

        this.infoWindow.addListener('closeclick', () => {
            this.refreshMarker()
        })

       


        self.computeInfoView()
        this.infoWindow.open(this.map, marker);
    }

    private checkStatus(outlet?: Pav) {
        let date = new Date()
        let weekday = date.getDay()
        let hour = date.getHours()
        let minute = date.getMinutes()
        switch (weekday) {
            case 0:
                if (outlet?.dimanche && outlet.dimanche != "") {
                    let openingHours = outlet.dimanche.split("/")
                    for (const hours of openingHours) {
                        if (this.compareHours(hour, minute, hours)) {
                            return true
                        }
                    }
                    return false
                }
                break
            case 1:
                if (outlet?.lundi && outlet.lundi != "") {
                    let openingHours = outlet.lundi.split("/")
                    for (const hours of openingHours) {
                        if (this.compareHours(hour, minute, hours)) {
                            return true
                        }
                    }
                    return false
                }
                break
            case 2:
                if (outlet?.mardi && outlet.mardi != "") {
                    let openingHours = outlet.mardi.split("/")
                    for (const hours of openingHours) {
                        if (this.compareHours(hour, minute, hours)) {
                            return true
                        }
                    }
                    return false
                }
                break
            case 3:
                if (outlet?.mercredi && outlet.mercredi != "") {
                    let openingHours = outlet.mercredi.split("/")
                    for (const hours of openingHours) {
                        if (this.compareHours(hour, minute, hours)) {
                            return true
                        }
                    }
                    return false
                }
                break
            case 4:
                if (outlet?.jeudi && outlet.jeudi != "") {
                    let openingHours = outlet.jeudi.split("/")
                    for (const hours of openingHours) {
                        if (this.compareHours(hour, minute, hours)) {
                            return true
                        }
                    }
                    return false
                }
                break
            case 5:
                if (outlet?.vendredi && outlet.vendredi != "") {
                    let openingHours = outlet.vendredi.split("/")
                    for (const hours of openingHours) {
                        if (this.compareHours(hour, minute, hours)) {
                            return true
                        }
                    }
                    return false
                }
                break
            case 6:
                if (outlet?.samedi && outlet.samedi != "") {
                    let openingHours = outlet.samedi.split("/")
                    for (const hours of openingHours) {
                        if (this.compareHours(hour, minute, hours)) {
                            return true
                        }
                    }
                    return false
                }
                break
            default:
                break
            }
        return false
    }

    private computeInfoView() {

        const contentString =
        '<style type="text/css">' +
        '.btn span.icon_site {' +
            `background: url(https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2FBouton-map-site.svg?alt=media) no-repeat; background-size: 100% 100%;` +
            'float: left;' +
            'width: 15px;' +
            'height: 20px;' +
            `filter:${this.cssFilter};` +
        '}' +
            '.btn span.icon_itineraire {' +
            `background: url(https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2FBouton-map-itineraire.svg?alt=media) no-repeat; background-size: 100% 100%;` +
            'float: left;' +
            'width: 15px;' +
            'height: 20px;' +
        '}' +
        '.btn span.icon_infos {' +
        `background: url(https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2FBouton-map-infos.svg?alt=media) no-repeat; background-size: 100% 100%;` +
        'float: left;' +
        'width: 15px;' +
        'height: 20px;' +
        `filter:${this.cssFilter};` +
        '}' +
        '.btn span.icon_email {' +
        `background: url(https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2FBouton-map-email.svg?alt=media) no-repeat; background-size: 100% 100%;` +
        'float: left;' +
        'width: 15px;' +
        'height: 20px;' +
        `filter:${this.cssFilter};` +
        '}' +
        '</style>' +
        '<div class="d-flex flex-column justify-content-around align-items-center">' +
            '<div class="d-flex flex-column justify-content-between">' +
                '<div class="d-flex flex-row justify-content-between align-content-center mb-3" style="width:250px;">' +
                    '<div id="pano" style="height: 90px; width: 90px;"></div>' +
                    '<div class="d-flax  flex-column justify-content-center align-items-center ml-2" style="width:160px;">' +
                        ((this.infos_window_pav_name!=undefined) ? `<div class="mt-2" style="font-weight: bold;">${this.infos_window_pav_name.replace('undefined',' ')}</div>` : '') +
                        ((this.infoswindow_adresse!=undefined) ? `<div class="mt-3" ">${this.infoswindow_adresse.replace('undefined',' ')}</div>` : '') +
                    '</div>' +
                '</div>' +
                '<div class="d-flex flex-row justify-content-start align-content-start">' +
                    ((this.infos_window_disponibility == true)&&(this.infos_window_times.length>0) ? `<div class="btn btn-secondary btn-sm mr-2" style="height: 30px; border-radius: 10px; background-color: #22780F; border-color: #22780F"><span></span>Ouvert<span></span></div>` : '') +
                    ((this.infos_window_disponibility == false)&&(this.infos_window_times.length>0) ? `<div class="btn btn-secondary btn-sm mr-2" style="height: 30px; border-radius: 10px; background-color: #BB0B0B; border-color: #BB0B0B"><span></span>Fermé<span></span></div>` : '') +
                    `<div class="btn btn-secondary btn-sm mr-2" style="height: 30px; border-radius: 10px; background-color: ${this.contractColor}; border-color: ${this.contractColor}"><span class="icon_itineraire"></span><a href="${this.infos_window_url_googlemaps}" target="_blank" style="text-decoration: none; color: white; padding-left: 5px;">Itinéraire</a><span></span></div>`+
                    ((this.pav.url!=undefined && this.pav.url!='') ? `<div class="btn infos btn-secondary btn-sm mr-2" style="height: 30px; border-radius: 10px; background-color: white; border-color: ${this.contractColor}"><span class="icon_infos"></span><a target="_blank" href="${this.pav.url}" style="text-decoration: none; padding-left: 5px; color: ${this.contractColor}">En savoir plus</a><span></span></div>` : '') +
                    ((this.pav.mail!=undefined && this.pav.mail!='') ? `<div class="btn infos btn-secondary btn-sm mr-2"  style="height: 30px; border-radius: 10px; background-color: white; border-color: ${this.contractColor}"><span class="icon_email" style="{filter:'${this.cssFilter}'"></span><a target="_blank" href="mailto:${this.pav.mail}" style="text-decoration: none; padding-left: 5px; color: ${this.contractColor}">Envoyer un email</a><span></span></div>` : '') +
                    ((this.pav.site!=undefined && this.pav.site!='') ? `<div class="btn infos btn-secondary btn-sm mr-2"  style="height: 30px; border-radius: 10px; background-color: white; border-color: ${this.contractColor}"><span class="icon_site" style="{filter:'${this.cssFilter}'"></span><a target="_blank" href="${this.pav.site}" style="text-decoration: none; padding-left: 5px; color: ${this.contractColor}">Site internet</a><span></span></div>` : '') +
                '</div>'+
                '<div id="see_more" class="d-flex flex-column mt-3">'+
                    ((this.pav.telephone!=undefined && this.pav.telephone!='') ? `<p class="mb-1 mt-2"><span style="font-weight: bold;">Téléphone :</span> ${this.pav.telephone}<p>` : '') +
                    ((this.pav.informations!=undefined && this.pav.informations != '') ? `<p style="font-weight: bold;margin-bottom: 0;">Informations<p>`  : '') +
                    ((this.pav.informations!=undefined && this.pav.informations != '') ? `<p style="margin-bottom: 0;">${this.pav.informations}<p>` : '') +
                    ((this.infos_window_times.length > 0) ? `<p style="font-weight: bold;" class="mb-1">Horaires<p>` : '') +
                    this.getHoraires() +
                '</div>'+
            '</div>'+
        '</div>'

        this.infoWindow?.setContent(contentString)
    }

    private getHoraires() {
        let horaires = ""
        for (let item of this.infos_window_times) {
            horaires += `<p>${item}</p>`
        }
        return horaires
    }

    private compareHours(currentHour: number, currentMinute: number, openingHours : string) {
        let splittedOpeningHours = openingHours.split(":")
        var openingHoursList:TimeTable[] = []

        for (let element = 0; element < splittedOpeningHours.length; element +=3) {
            if (element+1 < splittedOpeningHours.length) {
                let openTime = new Time(Number(splittedOpeningHours[element].substring(splittedOpeningHours[element].length-2, splittedOpeningHours[element].length)), Number(splittedOpeningHours[element+1].substring(0,2)))
                if (element+2 < splittedOpeningHours.length) {
                    let closeTime = new Time(Number(splittedOpeningHours[element+1].substring(splittedOpeningHours[element+1].length-2, splittedOpeningHours[element+1].length)), Number(splittedOpeningHours[element+2].substring(0,2)))
                    openingHoursList.push(new TimeTable(openTime, closeTime))
                }
            }
        }

        for (const elem of openingHoursList) {
            if (elem.open?.hour != undefined && elem.open?.minute != undefined && elem.close?.hour != undefined && elem.close?.minute != undefined) {
                if (((currentHour > elem.open.hour) || (currentHour == elem.open.hour) && (currentMinute > elem.open.minute)) && ((currentHour < elem.close.hour) || (currentHour == elem.close.hour) && (currentMinute < elem.close.minute))) {
                    return true
                }
            }
        }

        return false
    }

    showMarkerUserPosition(lat:any, lng:any){
        const userLatLng = new google.maps.LatLng(lat, lng)
        let distLatLong = []
        var bounds = new google.maps.LatLngBounds()
        bounds.extend(userLatLng)
        for(let marker of this.markers){
           let latLng = marker.getPosition()
           if (latLng) {
                let distance  = google.maps.geometry.spherical.computeDistanceBetween(latLng, userLatLng)
                distLatLong.push({distance: distance, latLong: latLng})
           }  
        }
        distLatLong.sort(function(a:any, b:any){return a.distance - b.distance})
        for(let elem of distLatLong.slice(0, 10)) {
            bounds.extend(elem.latLong)
        }
        this.map?.fitBounds(bounds)
    }

    gerUserPosition() {
        var self = this
        if (navigator.geolocation) {
            this.hidden_spinner = false
            setTimeout(() => {self.ref.detectChanges()}, 0)
            this.spinner.show()
            navigator.geolocation.getCurrentPosition((position) => {
                self.geoloc_lat = position.coords.latitude
                self.geoloc_lng = position.coords.longitude
                const geocoder = new google.maps.Geocoder()
                const latlng = new google.maps.LatLng(self.geoloc_lat, self.geoloc_lng)
                const request = {latLng: latlng} as google.maps.GeocoderRequest
                geocoder.geocode(request, (results, status) => {
                    this.spinner.hide()
                    this.hidden_spinner = true
                    setTimeout(() => {self.ref.detectChanges()}, 0)
                    if (status == google.maps.GeocoderStatus.OK) {
                        if (results && results[0] != null) {
                            const input = document.getElementById("input_adresse") as HTMLInputElement;
                            input.value = results[0].formatted_address
                            this.showMarkerUserPosition(self.geoloc_lat, self.geoloc_lng)
                        } else {
                            alert("Désolé, nous n'avons pas pu trouver votre adresse")
                        }
                    } else {
                        alert("Désolé, nous n'avons pas pu trouver votre adresse")
                    }
                })
                this.userMarker?.setMap(null)
                this.userMarker = new google.maps.Marker({
                    position: latlng
                })
                if (this.map) {
                    this.userMarker?.setMap(this.map)
                }
                }, (error) => {
                    console.log("Error code: " + error.code + "<br /> Error message: " + error.message)
                    this.spinner.hide()
                    this.hidden_spinner = true
                    setTimeout(() => {self.ref.detectChanges()}, 0)
                }
            );
        } else {
            alert("Désolé, nous n'avons pas pu trouver votre adresse")
        }
    }

	getAddress(place: google.maps.GeocoderResult) {
		this.address = place.formatted_address
		this.geoloc_lat = place.geometry.location.lat()
        this.geoloc_lng = place.geometry.location.lng()
        this.userMarker?.setMap(null)
        this.userMarker = new google.maps.Marker({
            position: new google.maps.LatLng(this.geoloc_lat, this.geoloc_lng)
        })
        if (this.map) {
            this.userMarker?.setMap(this.map)
        }
        this.showMarkerUserPosition(this.geoloc_lat, this.geoloc_lng)
	}

	onFocusSearch() {
		if (this.contractColor) {
            const contratColor06 = this.hexToRgbA(this.contractColor, "0.6")
            const contratColor0075 = this.hexToRgbA(this.contractColor, "0.075")
            const input = document.getElementById("input_search_adresse")
            if (input) {
                input.style.borderColor = this.contractColor
                input.style.boxShadow = "inset 0 1px 1px " + contratColor0075 + ", 0 0 8px " + contratColor06
            }
        }
	}

	onBlurSearch() {
		const input = document.getElementById("input_search_waste")
        if (input) {
		    input.style.borderColor = "gray"
		    input.style.boxShadow = "none"
        }
	}

	hexToRgbA(hex: string, opacity: string) {
		if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			let c:any = hex.substring(1).split("")
			if (c.length == 3) {
				c = [c[0], c[0], c[1], c[1], c[2], c[2]]
			}
			c = "0x" + c.join("")
			return (
				"rgba(" +
				[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
				"," +
				opacity +
				")"
			)
		}
		throw new Error("Bad Hex")
	}
}

class Place {
    formatted_address: any
    geometry: any
}
