<ng-container *ngIf="contrat?.contrat">
<div class="row d-flex justify-content-center marginDashboard">
    <div class="card card-login mt-4 mb-4 align-items-center" >
        <img src="assets/img/logo-montri.png" class="montri_logo mt-3 mb-4">
        <p class="p_welcome">👋 Bienvenue,</p>
        <p class="p_connect mb-4">Veuillez vous inscrire</p>
        <div class="left-inner-addon input-container mb-4">
            <img src="../../../assets/img/mail-icon.svg" alt="">
            <input type="text" (keyup)="processMail($event)"
                    (change)="processMail($event)"
                   class="form-control input_login" 
                   placeholder="Votre email" />
        </div>
        <div class="left-inner-addon input-container mb-4">
            <img src="../../../assets/img/password-icon.svg" alt="">
            <input type="password" (keyup)="processPwd($event)"
                    (change)="processPwd($event)"
                   class="form-control input_login" 
                   placeholder="Votre mot de passe" />
        </div>
        <div class="row w-100 align-items-center ml-2">
            <div class="col d-flex align-items-center" style="height: 2rem;">
                <img class="check mr-1"  [src]="caractereError ? pathNok : pathOk" alt="">
                <p class="p_check">8 caractères</p>
            </div>
            <div class="col d-flex align-items-center" style="height: 2rem;">
                <img class="check mr-1"  [src]="specialError ? pathNok : pathOk" alt="">
                <p class="p_check">1 caractère spécial</p>
            </div>
        </div>
        <div class="row w-100 align-items-center mb-2 ml-2">
            <div class="col d-flex align-items-center" style="height: 2rem;">
                <img class="check mr-1"  [src]="majError ? pathNok : pathOk" alt="">
                <p class="p_check">1 majuscule</p>
            </div>
            <div class="col d-flex align-items-center" style="height: 2rem;">
                <img class="check mr-1" [src]="numberError ? pathNok : pathOk" alt="">
                <p class="p_check">1 chiffre</p>
            </div>
        </div>
        <div class="row mt-3 mb-2">
                <label class="form-switch">
                    <div class="row">
                        <input type="checkbox" (change)="changeCguCheck($event)"  >
                        <i></i>
                        <p class="p_check">J’accepte les&ensp;<a href="https://www.uzer.eu/code-de-bonne-conduite-montri">Conditions Générales d’Utilisation</a></p> 
                    </div>
                </label>
        </div>
        <div class="row mb-4 mr-4">
                <label class="form-switch" >
                    <div class="row">
                        <input type="checkbox" (change)="changePrivacyCheck($event)"  >
                        <i></i>
                        <p class="p_check">J’accepte la&ensp;<a href="https://www.uzer.eu/charte-de-confidentialite-montri">Politique de Confidentialité</a></p> 
                    </div>
                </label>
        </div>
        <button class="button_connect mb-3" (click)="onSubmitSignUp()" [ngClass]="canCreate() ? 'disableButton' : ''" [disabled]="canCreate()">Créer mon compte</button>
        <div class="row">
            <p class="p_ask mr-5">J'ai déjà un compte</p>
            <p class="p_newaccount"  (click)="goToLogin()">Me connecter</p>
        </div>
    </div>
 </div>
</ng-container>