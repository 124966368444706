
<ngx-spinner bdColor = "rgba(255, 255, 255, 1.0)" size = "medium" color = "#A6A6A6" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: #A6A6A6" >Chargement en cours...</p></ngx-spinner>
<!-- Header -->
<header [hidden]="headerFooterHidden" id="header-v2" class="navbar navbar-expand-md navbar-light fixed-top">
    <div class="row">
        <div class="col-1"></div>
        <div class="col-10 ml-3">
            <div class="d-flex flex-column flex-md-row">
                <div class="d-flex flex-row mb-3">
                    <a class="navbar-brand" href="#">
                        <img [src]="logoUrl" style="height:42px;width:auto" alt="Montri">
                    </a>
                    <button *ngIf="hasContract" class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div class="collapse navbar-collapse my-3" id="navbarToggler">
                    <ul class="row navbar-nav d-flex flex-column flex-md-row justify-content-end fontTab">
                        <li class="nav-item d-flex flex-row justify-content-center my-2 mr-2"
                            *ngIf="hasContract">
                            <a class="nav-link avenir_heavy nav_bar_link" id="services" [routerLink]="['/services']"
                            [ngClass]="(hover_lik1 == true) || (current_window == 'services') ? 'activeTab' : ''"
                                (mouseover)="hover_lik1 = true" (click)="current_window = 'services'"
                                (mouseleave)="hover_lik1 = false" [ngStyle]="{
                                        'color': (hover_lik1 == true) || (current_window == 'services')  ? contractColor :  'black'
                                    }">Les services</a>
                        </li>
                        <li class="nav-item active d-flex flex-row justify-content-center my-2 mr-2"
                            *ngIf="hasContract">
                            <a class="nav-link avenir_heavy nav_bar_link" [routerLink]="['/home']"
                            [ngClass]="(hover_lik2 == true) || (current_window == 'home') ? 'activeTab' : ''"
                                (click)="current_window = 'home'" (mouseover)="hover_lik2 = true"
                                (mouseleave)="hover_lik2 = false" [ngStyle]="{
                                        'color': (hover_lik2 == true) || (current_window == 'home') ? contractColor : 'black'
                                    }">Application</a>
                        </li>

                        <li class="nav-item active d-flex flex-row justify-content-center my-2 mr-2"
                        *ngIf="hasContract && !userData.isAnonymous">
                            <a class="nav-link avenir_heavy nav_bar_link" [routerLink]="['/dashboard']"
                            [ngClass]="(hover_lik3 == true) || (current_window == 'dashboard') ? 'activeTab' : ''"
                                (click)="current_window = 'dashboard'" (mouseover)="hover_lik3 = true"
                                (mouseleave)="hover_lik3 = false" [ngStyle]="{
                                        'color': (hover_lik3 == true) || (current_window == 'dashboard') ? contractColor : 'black'
                                    }">Tableau de bord</a>
                         </li>

                        <li class="nav-item active d-flex flex-row justify-content-center my-2"
                        *ngIf="hasContract">
                            <a *ngIf="userData.isAnonymous" class="nav-link avenir_heavy nav_bar_link" [routerLink]="['/signin']" style="border: 1px solid;border-radius: 50px;;cursor: pointer"
                            [ngClass]="(hover_lik4 == true) || (current_window == 'signin') ? 'activeTab' : ''"
                                (click)="current_window = 'signin'" 
                                (mouseover)="hover_lik4 = true"
                                (mouseleave)="hover_lik4 = false" [ngStyle]="{
                                        'color': (hover_lik4 == true) || (current_window == 'signin') ? contractColor : 'black'
                                    }">Connexion</a>
                            <a *ngIf="!userData.isAnonymous" class="nav-link avenir_heavy nav_bar_link" style="border: 1px solid;border-radius: 50px;cursor: pointer"
                            [ngClass]="(hover_lik4 == true) || (current_window == 'signin') ? 'activeTab' : ''"
                                (click)="logout()" 
                                (mouseover)="hover_lik4 = true"
                                (mouseleave)="hover_lik4 = false" [ngStyle]="{
                                        'color': (hover_lik4 == true) || (current_window == 'signin') ? contractColor : 'black'
                                    }">Déconnexion</a>                     
                        </li>         
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>
<router-outlet></router-outlet>
<!-- Footer -->
<footer [hidden]="headerFooterHidden" class="footer bg-dark text-white footer_pos pt-4">
    <div class="container">
        <div class="row">
            <div
                class="col-lg-2 d-flex flex-lg-column m-0 p-0 flex-row justify-content-lg-start justify-content-center  align-items-center align-items-lg-start">
                <img src="assets/img/Logo-Montri-footer.svg" style="height: 60px; width: 60px;">
            </div>
            <div class="col-lg-8 m-0 p-0 ">
                <div
                    class="row  ml-0 mr-0 mt-lg-0 mt-3 p-0 mb-5 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                    <a href="https://apps.apple.com/fr/app/montri-trier-solidaire/id1458927235" target="_blank">
                        <div class="appstore mr-lg-2 mt-lg-0" style="height: 50px;width: 171px;"></div>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=eu.uzer.montri&hl=fr" target="_blank">
                        <div class="playstore ml-lg-2 mt-lg-0 mt-2" style="height: 50px;width: 171px;"></div>
                    </a>
                </div>

                <ul
                    class="row ml-0  mr-0 p-0 d-flex mt-2 flex-column flex-lg-row justify-content-center align-items-center">
                    <li class="avenir_heavy mt-lg-0 mt-3 ml-lg-5 ml-0 mr-lg-5 " style="list-style: none;"
                        ><a [routerLink]="['/home']" (click)="current_window = 'home'" style="color: white;">L'Application</a>
                    </li>
                    <li *ngIf="hasContract" class=" avenir_heavy mt-lg-0 mt-3 ml-lg-5 ml-0 mr-lg-5 mr-0" style="list-style: none;">
                        <a [routerLink]="['/services']" (click)="current_window = 'services'" style="color: white;">Les services</a>
                    </li>
                    <li class="avenir_heavy mt-lg-0 mt-3 ml-lg-5 ml-0 mr-lg-5 mr-0"
                        style="list-style: none; cursor: pointer;" (click)="showContactModal()">Contact</li>
                </ul>
                <ul
                    class="row  ml-0 mr-0 p-0 mt-5 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                    <li class="avenir_light mt-lg-0 mt-3 ml-lg-5 ml-0 mr-lg-5 mr-0" style="list-style: none;"><a
                            href="https://www.uzer.eu/charte-de-confidentialite-montri" target="_blank"
                            style="color: white;">Politique de confidentialité</a></li>
                    <li class="avenir_light mt-lg-0 mt-3 ml-lg-5 ml-0 mr-lg-5 mr-0" style="list-style: none;"><a
                            href="https://www.uzer.eu/cgu-montri" target="_blank" style="color: white;">Conditions
                            d'utilisation</a></li>
                </ul>
            </div>
            <div class="col-lg-2 m-0 p-0 d-flex flex-column justify-content-start align-items-start mx-5 mx-lg-0">
                <div *ngIf="hasContract" class="change_commune mb-3" (click)="changeCommune()" style="width: 100%;cursor: pointer;">
                    <a class="d-flex flex-column justify-content-center align-items-center"><span>changer votre
                            commune</span></a>
                </div>
                <div class="d-flex flex-row justify-content-center flex-wrap" style="width: 100%;">
                    <a href="{{lienFacebook}}"
                        *ngIf="lienFacebook" target="_blank" class=" mt-2"> <img
                            src="../assets/img/rs/logofb.png" style="width: 45px; height: 45px;"></a>
                    <a href="{{lienTwitter}}"
                        *ngIf="lienTwitter" target="_blank" class="ml-4 mt-2"><img
                            src="../assets/img/rs/logotwt.png" style="width: 45px; height: 45px;"></a>
                    <a href="{{lienYoutube}}"
                        *ngIf="lienYoutube" target="_blank" class="ml-4 mt-2"><img
                            src="../assets/img/rs/logoyt.png" style="width: 45px; height: 45px;"></a>
                    <a href="{{lienInstagram}}"
                        *ngIf="lienInstagram" target="_blank" class="ml-4 mt-2"><img
                            src="../assets/img/rs/logoinsta.png" style="width: 45px; height: 45px;"></a>
                </div>
            </div>
        </div>
        <div class="row m-0 p-0  mt-5">
            <div class="col-2"></div>
            <div class="col-8  d-flex flex-column flex-lg-row justify-content-center align-items-center">
                <p class="avenir_heavy">Powered by <span><a href="https://www.uzer.eu" target="_blank"
                            style="color: white;">UZER</a></span> S.A.S</p>
            </div>
            <div class="col-2"></div>
        </div>
    </div>
</footer>