<div class="ml-4 ml-sm-5 mt-3 mb-5">
    <div [hidden]="hideCitiesInput" class="row ml-0 ml-sm-2 mr-3 mr-sm-0">
        <div class="col-12 col-sm-4 p-0 pl-sm-2" style="min-width: 15rem;">
            <div class="form-group inner-addon left-addon mb-3 mb-sm-0">
                <i class="fa fa-home"></i> 
                <input id="inputcity" class="form-control bs-autocomplete-input mb-3"  
                    [(ngModel)]="filterCity" (ngModelChange)="filterEntriesCity($event)" data-toggle="dropdown"
                    (focus)="inputFieldFocusedCity()" autocomplete="off"
                    style="border-radius: 10px; border: 0px; background-color: #f4f3f3;color: black;" placeholder="Renseignez votre ville"
                    onfocus="this.placeholder = ''"
                    onblur=" this.placeholder = 'Renseignez votre ville'"
                    />
                    <div id="menucity" style="max-height:250px; overflow:auto" class="dropdown-menu  bs-autocomplete-menu" role="menu" aria-labelledby="dropdownMenuLink">
                    <a  href="javascript:void(0)"style="cursor: default;" class="dropdown-item pl-2 bg-gray avenir_light"  
                    *ngFor="let entry of filteredEntitiesCity" (click)="selectEntryCity(entry)">{{entry}}</a>
                    </div>
            </div>
        </div>
        <div class="col-12 col-sm-4 p-0 pl-sm-2" [ngStyle]="{'display' : hide_street, 'min-width': '15rem'}">
            <div class="form-group inner-addon left-addon mb-3 mb-sm-0">
                <i class="fa fa-home"></i> 
                <input id="inputstreet" class="form-control bs-autocomplete-input mb-3" autocomplete="off" 
                    [(ngModel)]="filterStreet" (ngModelChange)="filterEntriesStreet($event)" data-toggle="dropdown"
                    (focus)="inputFieldFocusedStreet()" autocomplete="off"
                    style="border-radius: 10px;border: 0px; background-color: #f4f3f3;color: black;"  placeholder="Renseignez votre rue"
                    onfocus="this.placeholder = ''"
                    onblur=" this.placeholder = 'Renseignez votre rue'"
                    />
                    <div id="menustreet" style="max-height:250px; overflow:auto" class="dropdown-menu bs-autocomplete-menu" aria-labelledby="dropdownMenuLink">
                        <a  href="javascript:void(0)" class="dropdown-item pl-2 avenir_light"  style="cursor: default; font-size: 13px" 
                        *ngFor="let entry of filteredEntitiesStreet" (click)="selectEntryStreet(entry)">{{entry}}</a>
                    </div>
            </div>
        </div>
        <div class="col-12 col-sm-4 p-0 pl-sm-2" [ngStyle]="{'display' : hide_num, 'min-width': '15rem'}">
            <div class="form-group inner-addon left-addon mb-3 mb-sm-0">
                <div class="col-lg-12 p-0">
                    <div class="bs-autocomplete-containe form-group">   
                        <i class="fa fa-home"></i> 
                        <input id="inputnum" class="form-control bs-autocomplete-input mb-3" autocomplete="off" 
                            [ngModel]="filterNum"  (ngModelChange)="filterEntriesNum($event)" data-toggle="dropdown"
                            (focus)="inputFieldFocusedNum()"
                            style="border-radius: 10px; border: 0px; background-color: #f4f3f3;color: black;" placeholder="Renseignez votre numéro"/>
                        <div id="menunum" class="dropdown-menu bs-autocomplete-menu" aria-labelledby="dropdownMenuLink">
                            <a class="dropdown-item pl-2 avenir_light"  style="cursor: default;" *ngFor="let entry of numeros" (click)="selectEntryNum(entry)">{{entry}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-4 p-0 pl-sm-2" style="min-width: 15rem;" *ngIf="calendarUrl">
            <button class="btn btn-montri-contract py-1" style="border-radius: 0.7rem; height: 2.3rem;"
                [ngStyle]="{'background-color': contractColor}" (click)="openUrl(calendarUrl)"><i class="fa fa-calendar pr-1"
                style="color: white;" aria-hidden="true"></i>
                <span style="font-size: 80%;color: white;font-family: avenir_heavy;">Télécharger calendrier</span>
            </button>
        </div>
    </div>

    <div class="row ml-0 ml-sm-3 mt-4 mb-3 mr-3 mr-sm-0" *ngFor="let item of collecteResult; let i = index">
        <div class="col-12">
            <div class="row" style="min-height: 10rem;">
                <div class="col" style="min-width: 5rem;max-width: 5rem;border-radius: 20px 0px 0px 20px;" [ngStyle]="{'background-color': hexToRgbA(HexCode(item.backgroudColor), '1.0')}">
                    <div class="row h-75">
                        <img src="{{item.icon}}" class="mx-auto my-auto" style="width: 3rem;height: 3rem;"/>
                    </div>
                    <div class="row h-25" [ngStyle]="{'background-color': hexToRgbA('#000000', '0.1')}"
                        style="border-radius: 0px 0px 0px 20px;">
                        <img src="{{getIconBottom(item.agenda)}}" class="mx-auto" alt="" style="width: 1.5rem;" />
                    </div>
                </div>
                <div class="col-9" [ngStyle]="{'background-color': hexToRgbA(HexCode(item.backgroudColor), '0.2'), 'border-radius': '0px 20px 20px 0px'}">
                    <h6 class="item_top pt-3" style="font-size: larger;">{{item.fluxCollecte}}</h6>
                    <p class="item_bottom mb-0" [innerHTML]="item.agenda"></p>
                    <p class="item_bottom mt-1 mb-0" style="font-size: 85%;color: grey;" *ngIf="item.warning"><img style="height:1.5rem;" class="pb-1 pr-1" src="https://firebasestorage.googleapis.com/v0/b/montri-dev.appspot.com/o/widget%2Fassets%2Fimages%2Fcommentaire.png?alt=media"/>{{item.warning}}</p>
                    <p class="item_bottom mt-3 mb-0" *ngIf="item.texte" ><img style="height:1.5rem;" class="pb-1 pr-1" src="https://firebasestorage.googleapis.com/v0/b/montri-dev.appspot.com/o/widget%2Fassets%2Fimages%2Faction.png?alt=media"/>{{ item.texte }}</p>
                    <div class="row mt-2">
                        <div class="col ml-3 pb-2" *ngIf="(item.fluxCarte?.length ?? 0) > 0">
                            <div class="row">
                                <button class="btn btn-montri-contract py-1" style="border-radius: 0.7rem;"
                                    [ngStyle]="{'background-color': hexToRgbA(HexCode(item.backgroudColor), '1.0')}"
                                    (click)="goToFluxInMap(item.fluxCarte)"><i class="fa fa-map-marker pr-1"
                                        style="color: white;" aria-hidden="true"></i>
                                        <span style="font-size: 80%;color: white;font-family: avenir_heavy;">
                                            {{(screenWidth >= 992) ? item.libelleBouton : getShortLibelle(item.libelleBouton)}}</span>
                                </button>
                            </div>
                        </div>
                        <div class="col ml-3 pb-2" *ngIf="(item.url?.length ?? 0) > 0">
                            <div class="row">
                                <a class="btn mb-10 btn-montri-consignes" style="border-radius: 0.7rem;"
                                    [ngStyle]="{'background-color': hexToRgbA(HexCode(item.backgroudColor), '1.0')}"
                                    target="_blank" href="{{item.url}}" role="button">
                                    <i class="fa fa-map-marker pr-1" style="color: white;" aria-hidden="true"></i>
                                    <span style="font-size: 90%;color: white">Trouver un point de collecte</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>