import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Contrat } from 'src/app/models/contrat';
import { ContratService } from 'src/app/services/contract.service';
import { Auth, onAuthStateChanged, signInAnonymously } from '@angular/fire/auth'
import { NgxSpinnerService } from "ngx-spinner"

@Component({
  selector: 'app-to-signup',
  templateUrl: './to-signup.component.html',
  styleUrls: ['./to-signup.component.scss'],

})
export class ToSignupComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private spinner: NgxSpinnerService,
        private contractService: ContratService,
        private auth: Auth,
    ) { }

    id: string = ""
    contractId : string = ""
    contrat?:  Contrat
    textWeb: string = ""
    hidden_spinner : boolean = false

    ngOnInit(): void {
        window.scrollTo(0, 0)
        this.spinner.show()

        onAuthStateChanged(this.auth, (user) => {
            if (user) {
                console.log('Compte user crée')
                this.route.params.subscribe( params => {
                    if(params['id'] && params['contrat']){
                        this.id = params['id']
                        this.contractId = params['contrat']
                        this.contractService.contract.asObservable().subscribe((contrat) => {
                            if (!contrat.contrat) {
                                console.log("Contrat vide")
                                this.contractService.loadContract(params['contrat'])
                            } else {
                                this.textWeb = this.contrat?.parrainage?.texteWeb ?? "" // Texte à enlever
                                this.spinner.hide()
                                this.hidden_spinner = true
                            }
                        })
                    }
                })
            } else {
                console.log('Un compte anonyme va être crée')
                signInAnonymously(this.auth)
            }
        })
    }

    register(){
        //send id of parainer to signup form of new user
        this.router.navigateByUrl(`/signup/${this.contractId}/${this.id}`)
    }

}
