import { CollecteWaste } from "./collecte-waste"
import {Deserializable} from "./deserializable.model";
import { Timestamp } from '@angular/fire/firestore'

export class Contrat implements Deserializable {
	contrat?: ContratClass
	design?: Design
	services?: Service[]
	guideDutri: GuideDutri[] = []
	id?: string
	postalCode?: string
	contact? : Contact
	Web? : Web
	actualites?: Actualites[]
	reseauxSociaux? :ReseauxSociaux
	interface? : Interface
	calendrier?: CollecteWaste
	reporting_show? : boolean
	villesListe? : string[]
	parrainage? : ContratParrainage

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getService (name: string) {
        return this.services?.find(elem => 
            elem.nom == name
        )
    }
}

export class Interface{
	designMobile?: any
	designWeb?: DesignWeb
}

export class DesignWeb{
	Home_tabs?: Home_tabs[]
	couleurPrincipale?: string
	logoSite?: string
	imageCover?: string
	logoUrl?: string
	soustitreCover?: string
	titreCover?: string
}

export class Home_tabs{
	image?: string
	text?: string
	title?: string
}

export class ContratParrainage {
    active? : boolean = true
	descriptionApp? : string
	titrePopup? : string
	textePopup? : string
	texteWeb? : string
	texteWebsuccess? : string
	pointsParrain? : string
	pointsInvite? : string
	textePartage? : string
}

export class Contact{
	cp?: string
	mailContact?:string
}

export class City {
	codePostal?: string
	nom?: string
	extension?: string
	active?: boolean
}

export class ReseauxSociaux{
	lienFacebook?: string
	lienTwitter?: string
	lienYoutube?: string
	lienInstagram?:string
	imageFacebook?: string
	imageYoutube?: string
	imageInstagram?: string
	imageTwitter?: string
}

export class Web {
	LogoUrl?:string
	Home_tabs?: HomeTab[]
	titreCover?: string
	soustitreCover?:  string
	logoSite?: string
	imageCover?: string
}

export class HomeTab {
    image?: string
    title?: string
	text?:  string
	url_image? : string
}

export class Alert {
	type?: string;
	message?: string;
}

export class GuideDutri {
	boutonCalendrier?: boolean;
	boutonEnlevement?: boolean;
	boutonPav?: boolean;
	description1?: string;
	description2?: string;
	description3?: string;
	famille?: string;
	flux?: string[];
	image?: string;
	last_one?: boolean;
	nom?: string;
	type?: string;
	hover1? : boolean;
	hover2? : boolean;
	hover3? : boolean;
	infos : Action[] = []
	url_image? : string;
	motsCle?: string ;
    top?: string
}

export class Action{
	action? : string;
	description?: string;
	flux: string[] = [];
	libelleBouton?: string;
	lien?: string;
	transition?: string;
	type?: string;
	colored?: number = 0;
	contact?: string
	image?: string

}

export class Design {
	couleurPrincipale?: string
	flux?: fluxInfos[]
	couleurSecondaire?: string
}

export class Actualites {
    actualites?: Actualite[]
}

export class Actualite {
    image?: string;
    titre?: string;
    date?: any;
    dateDebut: any
    dateFin: any
    epingler?: boolean;
    description?: string;
    url?: string;
}

export  class fluxInfos {
	active?: boolean
	colorErrorMd?: string
	couleur?: string
	error?: boolean
	image?: string
	nom?: string
}

export class villeServie {
    zipCode?: string
    active?:  boolean
}

export class Service {
	active?: boolean
	activeWeb?: boolean
	description1?: string
	description2?: string
	image?: string
	descriptionWeb? : string
	nom: string = ""
	ordreSlider?: number
	slider?: boolean
	web?: boolean
	sousServices?: Subservice[]
	titre?: string
	webColor?: String
	code?: number
	villes? : villeServie[]
    historique?: boolean
    confirmation?: string
}

export class Subservice {
	active? : boolean
	email? : string
	nom? : string
	titre? : string
    description? : string
    url? : string
    titreMotif : string = ""
    motifs?: [string]
	error?: boolean
	image?: string
    imageWeb?: string
	colorBo?: string
	iconBo?: string
	formulaires?: Formulaire
	coordonnees?: Coordonnees
	imageUrl?: string
	code?: string
    messages?: boolean
}

export class Formulaire {
	titre: string = ""
	champs!: Champs[]
}

export class Champs {
	nom: string = ""
    obligatoire?: boolean
    format?: string
    preview?: string
	motifs?: string
	value?: string
	photoId?: string
	coordinate?: Coordonne
	error?: boolean
	titreMotif?: string
	type?: string;
	message?: string;
}

export class Coordonne{
	latitude?: number
	longitude?: number
}

export class Coordonnees {
	titre?: string
    sousTitre?: string
    champs?: Champs[]
}

export class ContratClass {
	nomContrat?: string
	collectePolygon?: boolean
	villesListe?:[]
	siteContrat?: string
	reglesTri?: string
	idContrat?: string
	cguContrat?: string
	webApp?: boolean
	coordonne? : Coordonne
}

export class Vote {
	codePostale? : string
	ville? : string
	constructor(codePostal? : string, ville? : string) {
        this.codePostale = codePostal
        this.ville = ville
    }
}

export class Messages {
	message?: string = ""
	expediteur?: number = 0
	date?: Timestamp 
    constructor(message? : string, expediteur? : number, date?: Timestamp) {
        this.message = message
        this.expediteur = expediteur
        this.date = date
    }
}

export class Report {
    email?: string
    messages?: Messages[]
    nom ?: string
    numero ?:  string
    rue?: string
    telephone ?: string
    type ?: string
    ville ?: string
    idContrat ?: string
    date?: Timestamp
    dateEnCours?: Timestamp
    status ?: string
    gid ?: string
	uid?:string
    id ?: string
    image? : string
    coordonne? : Coordonne
    coordonnees? : any
    formulaires? : any
    adresse ? : string
    prenom ? : string
    motif ? : string
    commentaire ? : string
    soldeur ? : string
    dateFin ? : any
    destinataire?: string
    historique?: any[]
    majBO: boolean = false
    maj: boolean = false
}

