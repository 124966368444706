import { Component } from '@angular/core'

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr'

@Component({
    selector: '[bootstrap-toast-component]',
    templateUrl: './toast.component.html',
    preserveWhitespaces: false
})

export class CustomToast extends Toast {

    image: string = ""

    constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
        super(toastrService, toastPackage)
        this.image = `./../../../assets/img/alerts/${this.options.payload}.svg`
    }

    onClick() {
        console.log("TEST")
    }
}
