export class WasteFlux {
    flux?: Flux[];
}

export class CarteFlux {
    flux?: Flux[];
}

export class Flux {
	couleur : string = ""
    image? : string
    nom: string = ""
    defaut? :  boolean
    pavs: Pav[] = []
    description? : string
    media ?: string
	active ?: boolean
	url_marker? :  string
	url_menu ? : string
    ordre : number = 0
    displayName? : string
	svg_marqueur : string = ""
    svg_cluster : string = ""
}

export class Pav {
	aPublier ?: boolean
    nom? : string
    lat : number = 0
    lng : number = 0
    adresseVoie? : string
    adresseComplement? : string
    cp? : string
    ville? : string
    mail? : string
    telephone? :  string
    site? : string
    url? : string
    informations? : string
    ouvert24? : boolean
    lundi? : string
    mardi? : string
    mercredi? : string
    jeudi? : string
    vendredi? : string
    samedi? : string
	dimanche? : string
	distance?:  number
}

export class Time {
	hour?: number
	minute?: number
	constructor(hour?: number, minute?: number) {
		if (hour != undefined) { this.hour = hour }
		if (minute != undefined) { this.minute = minute }
	}
}

export class TimeTable {
	open?: Time
	close?: Time
	constructor(open?: Time, close?: Time) {
		if (open) { this.open = open }
		if (close) { this.close = close }
	}
}

export class TIME {
    day?: string;
    time?: string;
}

export enum APublier {
    OUI = "oui",
}

export enum Mail {
    Empty = "",
    RenseignementsDechetsEncaOrg = "renseignements-dechets@enca.org",
    RenseignementsDechetsNcaOrg = "renseignements-dechets@nca.org",
}

export enum Pays {
    Fr = "FR",
}



export enum Tel {
    Empty = "",
    The0805055055 = "0 805 055 055",
}

export enum Ville {
    Nice = "Nice",
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
    public static toWasteFlux(json: string): WasteFlux {
        return cast(JSON.parse(json), r("WasteFlux"));
    }

    public static wasteFluxToJson(value: WasteFlux): string {
        return JSON.stringify(uncast(value, r("WasteFlux")), null, 2);
    }
}

function invalidValue(typ: any, val: any): never {
    throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`);
}

function jsonToJSProps(typ: any): any {
    if (typ.jsonToJS === undefined) {
        var map: any = {};
        typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
        typ.jsonToJS = map;
    }
    return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
    if (typ.jsToJSON === undefined) {
        var map: any = {};
        typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
        typ.jsToJSON = map;
    }
    return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any): any {
    function transformPrimitive(typ: string, val: any): any {
        if (typeof typ === typeof val) return val;
        return invalidValue(typ, val);
    }

    function transformUnion(typs: any[], val: any): any {
        // val must validate against one typ in typs
        var l = typs.length;
        for (var i = 0; i < l; i++) {
            var typ = typs[i];
            try {
                return transform(val, typ, getProps);
            } catch (_) {}
        }
        return invalidValue(typs, val);
    }

    function transformEnum(cases: string[], val: any): any {
        if (cases.indexOf(val) !== -1) return val;
        return invalidValue(cases, val);
    }

    function transformArray(typ: any, val: any): any {
        // val must be an array with no invalid elements
        if (!Array.isArray(val)) return invalidValue("array", val);
        return val.map(el => transform(el, typ, getProps));
    }

    function transformDate(typ: any, val: any): any {
        if (val === null) {
            return null;
        }
        const d = new Date(val);
        if (isNaN(d.valueOf())) {
            return invalidValue("Date", val);
        }
        return d;
    }

    function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
        if (val === null || typeof val !== "object" || Array.isArray(val)) {
            return invalidValue("object", val);
        }
        var result: any = {};
        Object.getOwnPropertyNames(props).forEach(key => {
            const prop = props[key];
            const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
            result[prop.key] = transform(v, prop.typ, getProps);
        });
        Object.getOwnPropertyNames(val).forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps);
            }
        });
        return result;
    }

    if (typ === "any") return val;
    if (typ === null) {
        if (val === null) return val;
        return invalidValue(typ, val);
    }
    if (typ === false) return invalidValue(typ, val);
    while (typeof typ === "object" && typ.ref !== undefined) {
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ)) return transformEnum(typ, val);
    if (typeof typ === "object") {
        return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty("arrayItems")    ? transformArray(typ.arrayItems, val)
            : typ.hasOwnProperty("props")         ? transformObject(getProps(typ), typ.additional, val)
            : invalidValue(typ, val);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== "number") return transformDate(typ, val);
    return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
    return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
    return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
    return { arrayItems: typ };
}

function u(...typs: any[]) {
    return { unionMembers: typs };
}

function o(props: any[], additional: any) {
    return { props, additional };
}

function m(additional: any) {
    return { props: [], additional };
}

function r(name: string) {
    return { ref: name };
}

const typeMap: any = {
    "WasteFlux": o([
        { json: "flux", js: "flux", typ: a(r("Flux")) },
    ], false),
    "Flux": o([
        { json: "color", js: "color", typ: "" },
        { json: "image", js: "image", typ: "" },
        { json: "nom", js: "nom", typ: "" },
        { json: "pavs", js: "pavs", typ: a(r("Pav")) },
    ], false),
    "Pav": o([
        { json: "ADRESSE_NUM", js: "ADRESSE_NUM", typ: "" },
        { json: "ADRESSE_VOIE", js: "ADRESSE_VOIE", typ: "" },
        { json: "A_PUBLIER", js: "A_PUBLIER", typ: r("APublier") },
        { json: "CP", js: "CP", typ: "" },
        { json: "DIMANCHE_STANDARD", js: "DIMANCHE_STANDARD", typ: u(undefined, "") },
        { json: "ID", js: "ID", typ: "" },
        { json: "JEUDI_STANDARD", js: "JEUDI_STANDARD", typ: u(undefined, "") },
        { json: "LUNDI_STANDARD", js: "LUNDI_STANDARD", typ: u(undefined, "") },
        { json: "MAIL", js: "MAIL", typ: r("Mail") },
        { json: "MAINTYPE", js: "MAINTYPE", typ: "" },
        { json: "MARDI_STANDARD", js: "MARDI_STANDARD", typ: u(undefined, "") },
        { json: "MERCREDI_STANDARD", js: "MERCREDI_STANDARD", typ: u(undefined, "") },
        { json: "NOM", js: "NOM", typ: "" },
        { json: "PAYS", js: "PAYS", typ: r("Pays") },
        { json: "SAMEDI_STANDARD", js: "SAMEDI_STANDARD", typ: u(undefined, "") },
        { json: "URL", js: "URL", typ: "" },
        { json: "VENDREDI_STANDARD", js: "VENDREDI_STANDARD", typ: u(undefined, "") },
        { json: "VILLE", js: "VILLE", typ: r("Ville") },
        { json: "lat", js: "lat", typ: 3.14 },
        { json: "lng", js: "lng", typ: 3.14 },
        { json: "ADRESSE_COMPLEMENT", js: "ADRESSE_COMPLEMENT", typ: u(undefined, "") },
        { json: "TEL", js: "TEL", typ: u(undefined, r("Tel")) },
    ], false),
    "APublier": [
        "oui",
    ],
    "Mail": [
        "",
        "renseignements-dechets@enca.org",
        "renseignements-dechets@nca.org",
    ],
    "Pays": [
        "FR",
    ],
    "Tel": [
        "",
        "0 805 055 055",
    ],
    "Ville": [
        "Nice",
    ],
};

