import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { Auth, onAuthStateChanged, signInAnonymously } from '@angular/fire/auth'
import { Storage, ref, getDownloadURL } from '@angular/fire/storage'
import { Contrat, GuideDutri } from 'src/app/models/contrat'
import { ContratService } from 'src/app/services/contract.service'
import { environment } from 'src/environments/environment'
import { Router } from '@angular/router'
import { findBestMatch } from 'string-similarity'
import { CookieService } from "ngx-cookie-service"

@Component({
    selector: 'guide-widget',
    templateUrl: './guide.component.html',
    styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {
    contractColor?: string
    contractColorWithAlpha?: string
    listBackgroundColor?: String
    guideDutri?: GuideDutri[]
    number_waste = 300
    filtredList: GuideDutri[] = []
    enableCard: boolean = false
    hidden_spinner: boolean = false
    selectedItemInfos: any
    selectedItemImg : any
    selectedItemTitle : any
    cards_hidden: boolean = true
    list_hidden: boolean = true
    pills_hidden: boolean = false
    hover: boolean = false
    hover3: boolean = false
    hover1: boolean = false
    hover2: boolean = false
    hover4: boolean = false
    waste_items_grouped:{[key: string]:GuideDutri[]} = {}
    waste_items_grouped_with_images:{[key: string]:GuideDutri[]} = {}
    searchText: any = ""
    changed_text: string = ""
    ListbackgroundChilddren?: string
    wasteItemsGroupImage:any = {}
    motsCle?: string
    listMotsCle: any = []
    completeWasteList: any = []
    clicked : boolean = false
    contrat: Contrat = new Contrat()
    @Input() contractId = 'Est Ensemble'
    @ViewChild('search_field', {static: false})
    inputEl?: ElementRef
    filteredWasteList: GuideDutri[] = []
    topWasteList: GuideDutri[] = []

    constructor(private contractService: ContratService, private auth: Auth, private storage: Storage, private router : Router, private cookieService : CookieService) { }

    ngOnInit(): void {
        onAuthStateChanged(this.auth, (user) => {
            if (user) {
                this.contractService.contract.asObservable().subscribe((contrat) => {
                    if (!contrat.contrat) {
                        console.log("Contrat vide")
                        if (environment.widget) {
                            this.contractService.loadContract(this.contractId)
                        }  
                    } else {
                        console.log('Constrat set')
                        this.contractService.serviceButtonChangeColor.next("Guide du tri")
                        this.contrat = contrat
                        this.contractColor = this.contrat.design?.couleurPrincipale
                        this.contractColorWithAlpha = `${this.contractColor}33`
                        this.guideDutri = this.contrat.guideDutri.slice(0, this.number_waste)
                        this.waste_items_grouped = this.groupBy(this.guideDutri, 'famille')
                        this.listBackgroundColor = this.hexToRgbA(this.contractColor, "0.05")

                        let map :{
                            [key: string]: string
                          } = {
                            "Animalerie": "dechet400.png",
                            "Appareils électriques et électroniques": "dechet100.png",
                            "Déchets alimentaires": "dechet110.png",
                            "Bio déchets de jardin": "dechet120.png",
                            "Bois": "dechet130.png",
                            "Bouchons de bouteille": "dechet140.png",
                            "Eclairage": "dechet150.png",
                            "Emballages et produits en carton": "dechet160.png",
                            "Emballages et produits en métal": "dechet170.png",
                            "Emballages et produits en plastique": "dechet180.png",
                            "Emballages en polystyrène": "dechet200.png",
                            "Emballages en verre": "dechet210.png",
                            "Loisirs et jeux": "dechet220.png",
                            "Matériaux de construction": "dechet230.png",
                            "Mobilier et objets divers de la maison": "dechet240.png",
                            "Papiers": "dechet250.png",
                            "Produits automobile": "dechet260.png",
                            "Produits dangereux": "dechet270.png",
                            "Produits d'hygiène": "dechet280.png",
                            "Produits médicaux": "dechet290.png",
                            "Textile": "dechet300.png",
                            "Bouchons en liège": "dechet141.png",
                            "Cartons bruns": "dechet164.png",
                            "Emballages alimentaires en polystyrène": "dechet201.png",
                            "Emballages en carton ou bambou": "dechet160.png",
                            "Végétaux": "dechet120.png",
                            "Polystyrène": "dechet202.png",
                            "Emballages ménagers en carton" : "dechet162b.png"
                        }

                        var nbImagesToDownload = 0
                        for (let key in this.waste_items_grouped) {
                            if (map[key]) {
                                nbImagesToDownload += 1
                            }
                        }
            
                        for (let key in this.waste_items_grouped) {
                            if (map[key]) {
                                const iconRef = ref(this.storage, "Icons-guidedutri/" + map[key])
                                getDownloadURL(iconRef).then((href) => {
                                    this.wasteItemsGroupImage[key] = href
                                    nbImagesToDownload -= 1
                                    if (nbImagesToDownload == 0) {
                                        this.waste_items_grouped_with_images = this.waste_items_grouped
                                    }
                                })
                            }
                        }

                        for (let waste of this.contrat.guideDutri) {
                            for (let info of waste.infos) {
                                if (info.image) {
                                    const imageRef = ref(this.storage, this.contrat.contrat?.idContrat + "/" + environment.resourcesOrigin + "/" + info.image)
                                    getDownloadURL(imageRef).then((href) => {
                                        info.image = href
                                    })
                                }
                            }
                            const iconRef = ref(this.storage, "Icons-guidedutri/" + waste.image)
                            getDownloadURL(iconRef).then((href) => {
                                waste.url_image = href
                                const arrayMotCle = waste.motsCle?.split(", ") ?? []
                                this.completeWasteList.push(waste)
                                for (let motsCle of arrayMotCle) {
                                    if (motsCle == "") {
                                        return
                                    }
                                    const newElement = Object.assign({}, waste)
                                    newElement.nom = motsCle
                                    this.completeWasteList.push(newElement)
                                }
                                const arrayTopWaste = waste.top?.split(", ") ?? []
                                for (let topWaste of arrayTopWaste) {
                                    if (topWaste == "") {
                                        return
                                    }
                                    const newElement = Object.assign({}, waste)
                                    newElement.nom = topWaste
                                    this.topWasteList.push(newElement)
                                }
                            })
                        }
                    }
                })
            } else {
                console.log('Compte anonyme crée')
                signInAnonymously(this.auth)
            }
        })
    }

    onFocusSearch() {
        const contractColor = this.contractColor as string;
        const contratColor06 = this.hexToRgbA(contractColor, "0.6")
        const contratColor0075 = this.hexToRgbA(contractColor, "0.075")
        const input2:any = document.getElementById("input_search_waste")
        input2.style.borderColor = contractColor
        input2.style.boxShadow = "inset 0 1px 1px " + contratColor0075 + ", 0 0 8px " + contratColor06
        this.onClose()
    }

    onClose() {
        this.guideDutri = this.contrat?.guideDutri
        this.filtredList = []
        this.list_hidden = true
        this.cards_hidden = true
        this.pills_hidden = false
        this.searchText = ''
    }

    onBlurSearch() {
        const input = document.getElementById("input_search_waste") as HTMLInputElement
        input.style.borderColor = "gray"
        input.style.boxShadow = "none"
    }

    hexToRgbA(hex: any | undefined, opacity: string) {
        let c:any
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split("")
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]]
            }
            c = "0x" + c.join("")
            return (
                "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
                "," +
                opacity +
                ")"
            )
        }
        throw new Error("Bad Hex")
    }

    goToFluxInMap(flux: string[]) {
        this.contractService.defaultFlux = flux[0]
        this.contractService.serviceButtonChangeColor.next("Carte des points de collecte")
        this.router.navigateByUrl("/services/map")
    }

    gotoUrl(url: string) {
        switch(url) {
            case "calendar":
                this.contractService.serviceButtonChangeColor.next("Calendrier de collecte")
                break
            case "reporting":
                this.contractService.serviceButtonChangeColor.next("Demandes et Signalements")
                break
            default:
                break
        }
        if (url == "calendar" && this.contrat.contrat?.collectePolygon != true) {
            url = 'calendarWithoutPolygones'
        }
        this.router.navigateByUrl(`/services/${url}`)
    }

    searchWaste(filter: string) {
        if (filter.length > 2) {
            this.changed_text = filter
            this.filteredWasteList = this.completeWasteList.filter((item: { nom: string; }) => {
                    const nomLowerCasedWithoutAccents = item.nom.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ")
                    const filterLowerCasedWithoutAccents = filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ").filter(elem => elem != "")
                    let ratings = []
                    for (const f of filterLowerCasedWithoutAccents) {
                        ratings.push(findBestMatch(f, nomLowerCasedWithoutAccents).bestMatch.rating)
                    }
                    let ratio = 1
                    for (const r of ratings) {
                        if (r  < (0.6/ratio)) {
                            return false
                        }
                        ratio = ratio * 3
                    }
                    return true
                }
            );
            this.list_hidden = this.filteredWasteList.length == 0
            this.pills_hidden = !this.list_hidden
        } else {
            this.cards_hidden = true
            this.list_hidden = true
            this.pills_hidden = false
        }
    }

    filterListWaste(item?: GuideDutri) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.searchText = item?.nom
        this.filtredList = item ? [item] : []
        this.inputEl?.nativeElement.blur()
        this.list_hidden = true
        this.cards_hidden = false
        this.pills_hidden = true
    }

    groupBy = function(xs: any[], key: string) {
        return xs.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x)
            return rv
        }, {})
    }
}

