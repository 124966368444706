import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppComponent } from './app.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { provideFirebaseApp, initializeApp } from '@angular/fire/app'
import { getFirestore, provideFirestore } from '@angular/fire/firestore'
import { provideAuth, getAuth } from '@angular/fire/auth'
import { environment } from '../environments/environment'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'
import { NgxStarsModule } from 'ngx-stars'
import { WelcomeComponent } from './components/welcome/welcome.component'
import { getStorage, provideStorage } from '@angular/fire/storage'
import { NgxSpinnerModule } from 'ngx-spinner'
import { ContactComponent } from './components/contact/contact.component'
import { VoteComponent } from './components/vote/vote.component'
import { ToastrModule } from 'ngx-toastr'
import { CustomToast } from './components/toast/toast.component'
import { SignupComponent } from './components/parrainage/signup/signup.component'
import { getAnalytics } from "firebase/analytics";
import { SigninComponent } from './components/signin/signin.component'
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
// import { environment } from '../environments/environment';

@NgModule({
    declarations: [
      AppComponent,
      WelcomeComponent,
      ContactComponent,
      VoteComponent,
      CustomToast,
      SignupComponent,
      SigninComponent,
      DashboardComponent,
      SignUpComponent
    ],
    imports: [
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      FontAwesomeModule,
      BrowserAnimationsModule,
      AngularFireModule.initializeApp(environment.firebase),
      AngularFireAuthModule,
      AngularFirestoreModule,
      AngularFireStorageModule,
      AngularFireDatabaseModule,
      AppRoutingModule,
      provideFirebaseApp(() => {
        const app = initializeApp(environment.firebase)
        getAnalytics(app)
        return app
      }),
      provideFirestore(() => {
        const firestore = getFirestore()
        return firestore
      }),
      provideStorage(() => {
        const storage = getStorage()
        return storage
      }),
      provideAuth(() => {
        const auth = getAuth()
        return auth
      }),
      NgxStarsModule,
      NgxSpinnerModule,
      ToastrModule.forRoot()
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    entryComponents: [CustomToast],
    providers: [],
    bootstrap: [AppComponent]
})

export class AppModule {
}