import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { BrowserModule } from '@angular/platform-browser'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { createCustomElement } from '@angular/elements'
import { getStorage, provideStorage } from '@angular/fire/storage'
import { provideFirebaseApp, initializeApp } from '@angular/fire/app'
import { getFirestore, provideFirestore } from '@angular/fire/firestore'
import { provideAuth, getAuth } from '@angular/fire/auth'
import { environment } from '../environments/environment'
import { NewsComponent } from './components/actus/actus.component'
import { GuideComponent } from './components/guide/guide.component'
import { AutocompleteComponent } from './components/google-places/google-places.component'
import { ReportingComponent } from './components/signalements/signalements.component'
import { MontriComponent } from './components/montri/montri.component'
import { CalendrierComponent } from './components/calendrier/calendrier.component'
import { CalendrierSansPolygonesComponent } from './components/calendrier-sans-polygones/calendrier-sans-polygones.component'
import { MapComponent } from './components/map/carte.component'
import { NgxSpinnerModule } from 'ngx-spinner'
import { GoogleMapsModule } from '@angular/google-maps'
import { LOCALE_ID } from '@angular/core'
import { registerLocaleData } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import localeFR from "@angular/common/locales/fr"
import { HomeComponent } from './components/home/home.component'
import { ToastrModule } from 'ngx-toastr'
import { WidgetRoutingModule } from './widget-routing.module'
registerLocaleData(localeFR)

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => {
      const firestore = getFirestore()
      return firestore;
    }),
    provideStorage(() => {
      const storage = getStorage()
      return storage;
    }),
    provideAuth(() => {
      const auth = getAuth()
      return auth;
    }),
    WidgetRoutingModule,
    NgxSpinnerModule,
    GoogleMapsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    CookieService
  ],
  entryComponents: [MontriComponent],
  declarations: [
    MontriComponent,
    NewsComponent,
    GuideComponent,
    ReportingComponent,
    CalendrierComponent,
    CalendrierSansPolygonesComponent,
    AutocompleteComponent,
    MapComponent,
    HomeComponent],
  exports:[
    MontriComponent,
    GuideComponent,
    ReportingComponent,
    MapComponent,
    CalendrierComponent,
    CalendrierSansPolygonesComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class WidgetModule { 
  constructor(injector: Injector) {
    const montri = createCustomElement(MontriComponent, { injector })
    customElements.define('montri-widget', montri)

    const news = createCustomElement(NewsComponent, { injector })
    customElements.define('actus-widget', news)

    const guide = createCustomElement(GuideComponent, { injector })
    customElements.define('guide-widget', guide)

    const report = createCustomElement(ReportingComponent, { injector })
    customElements.define('signalements-widget', report)

    const calendar = createCustomElement(CalendrierComponent, { injector })
    customElements.define('calendrier-widget', calendar)

    const calendarWithoutPoly = createCustomElement(CalendrierSansPolygonesComponent, { injector })
    customElements.define('calendriersans-widget', calendarWithoutPoly)

    const carte = createCustomElement(MapComponent, { injector })
    customElements.define('carte-widget', carte)
  }
  ngDoBootstrap() {}
}



