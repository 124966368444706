<div class="row">
    <div class="col-0 col-sm-1"></div>
    <div class="col-12 col-sm-10">
        <div class="row mt-4 mb-5 ml-sm-3">
            <div class="col d-none d-sm-block" style="min-width:10rem;max-width:10rem;">
                <div [routerLink]="[{ outlets: { sub: ['/services/news'] } }]" class="card mt-3 card_services flex-row flex-wrap" *ngFor="let service of services"
                    (click)="changeService(service)" [ngStyle]="setBorderStyles(service.nom)">
                    <div class="card-header border-0 bg-transparent px-0">
                        <img [src]="serviceIcons[service.nom]" style="transform: scale(0.5)">
                    </div>
                    <div class="card-block my-auto pr-2">
                        <p class="card-text avenir_heavy" [ngStyle]="{'color': serviceStyles[service.nom][1]}">{{ serviceNames[service.nom] }}</p>
                    </div>
                    <div class="w-100"></div>
                </div>
            </div>
            <div class="col">
                <div class="dropdown d-sm-none mx-auto mt-5 mb-5 px-5" style="min-width: 15rem;" >
                    <button class="btn btn-secondary navbar-dark w-100" [ngStyle]="{'background-color': contractColor,'border-color': contractColor}" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="navbar-toggler-icon mb-1"></span><span class="pl-3 mt-1">{{ selectedServiceName }}</span>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <div class="dropdown-item pl-0 pt-0 pb-0" (click)="changeService(service)" *ngFor="let service of filteredServices()"><img [src]="serviceIcons[service.nom]" style="transform: scale(0.5)">{{ serviceNames[service.nom] }}</div>
                    </div>
                </div>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>