import { Component, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { City, Contrat, Vote } from 'src/app/models/contrat'
import { ContratService } from 'src/app/services/contract.service'

@Component({
	selector: 'app-vote',
	templateUrl: './vote.component.html',
	styleUrls: ['./vote.component.scss']
})

export class VoteComponent implements OnInit {
	contractColor?: String
	contrat?: Contrat
	city?: City

	constructor(
        private contractService: ContratService,
		private activeModal: NgbActiveModal
	) { }

	ngOnInit(): void {
	}

	vote() {
		var vote = new Vote(this.city?.codePostal, this.city?.nom)
		this.contractService.sendVote(vote)
		this.activeModal.close()
		window.scrollTo(0, 0)
	}

	closeModal() {
		this.activeModal.close()
	}
}
