import { Component, Input, OnInit } from '@angular/core'
import { Actualite, Contrat } from 'src/app/models/contrat'
import { Auth, onAuthStateChanged, signInAnonymously } from '@angular/fire/auth'
import { ContratService } from 'src/app/services/contract.service'
import { Storage, ref, getDownloadURL } from "@angular/fire/storage"
import { formatDate } from '@angular/common'
import { CookieService } from 'ngx-cookie-service'
import { environment } from 'src/environments/environment'
import { combineLatest } from 'rxjs'
import { Timestamp } from '@angular/fire/firestore'

@Component({
    selector: 'actus-widget',
    templateUrl: './actus.component.html',
    styleUrls: ['./actus.component.scss']
})
export class NewsComponent implements OnInit {

    @Input() contractId = 'Est Ensemble'
    actus : Actualite[] = []
    contractColor? = ""
    contract?: Contrat

    constructor(private contractService: ContratService, 
                private auth: Auth, 
                private storage: Storage,
                private cookieService : CookieService) { }

    ngOnInit(): void {
    
        if (this.contractService.skipInit == 1) {
            this.contractService.skipInit++
        } else if (this.contractService.skipInit == 2) {
            this.contractService.skipInit = 0
            return
        }

        onAuthStateChanged(this.auth, (user) => {
            if (user) {
                combineLatest([this.contractService.contract.asObservable(), this.contractService.actus.asObservable()]).subscribe((res) => {
                    const contrat = res[0]
                    const actus = res[1]
                    if (!contrat.contrat) {
                        console.log("Contrat vide")
                        if (environment.widget) {
                            this.contractService.loadContract(this.contractId)
                        }  
                    } else {
                        console.log('Contract set')
                        this.contract = contrat
                        this.contractService.serviceButtonChangeColor.next("Actualités et événements")
                        this.contractColor = contrat.design?.couleurPrincipale
                        if (environment.widget && !actus.actualites && this.contract?.contrat?.idContrat) {
                            console.log("Actus vide")
                            this.contractService.loadActualites(this.contract?.contrat?.idContrat)
                        } else {
                            console.log('Actus set')
                            this.actus = actus.actualites ?? []
                            this.actus = this.actus.filter(it => {
                                let lowerBound = true
                                let upperBound = true
                                if (it.dateDebut) {
                                    lowerBound = it.dateDebut < Timestamp.now()
                                }
                                if (it.dateFin) {
                                    upperBound = it.dateFin > Timestamp.now()
                                }
                                return lowerBound && upperBound
                            })
                            let highlightedNews = this.actus.filter(it => it.epingler).sort((a, b) => {
                                var aDate = a.dateDebut ?? a.date
                                var bDate = b.dateDebut ?? b.date
                                return bDate - aDate
                            })
                            this.actus = this.actus.filter( it => !it.epingler ).sort((a, b) => {
                                var aDate = a.dateDebut ?? a.date
                                var bDate = b.dateDebut ?? b.date
                                return bDate - aDate 
                            })
                            this.actus = highlightedNews.concat(this.actus)
                            for (let element of this.actus) {
                                const img = ref(this.storage, `${this.contract?.contrat?.idContrat}/actualites/${element.image}`)
                                getDownloadURL(img).then((url:any) => {
                                    element.image = url
                                })
                            }
                        } 
                    }
                })
            } else {
                console.log('Compte anonyme crée')
                signInAnonymously(this.auth)
            }
        })
    }

    formatDate(date: any) {
        return formatDate(date.toDate(), "dd MMMM yyyy", "fr")
    }

    onClickImage(url: string | undefined) {
        window.open(url, '_blank')?.focus()
    }
}
