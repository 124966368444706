<div class="container my-5">
    <div class="row justify-content-center">
        <img class="col-6 col-md-4" src="../../../../../assets/images/icone-invite-sent.svg" alt="">
    </div>
    <div class="row justify-content-center">
        <h3 class="col-6 col-md-4 my-5" style="font-family: avenir_heavy;text-align: center;">Votre compte est créé !</h3>
    </div>
    <div class="row justify-content-center">
        <h4 class="col-6 col-md-4 my-5" style="font-family: avenir_heavy;font-size: 120%;text-align: center;">{{textWeb}}</h4>
    </div>
    <div class="row justify-content-center">
        <button type="button" class="btn btn-warning mb-5 col-6 col-md-3 py-3" (click)='download()' style="font-family: avenir_heavy; color: white;">Télécharger</button>
    </div>
</div>
