import { Report } from "../models/contrat"
import { Injectable } from "@angular/core"
import { collection, doc, Firestore, getDoc, query, where } from "@angular/fire/firestore"
import { BehaviorSubject } from "rxjs"
import { User_Montri } from '../models/user'
import { setDoc, onSnapshot } from 'firebase/firestore'

@Injectable({
	providedIn: "root"
})

export class UserService {
    user: BehaviorSubject<User_Montri> = new BehaviorSubject<User_Montri>({})
    reports : BehaviorSubject<Report[]> = new BehaviorSubject<Report[]>([])

    constructor(private db: Firestore) { }

    async loadReports(contractId: string, uid:string){
        const q = query(collection(this.db, "formulaires"), where("uid", "==", uid), where("idContrat", "==", contractId))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const reports:Report[] = []
            querySnapshot.forEach((doc) => {
                let report = doc.data() as Report
                report.gid = doc.id
                reports.push(report)
            })
            this.reports.next(reports)
        })
    }

    async loadUser(contractId: string, uid:string) {
        const docRef = doc(this.db, "users", uid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data())
            this.user.next(docSnap.data())
            this.loadReports(contractId, uid)
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }

    async updateUser(user: User_Montri, uid:string){
        if(uid){
            const docRef = doc(this.db, 'users', uid);
            await setDoc(docRef, user, { merge: true })
        }
    }
}
