<div class="ml-5 mr-5 mt-3 mb-5">
    <div class="row ml-0 ml-sm-3 mr-0">
        <div class="col-12 px-0 inner-addon left-addon" style="min-width: 16rem;max-width:30rem;">
            <i class="fa fa-search"></i> 
            <i class="fa fa-times-circle" [hidden]="cards_hidden" (click)="onClose()" style="left:auto;right:0px;cursor: pointer;pointer-events:auto"></i>
            <input #search_field type="text" class="form-control w-100" (blur)="onBlurSearch()"
                (focus)="onFocusSearch()" onfocus="this.placeholder = ''"
                onblur=" this.placeholder = 'Tapez ici votre déchet'" [(ngModel)]="searchText"
                style="border-radius: 10px; border: 0px; background-color: #f4f3f3;color: black;" (ngModelChange)="searchWaste($event)"
                placeholder="Tapez ici votre déchet" aria-label="Default" autocomplete="off"
                data-toggle="dropdown" aria-describedby="inputGroup-sizing-default" id="input_search_waste">
        </div>
    </div>

    <div class="row ml-0 ml-sm-3 mt-4 mr-0" [hidden]="list_hidden" >
        <div class="col-12 rounded-lg border px-5 pt-2 pb-3" style="min-width: 16rem;">
            <ul class="list-group list-group-flush" [hidden]="searchText.length > 2 ? false : true">
                <li class="list-group-item" style="cursor: pointer;" *ngFor="let item of filteredWasteList" (click)="filterListWaste(item)">
                    <div class="card border-0 flex-md-row flex-md-wrap">
                        <div class="card-header border-0 bg-transparent mx-auto mx-md-0">
                            <img [src]="item.url_image" style="width:2rem;height:2rem;">
                        </div>
                        <div class="card-block my-auto pt-1">
                            <p class="card-text avenir_heavy text-center text-md-left" [ngStyle]="{'color': contractColor}">{{ item.nom }}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div class="row ml-0 ml-sm-3 mt-4 mr-0" [hidden]="pills_hidden">
        <div class="col-12 col-lg-8 px-0" style="min-width: 16rem;">
            <div class="row">
                <h5 class="ml-3 mt-3 mb-4">Les plus courants :</h5>
            </div>
            <div class="d-flex flex-row flex-wrap" >
                <p class="avenir_heavy py-2 px-4 mr-2 rounded-pill" style="cursor: pointer;" *ngFor="let item of topWasteList" (click)="filterListWaste(item)" [ngStyle]="{'background-color': contractColorWithAlpha}">{{ item.nom }}</p>
            </div>
        </div>
    </div>

    <div class="row ml-0 ml-sm-3 mt-4 mr-0" style="max-width:30rem;" [hidden]="cards_hidden">
        <div class="col-12 px-0" style="min-width: 16rem;">
            <div class="card" *ngFor="let item of filtredList">
                <div class="card-header border-0 bg-transparent" class="mx-auto mt-4">
                    <img style="max-width: 7rem;" [src]="item.url_image">
                </div>
                <div class="card-block mt-2 mx-auto px-4 mb-5">
                    <p class="card-text avenir_heavy py-2 px-4 text-center rounded-pill" [ngStyle]="{'background-color': contractColorWithAlpha}">{{ item.nom }}</p>
                </div>
                <div class="card-block mx-4" *ngFor="let act of item.infos">
                    <p class="card-text">{{ act.description }}</p>
                    <div class="card-bloc mb-4">
                        <img [src]="act.image" style="max-width: 15rem" *ngIf="act.image != null">
                    </div>
                    <button type="button"
                        class="btn btn-primary rounded-pill"
                        [ngStyle]="{'background-color': contractColor, 'border-color': contractColor}" style="font-family: avenir_heavy;font-size: 90%;"
                        (click)="goToFluxInMap(act.flux)" *ngIf="act.action=='carte'">
                        {{ act.libelleBouton }}
                    </button>
                    <button type="button"
                        class="btn btn-primary rounded-pill"
                        [ngStyle]="{'background-color': contractColor, 'border-color': contractColor}" style="font-family: avenir_heavy;font-size: 90%;"
                        (click)="gotoUrl('calendar')" *ngIf="act.action=='calendrier'">
                        {{ act.libelleBouton }}
                    </button>
                    <button type="button"
                        class="btn btn-primary rounded-pill"
                        [ngStyle]="{'background-color': contractColor, 'border-color': contractColor}" style="font-family: avenir_heavy;font-size: 90%;"
                        *ngIf="act.action=='lien'">
                        <a target="_blank" href="{{act.lien}}" style="text-decoration: none; color: #fff; background-color: transparent;">
                            {{ act.libelleBouton }}
                        </a>
                    </button>
                    <button type="button"
                        class="btn btn-primary rounded-pill"
                        [ngStyle]="{'background-color': contractColor, 'border-color': contractColor}" style="font-family: avenir_heavy;font-size: 90%;"
                        (click)="gotoUrl('reporting')" *ngIf="act.action=='encombrants'">
                        {{ act.libelleBouton }}
                    </button>
                    <button type="button"
                        class="btn btn-primary rounded-pill"
                        [ngStyle]="{'background-color': contractColor, 'border-color': contractColor}" style="font-family: avenir_heavy;font-size: 90%;"
                        *ngIf="act.action=='email'">
                        <a href="mailto:{{act.contact}}" style="text-decoration: none; color: #fff; background-color: transparent;">
                            {{ act.libelleBouton }}
                        </a>
                    </button>
                    <div class="mt-4" ></div>
                    <p class="card-text avenir_heavy">{{ act.transition }}</p>
                </div>
            </div>
        </div>
    </div>

    <div [hidden]="pills_hidden" class="row ml-3 mb-3 mt-4">
        <h5>Liste des catégories :</h5>
    </div>

    <div [hidden]="pills_hidden" class="row ml-1 mt-2 mb-3" *ngFor="let item of waste_items_grouped_with_images | keyvalue , let i = index" >
        <div class="col-lg-12 pl-2">
            <!-- Familles -->
            <div id="accordion" role="tablist" aria-multiselectable="true" >
                <div class="card-header px-0 px-sm-4" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" role="tab" id="accordionHeadingOne">
                    <a data-toggle="collapse" data-parent="#accordion" href="#t{{i}}" aria-expanded="false" aria-controls="accordionBodyOne" class="collapsed">
                        <div class="row">
                            <div class="col-1 my-auto" style="min-width: 4rem;">
                                <img src="{{wasteItemsGroupImage[item.key]}}" class="icon-taille-famille" alt="" />
                            </div>
                            <div class="col-8 col-md-5 my-auto pl-0">
                                <h6 class="mb-0">{{item.key}}</h6>
                            </div>
                            <div class="col-1 pr-0 my-auto accordion-head">
                                <i class="fa fa-angle-down" [ngStyle]="{'color':contractColor}" aria-hidden="true"></i>
                            </div>
                        </div>
                    </a>
                </div>
                <div id="t{{i}}" class="collapse" role="tabpanel" aria-labelledby="accordionHeadingOne" aria-expanded="false" data-parent="accordion">
                    <div class="card-block col-lg-12">
                        <!-- Enfants -->
                        <div *ngFor="let item of item.value, let f = index">
                            <div id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="card-header px-0 px-md-5"  role="tab" id="accordionHeadingOne" >
                                    <a data-toggle="collapse" data-parent="#accordion" href="#q{{i}}{{f}}" aria-expanded="false" aria-controls="accordionBodyOne" class="collapsed">
                                        <div class="row mt-2" (click)="filterListWaste(item)" >
                                            <div class="col-1 my-auto" style="min-width: 4rem;" >
                                                <img src="{{item.url_image}}" class="icon-taille" alt="" />
                                            </div>
                                            <div class="col-9 col-md-5 my-auto pl-0">
                                                <h6 class="mb-0" style="font-family: avenir_light; font-size: 90%;">{{item.nom}}</h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>