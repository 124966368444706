<div class="container-fluid main" [hidden]="hidden_page">
    <div class="row">
        <div class="col-12 d-flex flex-column justify-content-center align-items-center" id="background" [ngStyle]="{'background-image':' url(' + imageCover + ')'}">
            <div class="container" style="height: 100%;">
                <div class="row" style="height: 100%;">
                    <div class="col-12 d-flex flex-column align-items-center justify-content-center"style="height: 100%;">
                        <h1 class="mt-4 avenir_heavy responsive_font1">{{contrat?.Web?.titreCover}}</h1>
                        <p class="mt-4 avenir_light responsive_font">{{contrat?.Web?.soustitreCover}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row d-flex  flex-column justify-content-around  pt-3 pb-1 wrap"
        [ngStyle]="{'background-color' : contractColor}">
        <div class="d-flex flex-row justify-content-center wrap ">
            <h3 class="avenir_heavy" [ngStyle]="{'color':'white', 'text-align': 'center' }">Nos services</h3>
        </div>
        <div class="container cards-list">
            <div class="card d-flex flex-column"
                *ngFor="let service of services" [ngClass]="service.active ? 'card_services' : 'card_services_disabled'" (click)="goToServices(service)">
                <span *ngIf="!service.active" class="tooltiptext">Service indisponible dans votre ville</span>
                <div class="d-flex flex-row justify-content-center pt-2">
                    <img [src]="getServiceIcon(service)"
                        [ngStyle]="{'width.px':50, 'height.px':50 , 'fill':service.active ? contractColor : '#aaaaaa','stroke': service.active ? contractColor : '#aaaaaa'}"/>
                </div>
                <div class="d-flex flex-row h-100 justify-content-center">
                    <h5 [ngStyle]="{'color':service.active ? contractColor : '#aaaaaa', 'text-align': 'center', 'cursor': service.active ?  'pointer' : 'default' }" class="default avenir_heavy pt-2">
                        {{service.nom}}</h5>
                </div>
            </div>
        </div>
    </div>

    <div class="mx-5 row"  *ngFor="let item of contrat?.Web?.Home_tabs ?? []; let i = index; let isEven = even ; let isOdd = odd;"
    [ngClass]="{'flex-row': isEven , 'flex-row-reverse':isOdd,'flex-md-row-reverse': isOdd }">
        <div class="col-md-6 d-flex flex-row justify-content-center align-items-center pt-4 pb-4">
            <div class="row">
                <div class="col-md-2">
                </div>
                <div class="col-md-8 d-flex flex-column align-items-start justify-content-start">
                    <h2 class="avenir_heavy">{{item.title}}</h2>
                    <p class="mt-2" style="font-size: large;white-space: pre-line">{{item.text}}</p>
                </div>
                <div class="col-md-2">
                </div>
            </div>
        </div>
        <div class="col-md-6 d-flex flex-row align-items-center justify-content-center pt-4 pb-4">
            <img  [src]="item.url_image" class="site_screen_app" alt="img">
        </div>
    </div>
    
    <div class="container">
        <div class="row">
            <div class="col-2"></div>
            <div class="col-8">
                <div class="row d-flex flex-row justify-content-center pt-5">
                    <h4 [ngStyle]="{'color': contractColor, 'text-align': 'center' }" class="avenir_heavy">Télécharger gratuitement
                        l'application</h4>
                </div>
                <div class="row d-flex flex-row justify-content-center pt-1">
                    <h6 class="avenir_light" style="font-weight: bold;">4.5 étoiles</h6>
                </div>
                <div class="row d-flex flex-row justify-content-center pt-0">
                    <ngx-stars [readonly]="true" [color]="'#FFD700'" [size]="2" [initialStars]="4.5"></ngx-stars>
                </div>
            </div>
            <div class="col-2"></div>
        </div>
    </div>

    <div class="container">
        <div class="row  pt-4 pb-5">
            <div class="col-2"></div>
            <div class="col-8 stores">
                <a href="https://apps.apple.com/fr/app/montri-trier-solidaire/id1458927235" target="_blank"><img src="../../assets/img/Badge-Appstore.png"
                style="width: 200px;height: 60px;border: 1px solid white; border-radius: 10px;"
                class="stores_padding_right btn_apple"></a>
                <a href="https://play.google.com/store/apps/details?id=eu.uzer.montri&hl=fr" target="_blank"><img src="../../assets/img/Badge-GooglePlay.png"
                style="width: 200px;height: 60px;border-radius: 10px;" class="stores_padding_left btn_google"></a>
            </div>
            <div class="col-2"></div>
        </div>
    </div>
</div>