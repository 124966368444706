<div class="parent" class="toast-body" style='
    padding: .75rem;
    word-wrap: break-word;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 14px;
    grid-row-gap: 0px;'>
    <div class="div1" style='grid-area: 1 / 1 / 2 / 2;'> 
        <img [src]='image' style="width: 75%;display: table-cell;
        vertical-align: middle;
        text-align: center;" >
    </div>
    <div class="div2 my-auto" style='grid-area: 1 / 2 / 2 / 3;display: inline;
    font-family: avenir_heavy;
    text-align: center;'>
        <p style='display: inline;
        margin-top: auto;'>{{ message }}</p>
    </div>
    <div class="div3 my-auto" style=' grid-area: 1 / 3 / 2 / 4;text-align: center;'>
    <img *ngIf="options.closeButton" (click)='onClick()' src='./../../../assets/img/alerts/close.svg' style="width: 20%;margin-left: auto;
    margin-right: 5%;
    display: block;">
    </div>
</div>