
		<form [formGroup]="ContactForm" (ngSubmit)="sendContactMail(ContactForm.value)" class="modal-content  border_modal" >
			<div class="modal-header d-flex flex-row justify-content-center w-100" style='border: none;'>
				<h5 class="avenir_heavy" [ngStyle]="{'color':contractColor}" id="exampleModalLongTitle">Contactez-nous</h5>
			</div>

			<div class="modal-body avenir_light nav_bar_link border-0 " >
				<input type="text" autofocus="autofocus" style="display:none" />
				<div class="form-group">
					<label for="contact_email" [ngStyle]="{'color':contractColor}" class="col-form-label avenir_light">Email:</label>
					<input autocomplete="off"  [ngStyle]="{'border-color':contractColor}" class="form-control avenir_light" style="box-shadow: inset 0px 0px 0px 0px;" formControlName="contact_email" id="contact_email"
					onfocus="this.placeholder = ''" onblur="this.placeholder = 'Renseigner adresse e-mail....'"
					style="font-family:Arial, FontAwesome" placeholder="Renseigner votre adresse e-mail..." />
				</div>

				<div class="form-group">
					<label for="subject_email" [ngStyle]="{'color':contractColor}" class="col-form-label avenir_light">Objet:</label>
					<input autocomplete="off" [ngStyle]="{'border-color':contractColor}" class="form-control avenir_light" style="box-shadow: inset 0px 0px 0px 0px;" formControlName="subject_email" id="subject_email"
					onfocus="this.placeholder = ''" onblur="this.placeholder = 'Renseigner l\'objet de votre message....'"
					style="font-family:Arial, FontAwesome" placeholder="Renseigner l'objet de votre message..." />
				</div>

				<div class="form-group">
					<label for="message_email" [ngStyle]="{'color':contractColor}" class="col-form-label avenir_light">Message:</label>
					<textarea autocomplete="off" [ngStyle]="{'border-color':contractColor}" class="form-control avenir_light" style="box-shadow: inset 0px 0px 0px 0px;" formControlName="message_email" id="message_email"
					onfocus="this.placeholder = ''" onblur="this.placeholder = 'Renseigner votre message....'"
					style="font-family:Arial, FontAwesome" placeholder="Renseigner votre message..." ></textarea>
				</div>
			</div>
			<div class="modal-footer" style='border: none;'>
				<button type="button" class="btn btn-secondary"
					[ngStyle]="{'color': 'white','background-color': '#DCDCDC','border-color':'#DCDCDC'}"
					(click)="closeModal()">Annuler</button>
				<button type="submit" class="btn btn-primary"
					[ngStyle]="{'color': 'white','background-color':contractColor,'border-color': contractColor}"
					data-dismiss="modal" >Envoyer</button>
			</div>
		</form>
