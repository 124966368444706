import { Component, Input, OnInit } from '@angular/core'
import { Auth, onAuthStateChanged, signInAnonymously } from '@angular/fire/auth'
import { ActivatedRoute, Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { environment } from 'src/environments/environment'
import { Contrat, Service, Actualites } from '../../models/contrat'
import { ContratService } from '../../services/contract.service'

@Component({
    selector: 'montri-widget',
    templateUrl: './montri.component.html',
    styleUrls: ['./montri.component.scss']
})

export class MontriComponent implements OnInit {
  
    @Input() contractId = 'Est Ensemble'
  
    contrat?: Contrat
    actus? : Actualites
    contractColor?: string
    services: Service[] = []
    naviagtion_path: String | undefined;
    serviceNames: {[key: string]:string} = {}
    serviceStyles : {[key: string]:any} = {}
    serviceIcons: {[key: string]:string} = {}
    selectedService?: Service
    selectedServiceName: string = ""

    constructor(private auth: Auth, 
                private contractService: ContratService, 
                private router : Router, 
                private cookieService : CookieService) { 
                    console.log("MontriComponent")
                }

    ngOnInit(): void {
        onAuthStateChanged(this.auth, (user) => {
            if (user) {
                this.contractService.contract.asObservable().subscribe((contrat) => {
                    if (!contrat.contrat) {
                        console.log("Contrat vide")
                        if (environment.widget) {
                            this.contractService.loadContract(this.contractId)
                        }
                    } else {
                        console.log('Constrat set')
                        this.contrat = contrat
                        this.contractColor = this.contrat.design?.couleurPrincipale
                        this.services = (this.contrat?.services ?? []).filter(service => (service.active && service.web))
                        if(this.services.length == 0) {
                            this.services = (this.contrat?.services ?? []).filter(service => (service.active && service.activeWeb))
                        }
                        this.setName(this.services)
                        this.contractService.serviceButtonChangeColor.asObservable().subscribe((serviceName) => {
                            if (window.location.pathname == "/services" && serviceName == "") { 
                                serviceName = this.services[0].nom
                            }
                            if (serviceName == "") {
                                return
                            }
                            this.services.forEach(elem => {
                                if (elem.nom && elem.nom != serviceName) {
                                    this.serviceStyles[elem.nom][0] = 'white'
                                    this.serviceStyles[elem.nom][1] = this.contractColor ?? ""
                                } else {
                                    this.serviceStyles[serviceName][0] = this.contractColor ?? ""
                                    this.serviceStyles[serviceName][1] = 'white'
                                }
                            })
                        })
                        this.contractService.serviceButtonAction.asObservable().subscribe((serviceName) => {
                            if (window.location.pathname == "/services" && serviceName == "" || environment.widget == true) {
                                serviceName = this.services[0].nom
                            }
                            if (serviceName == "") {
                                return
                            }
                            let service = this.services.find(elem => elem.nom && elem.nom == serviceName)
                            if (service) {
                                this.changeService(service)
                            }
                        })
                    }
                })
            } else {
                console.log('Compte anonyme crée')
                signInAnonymously(this.auth)
            }
        })
    }

    setBorderStyles(nom: string) {
        let styles = {
            'border': '1.5px solid ' + this.contractColor,
            'background-color' : this.serviceStyles[nom][0]
        }
        return styles
    }

    filteredServices() {
        let s = this.services.filter(elem => elem.nom != this.selectedService?.nom)
        return this.services.filter(elem => elem.nom != this.selectedService?.nom)
    }

    getServiceIcon(service: Service) {
        switch(service.nom) {
            case "Actualités et événements":
                return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Ficon-menu-actus.png?alt=media`
            case "Guide du tri":
                return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Ficon-menu-guide.png?alt=media`
            case "Calendrier de collecte":
                return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Ficon-menu-calendrier.png?alt=media`
            case "Carte des points de collecte":
                return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Ficon-menu-carte.png?alt=media`
            case "Demandes et Signalements":
                return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/widget%2Fassets%2Fimages%2Ficon-menu-signalements.png?alt=media`
            default:
                return ''
        }
    }

    getServiceName(service: Service) {
        switch(service.nom) {
            case "Actualités et événements":
                return `Actualités`
            case "Guide du tri":
                return `${service.nom}`
            case "Calendrier de collecte":
                return `Calendrier`
            case "Carte des points de collecte":
                return `Carte`
            case "Demandes et Signalements":
                return `Signalements`
            default:
                return `${service.nom}`
        }
    }

    setName(services: Service[]){
        for(let service of services) {
            this.serviceIcons[service.nom] = `${this.getServiceIcon(service)}`
            this.serviceNames[service.nom] = `${this.getServiceName(service)}`
            this.serviceStyles[service.nom] = ['white', this.contractColor]
        }
    }

    changeService(service: Service) {
        this.selectedService = service
        this.selectedServiceName = this.serviceNames[service.nom]
        this.serviceStyles[service.nom][0] = this.contractColor ?? ""
        this.serviceStyles[service.nom][1] = 'white'
        this.contractService.skipInit = 1
        if (service.nom == 'Actualités et événements') {
            this.router.navigateByUrl("/services/news")
        }
        if (service.nom == 'Demandes et Signalements') {
            this.router.navigateByUrl("/services/reporting")
        }
        if (service.nom == 'Guide du tri') {
            this.router.navigateByUrl("/services/guide")		
        }
        if (service.nom == 'Carte des points de collecte') {
            this.router.navigateByUrl("/services/map")
        }
        if (service.nom == 'Calendrier de collecte') {
            if (this.contrat?.contrat?.collectePolygon == true) {
                this.router.navigateByUrl("/services/calendar")
            }
            else {
                this.router.navigateByUrl("/services/calendarWithoutPolygones")
            }
        }
        this.services.forEach(elem => {
            if (elem.nom && elem.nom != service.nom) {
                this.serviceStyles[elem.nom][0] = 'white'
                this.serviceStyles[elem.nom][1] = this.contractColor ?? ""
            }
        })
    }
}