<div class="ml-5 mb-5">
    <ngx-spinner [hidden]="hidden_spinner" bdColor = "rgba(255, 255, 255, 0.8)" size = "medium" color = "#A6A6A6" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: #A6A6A6" >Localisation en cours...</p></ngx-spinner>
    <div [hidden]="formIsHiden" class="row flex-row flex-wrap ml-0 mb-5 mr-5 mr-lg-0">
        <ng-container *ngFor="let item of sousServicesList; index as i" >
            <div *ngIf="item.active" class="col-12 col-md-5 card border-0 mt-3 mx-auto mx-sm-3 px-0" id="{{'input'+ i}}" (click)="changeInputColor(i,item,0)"  [ngStyle]="{'background-color': '#F2F2F2'}" 
                style="border-radius:1rem;cursor: pointer;">
                <div class="card-body">
                    <h6 class="card-title text mb-0 ml-1 avenir_heavy">{{item.nom}}</h6>
                    <div class="row mt-2">
                        <div class="col-3 my-auto mx-0 mx-md-auto mx-lg-0" style="min-width: 3rem;max-width: 3rem;">
                            <img src="{{item.imageUrl}}" alt="" class="half_image_size"/>
                        </div>
                        <div class="col-9 mx-0 mx-md-auto mx-lg-0">
                            <p class="mb-0" style="font-size: 80%;">
                            {{ item.description }}
                            </p> 
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <form [hidden]="formIsHiden" autocomplete="off">
        <div *ngIf="selectedItem != null" class="row mt-3 ml-0 ml-sm-3">
            <div class="col" [formGroup]="form">
                <div id="destination" class="row mt-3 mb-2">
                    <h4><img class="pr-2" src="https://firebasestorage.googleapis.com/v0/b/montri-dev.appspot.com/o/widget%2Fassets%2Fimages%2Ficon-message.png?alt=media" style="width: 2rem;" alt="">{{selectedItem.formulaires?.titre}}</h4>
                </div>
                <div *ngFor="let champsFormulaire of selectedItem.formulaires?.champs; let i = index">
                    <div *ngIf="champsFormulaire.format == 'message'" class="row mb-4">
                        <div class="col">
                            <p class="row">{{champsFormulaire.nom}} :&nbsp;<span *ngIf="champsFormulaire.obligatoire">*</span></p>
                            <div class="row form-group mb-0">
                                <textarea style="border-radius: 10px; border: 0px; background-color: #f4f3f3;color: black;"
                                (focus)="message_focus = true" [formControlName]="champsFormulaire.nom"
                                value="{{champsFormulaire.value}}" required="{{champsFormulaire.obligatoire}}"
                                (focus)="cleanField(champsFormulaire)"
                                ngClass="{{champsFormulaire.error ? 'colorerrorinput' : 'colornoterrorinput'}}"
                                class="form-control form_style col-10 col-sm-8" id="objectInput" rows="4" placeholder="{{champsFormulaire.preview}}">
                                </textarea>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="champsFormulaire.format == 'googlemaps'" class="row mb-4">
                        <div class="col" >
                            <p class="row">{{champsFormulaire.nom}} : *</p>
                            <div class="row mt-3" >
                                <div class="col-9 col-sm-8 pl-0">
                                    <div class="inner-addon left-addon">
                                        <i class="fa fa-home"></i> 
                                        <input style=" text-align: leff;max-width: 50rem; border-radius: 10px; border: 0px; background-color: #f4f3f3;color: black;"
                                            required="{{champsFormulaire.obligatoire}}"
                                            (focus)="onGooglePlacesFocus(champsFormulaire)"
                                            placeholder="{{champsFormulaire.preview}}"
                                            [formControlName]="champsFormulaire.nom"
                                            value="{{champsFormulaire.value}}" id="adress" type="text"
                                            class="form-control mw-100 input"
                                            (focus)="cleanField(champsFormulaire)"
                                            ngClass="{{champsFormulaire.error ? 'colorerrorinput' : 'colornoterrorinput'}}"
                                            aria-label="Default" aria-describedby="inputGroup-sizing-default"
                                            #address />
                                    </div>
                                </div>
                                <div class="col-2">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/montri-dev.appspot.com/o/widget%2Fassets%2Fimages%2Ficon-location.png?alt=media" style="width: 42px;margin-left: -1.4rem;margin-top: -0.18rem;cursor: pointer" (click)="getlocation(champsFormulaire.nom, champsFormulaire)" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="champsFormulaire.format == 'photo'" class="row mb-4">
                        <div class="col">
                            <div class="row">
                                <p>Ajouter une photo : <span *ngIf="champsFormulaire.obligatoire">*</span></p>
                                <div *ngIf="champsFormulaire.error">
                                    <p style="color: rgba(193, 83, 83, 0.945);">{{champsFormulaire.value}}</p>
                                </div>
                            </div>
                            <div class="row form-group">
                                <input type="file" required='{{champsFormulaire.obligatoire}}'
                                    [formControlName]="champsFormulaire.nom" #fileInput id="file" class="inputfile"
                                    (change)="onChange(fileInput.files, champsFormulaire)" />
                                <label for="file" *ngIf="imageUrl =='https://bulma.io/images/placeholders/480x480.png'" >
                                    <img src="https://firebasestorage.googleapis.com/v0/b/montri-dev.appspot.com/o/widget%2Fassets%2Fimages%2F099-camera.svg?alt=media" class="p-4"
                                        [ngStyle]="{'background-color': '#f4f3f3','border-radius': '10px'}" style="width: 5rem;cursor: pointer;" />
                                </label>
                                <div class="col p-0 center_blocks">
                                    <figure class="image is-128x128 "
                                        [hidden]="imageUrl =='https://bulma.io/images/placeholders/480x480.png'? true:false">
                                        <img [src]="imageUrl" style="width:250px; height: 250px;"
                                            [ngStyle]="{'border' : '5px solid '+contractColor,'border-radius': '10px'}" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="champsFormulaire.format == 'liste'" class="row mb-4">
                        <div class="col">
                            <p class="row">
                                {{champsFormulaire.nom}} : *</p>
                            <div class="row" *ngIf="selectedItem?.motifs">
                                {{champsFormulaire?.titreMotif}}
                            </div>
                            <div class="row custom-wrapper" *ngIf="selectedItem?.motifs">
                                <div class="group-gap">
                                    <div class="d-block">
                                        <select class="custom-select" (change)="changeMotif($event)"
                                            [formControlName]="champsFormulaire.nom"
                                            formControlName="motif-{{i}}"
                                            ngClass="{{mandatoryFieldError ? 'colorerrorinput' : 'colornoterrorinput'}}"
                                            style="width: auto; box-shadow: none; border-color:#f4f3f3; border-radius: 10px; color: #6E757C; background-color: #f4f3f3;">
                                            <option value="">
                                                {{mandatoryFieldError ? "Champs obligatoire" : champsFormulaire.preview}}
                                            </option>
                                            <option *ngFor="let motif of champsFormulaire.motifs"
                                                [ngValue]="motif">
                                                {{motif}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3" >
                    <h4><img class="pr-2" src="https://firebasestorage.googleapis.com/v0/b/montri-dev.appspot.com/o/widget%2Fassets%2Fimages%2Ficon-coordonnees.png?alt=media" style="width: 2rem;" alt="">{{selectedItem.coordonnees?.titre}}</h4>
                </div>
                <div class="row mt-2 mr-4" *ngIf="selectedItem.coordonnees?.sousTitre && selectedItem.coordonnees?.sousTitre != ''" >
                    <p style="font-size: 95%;">{{ selectedItem.coordonnees?.sousTitre }}</p>
                </div>
                <div class="row">
                    <div class="col-8 mt-3 col-lg-3 p-0 mr-3" *ngFor="let champsCoordonnees of selectedItem.coordonnees?.champs ?? [] ; let i = index">
                        <div class="form-group my-auto">
                                <div class="inner-addon left-addon">
                                    <i [ngClass]="getIcon(champsCoordonnees.nom)"></i> 
                                    <input required="{{champsCoordonnees.obligatoire}}" class="w-100 personal_infos" style="border-radius: 10px; border: 0px; background-color: #f4f3f3;color: black;height: 2.5rem;font-family:Arial, FontAwesome;"
                                    [formControlName]="champsCoordonnees.nom"
                                    placeholder="{{champsCoordonnees.nom}} *" value="{{champsCoordonnees.value}}"
                                    (focus)="cleanField(champsCoordonnees)"
                                    ngClass="{{champsCoordonnees.error ? 'colorerrorinput-coord' : 'colornoterrorinput'}}"
                                    name="{{champsCoordonnees.nom}}" >
                                </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5 mr-3">
                    <div class="col-12 col-sm-4 pl-0">
                        <button type="submit" style="border-radius: 10px; min-width: 15rem" class="btn btn-lg btn-block divCentro"
                        (click)="sendInfos()">Envoyer</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div [hidden]="!formIsHiden" class="row pl-0 pl-sm-4 mt-3">
        <div class="col-10 col-sm-12 mb-2">
            <img src="https://firebasestorage.googleapis.com/v0/b/montri-dev.appspot.com/o/widget%2Fassets%2Fimages%2Fmessage-sent.png?alt=media"/>
        </div>
        <div class="col-10 col-sm-12 mb-1">
            <h4>Merci pour votre envoi</h4>
        </div>
        <div class="col-10 col-sm-12 mb-2">
            <p>Nos équipes prendront contact avec vous si cela est nécessaire dans les plus brefs délais. Sinon, vous serez informé par email de la prise en charge de votre demande.</p>
        </div>
        <div class="col-10 col-sm-12">
            <button type="submit" [ngStyle]="{'background-color': contractColor}" style="border-radius: 10px; min-width: 10rem;max-width: 10rem; font-size:1rem;font-family: avenir_heavy;" class="btn btn-lg btn-block divCentro"
            (click)="formIsHiden = false">Autre demande</button>
        </div>
    </div>
</div>