<ngx-spinner [hidden]="hidden_spinner" bdColor = "rgba(255, 255, 255, 0.8)" size = "medium" color = "#A6A6A6" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: #A6A6A6" >Localisation en cours...</p></ngx-spinner>
<div class="ml-2 mr-3 ml-sm-5 mt-3 mb-5">
	<div class="row ml-2 mt-2 mb-3">
		<div class="col-10 col-lg-6 pl-2 pr-0">
			<p>
				<AutocompleteComponent (setAddress)="getAddress($event, null)" adressType="geocode">
				</AutocompleteComponent>
			</p>
		</div>
		<div class="col-2 pl-2">
			<img src="https://firebasestorage.googleapis.com/v0/b/montri-dev.appspot.com/o/widget%2Fassets%2Fimages%2Ficon-location.png?alt=media" style="margin-left: -2px;max-width: 2.4rem;cursor:pointer"
				(click)="getCollectGeolocation()" alt="">
		</div>
	</div>
    
	<div class="row mb-4 mx-2" *ngFor="let item of getOrderedItems(); let i = index;">
        <div class="col-12 ml-2">
            <div class="row" style="min-height: 10rem;">
                <div class="col" style="min-width: 5rem;max-width: 5rem;border-radius: 20px 0px 0px 20px;" [ngStyle]="{'background-color': hexToRgbA(HexCode(item.backgroudColor), '1.0')}">
                    <div class="row h-75">
                        <img src="{{item.icon}}" class="mx-auto my-auto" style="width: 3rem;height: 3rem;"/>
                    </div>
                    <div class="row h-25" [ngStyle]="{'background-color': hexToRgbA('#000000', '0.1')}"
                        style="border-radius: 0px 0px 0px 20px;">
                        <img src="{{getIconBottom(item.agenda)}}" class="mx-auto" alt="" style="width: 1.5rem;" />
                    </div>
                </div>
                <div class="col-9" [ngStyle]="{'background-color': hexToRgbA(HexCode(item.backgroudColor), '0.2'), 'border-radius': '0px 20px 20px 0px'}">
                    <h6 class="item_top pt-3">{{item.nom}}</h6>
                    <p *ngIf="item.agenda != ''" class="item_bottom mb-0"><img style="height:1.5rem;" class="pb-1 pr-1" [src]="item.agendaIcon"/>{{ getAgendaText(item) }}</p>
                    <p class="item_bottom mt-1 mb-0" style="font-size: 85%;color: grey;" *ngIf="item.zone?.infos?.commentaire"><img style="height:1.5rem;" class="pb-1 pr-1" src="https://firebasestorage.googleapis.com/v0/b/montri-dev.appspot.com/o/widget%2Fassets%2Fimages%2Fcommentaire.png?alt=media"/>{{ item.zone?.infos?.commentaire }}</p>
                    <p class="item_bottom mt-3 mb-0" style="font-size: 90%;" *ngIf="showMapButton(item)" [innerHTML]="getActionText(item)"></p>
                    <div class="d-flex flex-row flex-wrap mt-3" >
                        <p class="avenir_heavy py-2 px-4 mr-2" *ngIf="showMapButton(item)" (click)="goToFluxInMap(item)" style="font-size: 80%;color: white;font-family: avenir_heavy;cursor: pointer;border-radius: 10px;" [ngStyle]="{'background-color': hexToRgbA(HexCode(item.backgroudColor), '1.0')}">{{(screenWidth >= 768) ? item.libelleBouton : getShortLibelle(item.libelleBouton)}}</p>
                        <p class="avenir_heavy py-2 px-4 mr-2" *ngIf="(item.url?.length ?? 0) > 0" (click)="goToUrl(item.url)" style="font-size: 80%;color: white;font-family: avenir_heavy;cursor: pointer;border-radius: 10px;" [ngStyle]="{'background-color': hexToRgbA(HexCode(item.backgroudColor), '1.0')}">{{ (screenWidth >= 768) ? "Consulter les consignes" : "Consignes" }}</p>
                    </div>
                </div>
            </div>
        </div>
	</div>
</div>
