import { FormBuilder, FormGroup } from '@angular/forms'
import { Component, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Alert, Contrat } from 'src/app/models/contrat'
import { ContratService } from 'src/app/services/contract.service'
import { addDoc, collection, Firestore } from '@angular/fire/firestore'

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
	contractColor?: string
    mailContact:string = "bonjour@montri.fr"
	ContactForm = new FormGroup({})
	constructor(
		private formBuilder: FormBuilder,
		private contratService: ContratService,
		public activeModal: NgbActiveModal,
        private db: Firestore
	) { }

    ngOnInit(): void {
        this.contratService.contract.asObservable().subscribe((contrat) => {
            this.contractColor = contrat?.design?.couleurPrincipale ?? "#FF8C00"
            this.ContactForm = this.formBuilder.group({
                contact_email: "",
                subject_email: "",
                message_email: ""
            })
        })
    }

    closeModal() {
        this.activeModal.close()
    }

    async sendContactMail(customerData: { [x: string]: any }) {
        var verif = 0
        const ref = collection(this.db, 'mail')
        const res = await addDoc(ref, {
            from: customerData['contact_email'],
            to: this.mailContact,
            message: {
                subject: `${customerData['subject_email']}`,
                html: `<code><P>${customerData['message_email']}</p></code>`,
            }
        })
        var alert: Alert
        if (res) {
            alert = {
                type: "success",
                message: "Votre message a bien été envoyé.",
            }
        } else {
            alert = {
                type: "error",
                message: "Un problème est survenu lors de l'envoi, veuillez réessayer ultérieurement.",
            }
        }
        verif = 1
        this.contratService.alert.next(alert)
        this.activeModal.close()
    }
}
