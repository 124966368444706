import { Component, OnInit } from '@angular/core'
import { Auth, onAuthStateChanged } from '@angular/fire/auth'
import { Router } from '@angular/router'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { AuthentificationService } from 'src/app/services/authentification.service'
import { UserService } from 'src/app/services/user.service'
import { Contrat, Service } from '../../models/contrat'
import { ContratService } from '../../services/contract.service'

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})

export class SigninComponent implements OnInit {

    contrat?: Contrat
    contractColor?: string
    mail?:string
    password?:string
    services: Service[] = []
    mailError  : boolean =  false
    codeError : boolean = false
    cguError : boolean= false
    passwordError : boolean =  false
    confirmpasswordError : boolean = false
    error  : boolean  =  false
    userExist : boolean = false
    isChecked : boolean = false;
    errorLogin: boolean = false

    constructor(
        private auth: Auth, 
        private authservice: AuthentificationService,
        private contractService: ContratService, 
        private userService: UserService, 
        private router : Router,
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        onAuthStateChanged(this.auth, (user) => {
            this.contractService.contract.asObservable().subscribe((contrat) => {
                console.log('Constrat set')
                this.contrat = contrat
                this.contractColor = this.contrat.design?.couleurPrincipale
                if (user?.isAnonymous == false && contrat.contrat?.idContrat) {
                    this.userService.loadUser(contrat.contrat?.idContrat, user.uid)
                    this.router.navigate(['dashboard'])
                    this.contractService.navButtons.next("dashboard")
                } else {
                    console.log('Utilisateur non connecté')
                }
            })
        })
    }

  validMail(mail:string){
    return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(mail);
  }

   
  forgotPwd(){
    if(this.mail && this.validMail(this.mail)){
      this.authservice.ForgotPassword(this.mail)
    }else{
      window.alert("Veuillez saisir une adresse mail valide")
    }
  }


  toRegister(){
    this.router.navigate(['/signup'])
  }
 

  async onSubmitSignIn() {
    if(this.mail && this.password){
      const result = await this.authservice.SignIn(this.mail, this.password, this.contrat?.contrat?.idContrat ?? "");
      if(result == 'error'){
        this.errorLogin = true
      }
    }else{
      this.error = true
    }
	}


  showSendMailModal(){
		    let options: NgbModalOptions = {};
        options.centered = true
		    this.modalService.dismissAll()
		    // const modalRef = this.modalService.open(SendmailComponent,options)
  }

  processMail(event:any){
    this.mail = event.target.value
    console.log(event.target.value)
  }

  processPwd(event:any){
    this.password = event.target.value
  }

  checkCheckBox(){
    if (this.isChecked == false){
        this.isChecked = true
    }else if (this.isChecked == true){
        this.isChecked = false
    }

}

}
